import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { listingActions } from "../../store/slice/listing-slice";

export default function WarningModal({ isOpen, setIsOpen }) {
  function closeModal() {
    setIsOpen(false);
  }
  const dispatch = useDispatch()

  const navigate = useNavigate();
  const handleNavigation = () => {
    dispatch(listingActions.addIsListing(false))
    navigate('/')
    const listingAddress={
      searchAddress:'',
      address:'',
      town:'',
      country:'',
      postalCode:''
    }
    dispatch(listingActions.addListingAddress(listingAddress))
    const listingData = {

      propertyType:'',
      furnishingOption:"",
      price:'',
      numberofBathrooms: '',
      numberofBedrooms: '',
      propertyAge:'',
      carpetArea:'',
      listingMedia: [],
      floorImage: [],

      propertyDesc:'',
      selectedDate:"",
      phoneNumber:'',
      showNumber:false,
      statusGroup:'Agent',
      parking:false,
      securityAlarm:false,
      balconies:false,
      garden:false,
      swimmingPool:false,
      kitchen:false,
      otherFeatures:'',
      depositAmount:null,
      occupancyLimit:null,
      minTenancyLength:null,
      maxTenancyLength:null,
      rentalPrice:null,
      paymentPlan:null,
      minInvestment:'',
      investmentCategory:'',
      investmentType:'',
      expectedROI:'',
      roiPlan:"",
      historicPerformance:'',
      roiPlanInput:'',
      historicInput:'',
      investmentTime:'',
      timelineDate:'',
      investmentTimeType:'',
      natureInvestment:'',
      zoiningPermits:'',
      propertyValue:""
    }

    dispatch(listingActions.addListingDetail(listingData))
    const listingPremiums={
      premiumUpgrade:null,
      premiumRightMove:null,
      premiumZoopla:null,
      energyCertifcate:null,
      gasCertificate:null,
      premiumRent:null,
      premiumTenancyRef:null,
      premiumTenancyAgreem:null,
    }
    dispatch(listingActions.addListingPremiums(listingPremiums))

    setIsOpen(false)
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full h-[262px] max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-center align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-[28px] font-semibold leading-6 text-gray-900"
                  >
                    Exit Property Listing
                  </Dialog.Title>
                  <div className="mt-4">
                    <p style={{color:'#0D0E0F'}} className="warning-subText text-[16px] color-[#0D0E0F]">
                      Are you sure you want to exit without completely updating
                      your listing details? Your information will be lost and
                      you won’t be able to continue if you leave.
                    </p>
                  </div>

                  <div className="mt-10 flex justify-center gap-2">
                    <button
                      type="button"
                      className="h-[50px] w-[166px] inline-flex justify-center rounded-[12px] bg-white px-4  pt-[10px] text-[17px] font-medium text-black border-[1.5px] border-[#E53935]"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      className="h-[50px] w-[166px] inline-flex justify-center rounded-[12px] border border-transparent bg-[#E53935] px-4 pt-[10px] text-[17px] font-semibold"
                      onClick={handleNavigation}
                    >
                      Continue
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
