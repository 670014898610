import { Fragment, useRef, useState, } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import './Boost.css'
import CancelIcon from '../../assets/cancel-icon.png'
import editIcons from "../../assets/Images/property/editIcons24.png";
import insideIcons from "../../assets/Images/property/insideIcons24.png";
import deletIcons from "../../assets/Images/property/deletIcons24.png";
import Deactivate from "../../assets/Deactivate.png";
import Delete from '../../assets/Delete.png'
import { IoShareSocial } from "react-icons/io5";
import Remove from "../../assets/Remove.png";

export default function More({ isInActive, more, setMore, isSavedPop, setIsSavedPop, getPropertyImpressionInsights, setDeactive, setDeleted, listingId, setPropertyId, isDeclinePop, handleRemoveListing, setIsShare, }) {
    const cancelButtonRef = useRef(null)
    return (
        <>
            <Transition.Root show={more} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setMore}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 ">
                        <div className="">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="  ">

                                    <div className="Boost d-flex  mt-5">
                                        <div className='col-4   mt-[5vmax]'>
                                            <div className='boostMainContainer form-control d-flex flex-column ' style={{ position: "relative" }}>
                                                <div className='text-end' style={{ position: "absolute", top: "-5px", right: "-5px", cursor: "pointer" }}>
                                                    <img src={CancelIcon} onClick={() => setMore(false)} />
                                                </div>
                                                {
                                                    isDeclinePop ? (
                                                        <>
                                                            {
                                                                isInActive ? (
                                                                    <p style={{ fontSize: "16px", cursor: 'pointer' }} 
                                                                    onClick={() => {
                                                                        setDeactive(true)
                                                                        setPropertyId(listingId)
                                                                    }}
                                                                     className='boostSubTitle flex mt-3'><img className='mr-2' src={editIcons} /> Edit & Reactivate</p>

                                                ):(
                                                <p style={{ fontSize: "16px", cursor: 'pointer' }} className='boostSubTitle flex mt-3'><img className='mr-2' src={editIcons} /> Edit & Reactivate</p>

                                                )
                                                        }
                                                <p onClick={() => {
                                                    setDeleted(true)
                                                    setPropertyId(listingId)
                                                }} style={{ fontSize: "16px", cursor: 'pointer' }} className='boostSubTitle flex'><img className='mr-2' src={Delete} /> Delete </p>
                                            </>
                                            ) :  isSavedPop ? (
                                            <>
                                                <p onClick={() => setIsShare(true)}
                                                    style={{ fontSize: "16px", cursor: 'pointer' }} className='boostSubTitle flex mt-3'>
                                                    <span className='mr-4 mt-1' ><IoShareSocial /></span> Share
                                                </p>
                                                <p onClick={() => {
                                                    handleRemoveListing(listingId)
                                                }} style={{ fontSize: "16px", cursor: 'pointer' }} className='boostSubTitle flex'>
                                                    <img className='mr-2' src={Remove} alt="Remove Icon" />   Remove Listing
                                                </p>
                                            </>
                                            ) : (
                                            <>
                                                <p style={{ fontSize: "16px", cursor: 'pointer' }} className='boostSubTitle flex mt-3'>
                                                    <img className='mr-2' src={editIcons} alt="Edit Icon" /> Edit
                                                </p>
                                                <p onClick={() => getPropertyImpressionInsights(listingId)} style={{ fontSize: "16px", cursor: 'pointer' }} className='boostSubTitle flex'>
                                                    <img className='mr-2' src={insideIcons} alt="Insight Icon" /> Insight
                                                </p>
                                                <p onClick={() => {
                                                    setDeactive(true);
                                                    setPropertyId(listingId);
                                                }} style={{ fontSize: "16px", cursor: 'pointer' }} className='boostSubTitle flex'>
                                                    <img className='mr-2' src={Deactivate} alt="Deactivate Icon" /> Deactivate
                                                </p>
                                                <p onClick={() => {
                                                    setDeleted(true);
                                                    setPropertyId(listingId);
                                                }} style={{ fontSize: "16px", cursor: 'pointer' }} className='boostSubTitle flex'>
                                                    <img className='mr-2' src={Delete} alt="Delete Icon" /> Delete
                                                </p>
                                            </>
                                            )
                                                }

                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >

        </>

    )
}








