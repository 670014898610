import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import './EmailSuccess.css'


export default function EmailSuccess({ emailsuccess, setEmailsuccess }) {

    const cancelButtonRef = useRef(null)

    return (
        <>
            <Transition.Root show={emailsuccess} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setEmailsuccess}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 ">
                        <div className="">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="  ">

                                    <div className="EmailSuccessMsg">
                                        <div className="mailsuccess">
                                            <p>Your email has been successfully updated.</p>
                                            <i onClick={() => setEmailsuccess(false)} className=" ri-close-circle-line"></i>
                                        </div>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>

    )
}
