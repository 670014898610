import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import PasswordResetEmail from './PasswordResetEamil'
import CancelIcon from '../../assets/cancel-icon.png'

// import { Link } from 'react-router-dom'

export default function ForgotValidation({ validation, setValidation }) {

    const [passwordMail, setPasswordMail] = useState(false)

    const cancelButtonRef = useRef(null)

    return (
        <>
            <Transition.Root show={validation} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setValidation}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 ">
                        <div className="">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="  ">


                                    <div className='ForgotValidation d-flex align-items-center justify-content-center '>
                                        <div style={{ backgroundColor: "#fff", marginTop: "20vmax", borderRadius: "5px", padding: "1.6vmax",borderRadius:'20px',position:'relative' }} className="Validation  col-10 col-lg-5">
                                            <div style={{ cursor: "pointer",position:'absolute',right: "-5px",
                                            top: "-9px" }} className='text-end'> <img src={CancelIcon} onClick={() => setValidation(false)} /></div>
                                            <h5 className='ForgotValidationTitle' style={{ fontWeight: "700" }}>We have sent an email to the specified address</h5>
                                            <p className='ForgotValidationSubTitle'>Please check your email. If you don’t find it, verify that your email address is correct.</p>

                                        </div>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <PasswordResetEmail passwordMail={passwordMail} setPasswordMail={setPasswordMail} />
        </>

    )
}
