import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import LOGO from '../../assets/Images/navimage/LOGO.png'
import { useDispatch } from 'react-redux'
import './ForgotPassword.css'
import ForgotValidation from './ForgotValidation'
import { authActions } from '../../store/slice/auth-slice'
// import { Link } from 'react-router-dom'
import CancelIcon from '../../assets/cancel-icon.png'
import axios from "axios"

export default function ForgotPassword({ forgotPassword, setForgotPassword }) {
    const [email, setEmail] = useState('')
    const [validation, setValidation] = useState(false)
    const dispatch = useDispatch()
    const cancelButtonRef = useRef(null)

    const handleForgotEmail = async() => {
        try{
            let data={
                email
            }
            const res=await axios.post('https://linkedbricks-backend.onrender.com/auth/send-forgetPassworLink',data)
            setValidation(true)

            const userData = {
                email: email
            }

        }catch(err){
            console.log(err)
        }
    }

    return (
        <>
            <Transition.Root show={forgotPassword} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setForgotPassword}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 ">
                        <div className="">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="  ">


                                    <div className='ForgotPasswordMain'>
                                        <div className="ForgotPass">

                                            <div className='ForgotFlex'>
                                                <img src={CancelIcon} onClick={() => setForgotPassword(false)} className="popupClose "/>

                                                <div className='ForgotOne'>
                                                    <img src={LOGO} alt="" />
                                                </div>
                                                <div className='ForgotTwo'>
                                                    <h5 >Forgotten Password?</h5>
                                                    <p>Please enter the email address you registered with Linked Bricks below and we’ll email you a link to reset your password</p>
                                                    <input style={{marginBottom:'0px',width:"95%"}} onChange={(e) => setEmail(e.target.value)} className='changeInput' type="text" placeholder='Email' />
                                                    <button onClick={handleForgotEmail}>Continue</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <ForgotValidation validation={validation} setValidation={setValidation} />
        </>

    )
}
