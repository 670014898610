import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import LOGO from '../../assets/Images/navimage/LOGO.png'
import axios from 'axios'
import './PaymentPopup.css'
import SuccessPop from './SuccessPop'
import { useDispatch, useSelector } from 'react-redux'
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from "@stripe/react-stripe-js";
import { useStripe, useElements } from '@stripe/react-stripe-js'
import { listingActions } from '../../store/slice/listing-slice'
import Divider from '@mui/material/Divider';
import CancelIcon from '../../assets/cancel-icon.png'
import { useNavigate } from 'react-router-dom'

export default function PaymentPopup({ handleIsListed, opencard, setOpencard, plantype, totalCost, isBoost, setIsSuccessful }) {
    const [success, setSuccess] = useState(false)

    const [fname, setFname] = useState('')
    const [lname, setLname] = useState('')

    const listingId = useSelector(state => state.listing.listingId)

    const cancelButtonRef = useRef(null)
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const handleOrder = async (orderId) => {

        const userId = localStorage.getItem('id')
        let data;
        if (isBoost === true) {
            data = {
                firstName: fname,
                lastName: lname,
                listingId: listingId,
                userId: userId,
                orderId: orderId,
                isBoosted: true
            }
        } else {
            data = {
                firstName: fname,
                lastName: lname,
                listingId: listingId,
                userId: userId,
                orderId: orderId
            }
        }


        try {
            const res = await axios.post(`https://linkedbricks-backend.onrender.com/order/submit-order/${userId}`, data, {
                headers: {
                    'x-api-key': 'application/javascript',
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")

                },
            });

            // You can add further logic here, such as displaying a success message or redirecting the user
        } catch (error) {
            console.error('Error submitting order:', error.message);
            // You can handle errors here, such as displaying an error message to the user
        }
    };


    const handlePayment = async (event) => {
        //payment functionality
        event.preventDefault();
        let amount;
        if (plantype == 'Light') {
            amount = 0.30 * 100;
        } else if (plantype == 'Premium') {
            amount = 0.30 * 100;
        } else {
            console.log('in else block boosted')
            amount = totalCost * 100;
        }
        if (amount < 30) {
            console.log('Amount must be at least £0.30 ');
            return;
        }
        try {
            const response = await axios.post(
                `https://linkedbricks-backend.onrender.com/payment/payment-intent`,
                {
                    amount: amount,
                },
            );
            if (response?.status === 200) {
                const confirmPayment = await stripe.confirmCardPayment(
                    response.data,
                    {
                        payment_method: {
                            card: elements.getElement(CardNumberElement),
                        },
                    }
                );
                if (confirmPayment?.paymentIntent?.status === "succeeded") {
                    handleOrder(confirmPayment.paymentIntent.client_secret)


                    if (isBoost === true) {
                        boostListing()
                        setSuccess(true)

                    } else {
                        handleIsListed()
                        setSuccess(true)
                        setIsSuccessful(true)
                    }
                    dispatch(listingActions.addIsListing(false))

                    // setOpencard(false)

                } else {
                    console.log('something went wrong')
                    dispatch(listingActions.addIsListing(false))

                }
            }

        } catch (err) {
            console.log(err)
            dispatch(listingActions.addIsListing(false))

        }
    }
    const boostListing = async () => {
        try {
            const res = await axios.put(`https://linkedbricks-backend.onrender.com/listing/change-boostedStatus/${listingId}`);

        } catch (error) {
            console.error('Error boosting property:', error);
        }
    };
    return (
        <>
            <Transition.Root show={opencard} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpencard}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 ">
                        <div className="">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="  ">
                                    <div className='sinmain'>
                                        <img style={{ cursor: 'pointer' }} src={CancelIcon} onClick={() => setOpencard(false)} className="signclose" />

                                        <div className='signflex '>
                                            <div className='signone text-light '>
                                                <img src={LOGO} alt="" />
                                                {
                                                    plantype == 'Light' && <h5>£0.50</h5>

                                                }
                                                {
                                                    plantype == 'Premium' && <h5>£0.50</h5>

                                                }
                                                {
                                                    isBoost && <h5>£{totalCost}</h5>

                                                }
                                            </div>
                                            <div className='signtwo me-4'>

                                                <form className='mb-2' onSubmit={handlePayment}>
                                                    <div style={{ width: '100%' }} className='cardElement1'>
                                                        <CardNumberElement options={{
                                                            style: {
                                                                base: {
                                                                    fontSize: '16px', border: "1px solid rgb(255, 208, 0)",
                                                                    '::placeholder': { color: '#aab7c4' }
                                                                }
                                                            }
                                                        }} />

                                                    </div>
                                                    <div className="d-flex flex-row gap-1">
                                                        <div className='cardElement1'>
                                                            <CardExpiryElement options={{
                                                                style: {
                                                                    base: {
                                                                        fontSize: '16px',
                                                                        '::placeholder': { color: '#aab7c4' }
                                                                    }
                                                                }
                                                            }} />

                                                        </div>
                                                        <div className='cardElement1'>
                                                            <CardCvcElement options={{
                                                                style: {
                                                                    base: {
                                                                        fontSize: '16px',
                                                                        '::placeholder': { color: '#aab7c4' }
                                                                    }
                                                                }
                                                            }} />

                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-row gap-1">
                                                        <input required value={fname} onChange={(e) => setFname(e.target.value)} className='' type="text" placeholder='First name' />
                                                        <input required value={lname} onChange={(e) => setLname(e.target.value)} className='' type="text" placeholder='Last name' />
                                                    </div>
                                                    <button style={{ width: '100%' }}>Pay</button>
                                                </form>
                                                <Divider><p className='text-[16px]'>I agree to the<span onClick={() => {
                                                    setOpencard(false);
                                                    navigate('/termsofuse')
                                                }} style={{ color: '#098895', cursor: 'pointer' }}> terms and conditions</span> </p></Divider>

                                            </div>

                                        </div>
                                        <SuccessPop isBoost={isBoost} success={success} setSuccess={setSuccess} />
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            {/* <Signup signUpOpen={signUpOpen} setSignUpOpen={setSignUpOpen} /> */}
        </>

    )
}
