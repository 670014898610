import React, { useState, useEffect } from 'react';
import "./SaveProperty.css";
import { useNavigate, useLocation } from 'react-router-dom';
import InactiveImg from "../../../../assets/Images/property/Inactivve.png"
import { delete_saveListings, fetch_saveListings } from '../../../../apiServices';
import bed24 from "../../../../assets/Images/property/bed24.png";
import bathub24 from "../../../../assets/Images/property/bathub24.png";
import Chairs24 from "../../../../assets/Images/property/Chairs24.png";
import { IoShareSocial } from "react-icons/io5";
import { useSelector } from 'react-redux';
import PhotoImg from '../../../../assets/image2.png'
import MoreOption from '../../../../assets/Group.png'
import More from '../../../Popupfile/MoreOption'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
const SaveProperty = ({setShowTabs,getUserDataCounts}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isShare, setIsShare] = useState(false)
  const [savedListings, setSavedListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const isProfileUpdated = useSelector(state => state.auth.isProfileUpdated)
  const [more, setMore] = useState(false)
  const [isSavedPop,setIsSavedPop]=useState(false)
  const [propertyId, setPropertyId] = useState('')



  const handleRemoveListing = async (id) => {
    try {
      setIsDeleting(true);
      const res = await delete_saveListings(id)

      // Handle successful removal (e.g., update state, show notification)
      alert('Listing removed successfully');
      fetchSavedListings()
      getUserDataCounts()

    } catch (error) {
      // Handle error (e.g., show error message)
      console.error('Error removing listing:', error);
    } finally {
      setIsDeleting(false);
    }
  };

  const fetchSavedListings = async () => {
    const listingType = 'FOR_SALE'; // Replace with the desired listing type

    try {
      setLoading(true)
      const response = await fetch_saveListings(listingType);
      setSavedListings(response);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSavedListings();
  }, []);

  const copyLinkToClipboard = (id) => {
    // The link you want to copy
    const link = `https://linkedbricks-backend.onrender.com/property-detail/${id}`;
  
    // Copy the link to the clipboard
    navigator.clipboard.writeText(link)
      .then(() => {
        alert('Link copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  }

  return (
    <div className='SavePropertMain'>
      <div className="Savedmain">
        {
          isProfileUpdated == false && <div className="TopOne mb-8">
            <div className="topOneLeft">
              <h5 className="topOneTitle">Welcome to your account</h5>
              <p className="topOnePara">Complete your profile to seamlessly connect with property owners and do so much more.</p>
              <button className="topOneButton" onClick={() => navigate('/dashboard/my-profile')}><span>Complete profile</span> <i style={{ fontSize: '24px' }} className="ri-arrow-right-s-line"></i></button>
            </div>
            <div className="topOneRight">
              <img className="topOneImg" src="https://myproperties.vercel.app/BuildingIllustration.png" alt="" />
            </div>
          </div>
        }

        <div className="sTopLast">
          <div className="sHeadingP">
            <h5 className='flex' ><i onClick={() => setShowTabs(true)} className="showTabBack ri-arrow-left-s-line"></i>Saved Properties</h5>
            <div className="links">
              <span
                className={location.pathname.includes('/sale') ? 'active-link' : ' '}
                onClick={() => navigate('/dashboard/save-property/sale')}
              >
                For sale
              </span>
              <span
                className={location.pathname.includes('rent') ? 'active-link' : ' '}
                onClick={() => navigate('/dashboard/save-property/rent')}
              >
                For rent
              </span>
              <span
                className={location.pathname.includes('investment') ? 'active-link' : ' '}
                onClick={() => navigate('/dashboard/save-property/investment')}
              >
                For investment
              </span>
            </div>
          </div>
        </div>


        {/* ----------------- */}

        <div>
          {
            loading ? (
              <h5 className='loadingMsg'>Loading...</h5>
            ) : (
              <>
                {
                  savedListings.length != 0 ? (
                    savedListings?.map((listing, i) => {
                      return (
                        <>
                        <div key={i} className=" cursor-pointer active-card h-[246px]  mb-4">
                          <div class="  ms-0 mt-2 ">
                            <div class="d-flex">
                              <div className="position-relative">
                                <img
                                  onClick={() => navigate(`/property-detail/${listing?._id}`)}
                                  src={listing?.listingMedia[0]}
                                  alt=""
                                  style={{
                                   borderTopLeftRadius: '12px', // Border radius for the top-left corner
                                    borderBottomLeftRadius: '12px'
                                  }}
                                  className="myPropertyImg h-[246px] w-[510px] float-left"
                                />
                                {/* Overlay */}
                                <div
                                  style={{
                                    position: 'absolute',
                                    bottom: '15px',
                                    right: '15px',
                                    backgroundColor: '#F9FAFB',
                                    color: '#0D0E0F',
                                    padding: '4px 8px',
                                    borderRadius: '12px',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    display: 'flex'
                                  }}
                                  className='myPropertyImg__total'

                                >
                                  <img
                                    style={{ width: '12px', height: '12px', marginTop: '5px', marginRight: '3px' }}
                                    src={PhotoImg}
                                    alt=''
                                  />

                                  {0 + 1}/{listing.listingMedia.length}
                                </div>
                              </div>


                              <div class="col">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }} className="myPropertyContainer1 ml-[25px] w-[520px] my-[20px]  h-[44px]">
                                  <div>
                                    <h2 onClick={() => navigate(`/property-detail/${listing?._id}`)} className="myPropertyPrice ml-[5px]  font-semibold ">
                                      £{listing?.price}</h2>
                                  </div>

                                  <div className='myPropertyIcon' style={{ display: 'flex' }}>

                                    <div class="col">
                                      <div style={{ width: '45px', height: '45px', borderRadius: '50%', backgroundColor: '#fcfcfc', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '24px' }}
                                        onClick={() => setIsShare(true)}>
                                        <IoShareSocial />
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div style={{ width: '45px', height: '45px', borderRadius: '50%', backgroundColor: '#fcfcfc', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '10px' }}
                                        onClick={() => handleRemoveListing(listing?._id)} >
                                        <i style={{ color: "red", fontSize: '24px' }} class="ri-indeterminate-circle-line"></i>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='flex myPropertyIcon2 mt-1 '>

                                  <img style={{ height: '12px'}} onClick={() => {setMore(true)
                                    setIsSavedPop(true)
                                  }} src={MoreOption} />
                                </div>
                                </div>
                                <div style={{ cursor: 'pointer' }} onClick={() => navigate(`/property-detail/${listing?._id}`)} className="myPropertyContainer2 ml-[20px] h-[66px]">
                                  <div class="container myPropertySubContainer2__main ">
                                    <div class="">
                                      <div className="myPropertySubContainer2 h-[36px] mb-2">
                                        <h2 className="myPropertyType text-[26px] font-semibold text-[#135966]">

                                          {listing?.propertyType}
                                        </h2>


                                      </div>
                                      <div className="mt-[4px] h-[26px]">
                                        {
                                          listing?.addressLine1 ? (
                                            <h2 className="myPropertyPlace text-[18px] font-normal ">
                                              {listing?.addressLine1}, {listing?.town}
                                            </h2>
                                          ) : (
                                            <h2 className="myPropertyPlace text-[18px] font-normal ">{listing?.postalCode} </h2>
                                          )
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="myPropertyContainerTop3 my-[20px] h-[56px]">
                                  <div style={{ backgroundColor: '#F9FAFB', padding: '10px', paddingLeft: '30px', textAlign: 'center', width: '500px', borderRadius: '15px' }} class="myPropertyContainer3 myPropertyContainer3-margin container ">
                                    <div class="myPropertySubContainer3__main d-flex align-items-center">
                                      <div class="myPropertySubContainer3 col ">
                                        <div style={{width:'max-content' }} className=" text-start d-flex flex-lg-row ">
                                          <img
                                            src={bed24}
                                            alt=""
                                            className="myPropertyContainer3__icons w-[24px] h-[24px] float-left mx-[4px] "
                                          />
                                          <span className="myPropertyContainer3__iconsText text-[14px] font-normal">
                                            {listing?.numberofBedrooms} <span className='myPropertyContainer3__iconsSpan'>beds</span>
                                          </span>
                                        </div>
                                      </div>
                                      <div class="col myPropertySubContainer3">
                                        <div style={{ marginLeft: '15px',width:'max-content' }} className="text-start d-flex flex-lg-row ">
                                          <img
                                            src={bathub24}
                                            alt=""
                                            className="myPropertyContainer3__icons w-[24px] h-[24px] float-left mx-[4px] "
                                          />
                                          <span className="myPropertyContainer3__iconsText text-[14px] font-normal ">
                                            {listing?.numberofBathrooms} <span className='myPropertyContainer3__iconsSpan'>baths</span>
                                          </span>
                                        </div>
                                      </div>
                                      <div class="col myPropertySubContainer3">
                                        <div style={{ marginLeft: '25px',width:'max-content' }} className="text-start d-flex flex-lg-row ">
                                          <img
                                            src={Chairs24}
                                            alt=""
                                            className="myPropertyContainer3__icons w-[24px] h-[24px] float-left ]"
                                          />
                                          <span className="myPropertyContainer3__iconsText text-[14px] font-normal">
                                            {listing?.furnishingOption}
                                          </span>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <More setIsSavedPop={setIsSavedPop} isSavedPop={isSavedPop} more={more} handleRemoveListing={handleRemoveListing} setMore={setMore} listingId={listing?._id} setIsShare={setIsShare} />
                        {isShare &&

                          <div className='savedPropertyyy '>
                            <h6>Share this property</h6>
                            <i onClick={() => setIsShare(false)} class="ri-close-line"></i>
                                   
                            <FacebookShareButton url={`https://linkedbricks-backend.onrender.com/property-detail/${listing?._id}`}><i className="ri-facebook-fill"></i></FacebookShareButton>
                            <WhatsappShareButton url={`https://linkedbricks-backend.onrender.com/property-detail/${listing?._id}`}><i className="ri-whatsapp-line"></i></WhatsappShareButton>
                            <LinkedinShareButton url={`https://linkedbricks-backend.onrender.com/property-detail/${listing?._id}`}><i className="ri-linkedin-fill"></i></LinkedinShareButton>
                            <TwitterShareButton url={`https://linkedbricks-backend.onrender.com/property-detail/${listing?._id}`}><i className="ri-twitter-line"></i></TwitterShareButton>
                            <button onClick={()=>copyLinkToClipboard(listing?._id)}><i class="ri-links-line"></i>Copy link</button>
                          </div>
                        }
                        </>
                      )
                    })
                  ) : (
                    <h6 > You don't have any saved properties.</h6>
                  )
                }

              </>
            )
          }

        </div>


        {/* ------------------- */}
    

      </div>
    </div>
  );
};

export default SaveProperty;



