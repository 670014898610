import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import LOGO from '../../assets/Images/navimage/LOGO.png'
import { login_user, signIn_provider } from '../../apiServices'
import { useDispatch } from 'react-redux';
import { authActions } from '../../store/slice/auth-slice';
import { signInWithPopup } from 'firebase/auth'
import { useNavigate } from 'react-router-dom';
import Signup from './Signup'
import './Signin.css'
// import { Link } from 'react-router-dom'
import ForgotPassword from './ForgotPassword'
import { auth, provider, facebook } from '../../firebase/firebase'

export default function Signin({ isSaved, open, setOpen, onSignInClick, isListing, isAuth, isSchedule, isContact, setIsContact, setIsSchedule }) {
    const [signUpOpen, setSignUpOpen] = useState(false)
    const [show, setShow] = useState(false)
    const [forgotPassword, setForgotPassword] = useState(false)
    const dispatch = useDispatch()
    const cancelButtonRef = useRef(null)
    const [email, setEMail] = useState("");
    const [pass, setPass] = useState("");
    const navigate = useNavigate()

    const handleSignUp = () => {
        setSignUpOpen(true);
        setOpen(false);

    }

    const handleSignIn = async (e) => {
        e.preventDefault()
        try {
            const user = {
                email,
                password: pass,
            };

            const response = await login_user(user);

            const userData = {
                token: response.jwt,
                userId: response.userId,
                email: response.email
            }
            dispatch(authActions.login(userData))

            localStorage.setItem("token", response.jwt);
            localStorage.setItem("id", response.userId)
            localStorage.setItem('userDbId',response.userDbId)
            localStorage.setItem('userEmail',response.email)


            if (onSignInClick)
                onSignInClick();

            setEMail('')
            setPass('')
            setOpen(false);
            navigate('/')
        } catch (error) {
            // onSignInClick();
            // setOpen(false);
            if (error.isVerified == false) {
                alert('Email not verified. Please check your inbox we have sent an email for verification');

                setEMail('')
                setPass('')
                setOpen(false);

            } else {
                alert(error)
            }
            console.log(error)
        }
    }

    const handleGoogleLogin = async () => {
        await signInWithPopup(auth, provider)
            .then(async (result) => {
                const authenticatedUser = result.user;
                let userDetail = {
                    email: authenticatedUser.email,
                    photoUrl: authenticatedUser.photoURL,
                    fullName: authenticatedUser.displayName

                };
                const res = await signIn_provider(userDetail)
                setOpen(false);
                const userData = {
                    userId: res.userId,
                    email: res.email,
                    token: res.jwt,

                }
                dispatch(authActions.login(userData))
                localStorage.setItem("id", res.userId)
                localStorage.setItem('userDbId',res.userDbId)

                localStorage.setItem("token", res.jwt);
                localStorage.setItem('userEmail',res.email)

                setEMail('')
                setPass('')

                navigate('/dashboard/my-propertymain');

            })
    }

    const handleFacebookLogin = async () => {
        await signInWithPopup(auth, facebook)
            .then(async (result) => {
                const authenticatedUser = result.user;
                let userDetail = {
                    email: authenticatedUser.email,
                    photoUrl: authenticatedUser.photoURL,
                    fullName: authenticatedUser.displayName

                };
                const res = await signIn_provider(userDetail)
                setOpen(false);
                const userData = {
                    userId: res.userId,
                    email: res.email,
                    token: res.jwt,

                }
                dispatch(authActions.login(userData))
                localStorage.setItem("id", res.userId)
                localStorage.setItem("token", res.jwt);
                localStorage.setItem('userEmail',res.email)


                navigate('/dashboard/my-propertymain');

            })
    }
    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 ">
                        <div className="">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="  ">


                                    <div className='SignMain'>
                                        <div className="SignIn">

                                            <div className='signflex'>
                                                <i onClick={() => setOpen(false)} className="signclose ri-close-line rounded-circle px-2 " style={{ backgroundColor: "#FFF9E6", marginTop: "-2vmax", marginRight: "-1vmax", cursor: "pointer" }}></i>

                                                <div className='signone'>
                                                    <img src={LOGO} alt="" />
                                                </div>
                                                <div className='signtwo'>
                                                    <form onSubmit={handleSignIn}>
                                                        {
                                                            isListing && <h5 > Sign in to list your <br /> properties and much more</h5>

                                                        }
                                                        {
                                                            isSaved && <h5 > Sign in to save your <br /> property and much more</h5>

                                                        }
                                                        {
                                                            isSchedule && <h5 > Sign in to schedule a tour <br /> and much more</h5>

                                                        }
                                                        {
                                                            isContact && <h5 > Sign in to contact property <br /> owner and much more</h5>

                                                        }

                                                        {
                                                            isAuth && <h5 > Welcome to Linked Bricks. <br /> Sign in to enjoy much more</h5>

                                                        }
                                                        <h6>No account?  <span onClick={handleSignUp}> Sign up</span></h6>
                                                        <input type="text" placeholder='Email'
                                                            value={email}
                                                            onChange={(e) => setEMail(e.target.value)} required />
                                                        <input className='mt-2' type={show ? ("text") : ("password")} placeholder='Password'
                                                            value={pass}
                                                            onChange={(e) => setPass(e.target.value)} required />
                                                        {show ? (<i style={{color:'#A6A7A8'}} onClick={() => setShow(false)} class="eyes ri-eye-fill"></i>) : (<i style={{color:'#A6A7A8'}} onClick={() => setShow(true)} class="eyes ri-eye-off-fill"></i>)}

                                                        <p style={{ cursor: "pointer" }} onClick={() => setForgotPassword(true)}>Forgot Password?</p>
                                                        <div className='text-dark'  ><button type='submit' >Continue</button></div>
                                                    </form>

                                                    <h4>or sign in with</h4>
                                                    <div className='signicon'>
                                                        <i style={{ cursor: 'pointer' }} onClick={handleGoogleLogin} class="ri-google-fill "></i>
                                                        <i style={{ cursor: 'pointer' }} onClick={handleFacebookLogin} class="ri-facebook-fill ms-2"></i>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <Signup isSaved={isSaved} isContact={isContact} isSchedule={isSchedule} isAuth={isAuth} signUpOpen={signUpOpen} setSignUpOpen={setSignUpOpen} setOpen={setOpen} isListing={isListing} />
            <ForgotPassword forgotPassword={forgotPassword} setForgotPassword={setForgotPassword} />

        </>

    )
}
