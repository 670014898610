import React, { useState } from 'react'
import "./Footer.css"
import { Link } from 'react-router-dom'
import Bg from '../../assets/bg2.png'
import axios from 'axios'
import { homeActions } from '../../store/slice/home-slice';
import { useDispatch } from 'react-redux';
import { listingActions } from '../../store/slice/listing-slice'
const Footer = () => {
    const [email, setEmail] = useState('')
    const dispatch = useDispatch()

    const handleLetter = async () => {
        try {
            if (email != '') {
                let data = {
                    email
                }
                const res = await axios.post('https://linkedbricks-backend.onrender.com/newsletter/subscribe', data)
                alert('You are successfully subscribed to our news letter!')
                setEmail('')
            }


        } catch (err) {
            console.log(err)
            alert(err.response.data.message)
            setEmail('')

        }
    }
    return (
        <div>
            <div className='footer'>
                <img src={Bg} alt="" />
                <div className='finputmain'>
                    <div className='finput'>
                        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Subscribe to our newsletter' />
                        <button onClick={handleLetter} className=''>Subscribe <i style={{ width: '24px', height: '24px' }} class="ri-arrow-right-s-line"></i> </button>
                    </div>
                </div>
                <div className='fdisplay'>
                    <div className='fmain'>
                        <div className='textDiv'>
                            <div className='fone'>
                                <Link className='text-decoration-none ' to="/addlisting"> <h6 onClick={() => {
                                    dispatch(homeActions.closeNavbar()); dispatch(listingActions.addIsListing(false))
                                }}>Sell your property</h6></Link>
                                <Link className='text-decoration-none ' to="/addlisting">  <h6 onClick={() => {
                                    dispatch(homeActions.closeNavbar()); dispatch(listingActions.addIsListing(false))
                                }} >Let your property</h6> </Link>
                                <Link className='text-decoration-none ' to="/forinvestment">   <h6 onClick={() => {
                                    dispatch(homeActions.closeNavbar()); dispatch(listingActions.addIsListing(false))
                                }} >Invest in a property</h6></Link>

                            </div>
                            <div className='ftwo'>

                                <Link className='text-decoration-none ' to="/forrent">  <h6 onClick={() => {
                                    dispatch(homeActions.closeNavbar()); dispatch(listingActions.addIsListing(false))
                                }} >Rent a property</h6></Link>
                                <Link className='text-decoration-none ' to="/forsale"> <h6 onClick={() => {
                                    dispatch(homeActions.closeNavbar()); dispatch(listingActions.addIsListing(false))
                                }} >Buy a property</h6></Link>
                            </div>
                            <div className='fthree'>
                                <Link className='text-decoration-none ' to="/about"> <h6 onClick={() => {
                                    dispatch(homeActions.closeNavbar()); dispatch(listingActions.addIsListing(false))
                                }} >About us</h6></Link>
                                <Link className='text-decoration-none ' to='/contact'><h6 onClick={() => {
                                    dispatch(homeActions.closeNavbar()); dispatch(listingActions.addIsListing(false))
                                }}>Contact us</h6></Link>
                            </div>
                            <div className='ffour'>
                                <Link className='text-decoration-none ' to='/privacypolicy'>  <h6 onClick={() => {
                                    dispatch(homeActions.closeNavbar()); dispatch(listingActions.addIsListing(false))
                                }}>Privacy policy</h6></Link>
                                <Link className='text-decoration-none' to='/termsofuse'><h6 onClick={() => {
                                    dispatch(homeActions.closeNavbar()); dispatch(listingActions.addIsListing(false))
                                }}>Terms of use</h6></Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flast'>
                    <div className='flastone'>

                        <a style={{ textDecoration: 'none', color: 'black' }} href="https://www.facebook.com/linkedbricksofficial"><i className="ri-facebook-fill"></i></a>
                        <a style={{ textDecoration: 'none', color: 'black' }} href="https://www.youtube.com/@LinkedBricks23"><i className="ri-youtube-line"></i></a>
                        <a style={{ textDecoration: 'none', color: 'black' }} href="https://www.linkedin.com/company/linked-bricks"><i className="ri-linkedin-fill"></i></a>
                        <a style={{ textDecoration: 'none', color: 'black' }} href="https://www.instagram.com/linkedbricks/"><i className="ri-instagram-line"></i></a>
                    </div>
                    <div className='flasttwo'>
                        <h6>@ 2024 Linked Bricks Limited All rights reserved</h6>
                    </div>
                </div>

            </div>
        </div>

    )
}

export default Footer