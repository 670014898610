import { createSlice } from '@reduxjs/toolkit'

export const initialAuthState = {
    isAuthenticated: localStorage.getItem('token') != null ? true : false,
    userToken: localStorage.getItem('token') || null,
    userEmail: localStorage.getItem('userEmail') || null,
    name: localStorage.getItem('name') || '',
    isProfileUpdated: localStorage.getItem('isProfileUpdated') != null ? true : false,

}

const AuthSlice = createSlice({
    name: 'auth',
    initialState: initialAuthState,
    reducers: {
        login(state, action) {
            state.userToken = action.payload.token
            state.userEmail = action.payload.email
            state.isAuthenticated = true
        },
        logout(state, action) {
            state.userToken = null
            state.userEmail = null
            state.isAuthenticated = false
        },
        addEmail(state, action) {
            state.userEmail = action.payload.email

        },
        addName(state, action) {
            state.name = action.payload.name

        },
        updateIsProfile(state,action){
            state.isProfileUpdated=action.payload
        }
    }
})



export const authActions = AuthSlice.actions
export default AuthSlice.reducer