import axios from 'axios';
// import https from 'https'
// const https = require('https');

// const agent = new https.Agent({
//   rejectUnauthorized: false,
//   requestCert: false,
//   agent: false,
// });

const apiService = axios.create({
  baseURL: 'https://linkedbricks-backend.onrender.com',
  responseType: 'json',
});

// let reqInstance = await axios.create(
//   headers: {
//       Authorization: token
//   }
// })

export const register_user = async (user) => {
  try {
    const response = await apiService.post('/auth/register', user, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json'
      },
    });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export const signIn_provider = async (user) => {
  try {
    const response = await apiService.post('/auth/signup-with-provider', user, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json'
      },
    });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export const login_user = async (user) => {
  try {
    const response = await apiService.post('/auth/login', user, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json'
      },
    });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export const update_password = async (data) => {
  try {
    const response = await apiService.put('/auth/updatePassword', data, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}
export const send_passwordChangeLink = async (data) => {
  try {
    const response = await apiService.post('/auth/updatePassword', data, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}
export const change_password = async (data) => {
  try {
    const response = await apiService.put(`/auth/changePassword/${data.userId}`, data, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}

export const change_email = async (data) => {
  try {
    const response = await apiService.put(`/auth/changeEmail/${data.userId}`, data, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}
export const delete_user = async (data) => {
  try {
    const response = await apiService.delete(`/user/delete-user/${data.userId}`, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
      data: data // Sending data in the request body
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}
export const user_details = async () => {
  let userId = localStorage.getItem('id')

  try {
    const response = await apiService.get(`/user/get-user/${userId}`, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    })
    return response.data;
  }
  catch (error) {
    throw error
  }
}
export const user_dataCounts = async () => {
  let userId = localStorage.getItem('id')

  try {
    const response = await apiService.get(`/user/listing-count/${userId}`)
    return response.data;
  }
  catch (error) {
    throw error
  }
}
export const user_isUpdated = async () => {
  let userId = localStorage.getItem('id')

  try {
    const response = await apiService.get(`/user/is-profileUpdated/${userId}`, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    })
    return response.data;
  }
  catch (error) {
    throw error
  }
}

export const user_messageDetail = async (userId) => {

  try {
    const response = await apiService.get(`/user/get-userDetail/${userId}`, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    })
    return response.data;
  }
  catch (error) {
    throw error
  }
}
export const update_profile = async (data) => {
  try {
    const response = await apiService.put(`/user/update-profile/${data.userId}`, data, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}

export const add_listingAdress = async (data) => {
  try {
    const response = await apiService.post(`/listing/add-listing-address/${data.userId}`, data, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}

export const add_propertyDetail = async (data) => {
  try {
    const response = await apiService.put(`/listing/add-property/${data.listingId}`, data, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}

export const add_propertyAddOns = async (data) => {
  try {
    const response = await apiService.put(`/listing/add-addOns/${data.listingId}`, data, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}

export const get_propertyDetailById = async (data) => {
  try {
    const response = await apiService.get(`/listing/get-user-propertyDetailByID/${data.listingId}/${data.userId}`, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}
export const get_allListing = async (listingType, page, pageSize) => {
  try {
    const response = await apiService.get(`/listing/get-allListing/${listingType}?page=${page}&pageSize=${pageSize}`, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}

export const get_allUserListing = async (userId) => {
  try {
    const response = await apiService.get(`/listing/get-allUserListing/${userId}`, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}

export const get_singleListing = async (id,page,pageSize) => {
  try {
    const response = await apiService.get(`/listing/get-listing/${id}?page=${page}&pageSize=${pageSize}`, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}

export const get_listingByStatus = async (type, status) => {
  const userId = localStorage.getItem('id')
  try {
    const response = await apiService.get(`/listing/get-byStatus/${userId}/${type}/${status}`, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}
export const get_filterListings = async (type, data,) => {


  try {
    const response = await apiService.get(`/listing/filter-listings/${type}?timeCondition=${data.sort}&investmentType=${data.investmentType}&investmentCategory=${data.investmentCategory}&beds=${data.beds}&baths=${data.baths}&propertyType=${data.propertyType}&furnishingOption=${data.furnishingOption}&paymentPlan=${data.paymentPlan}&minPrice=${data.minPrice}&maxPrice=${data.maxPrice}`, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
      //   params: {
      //    priceRange: data.priceRange,
      //    beds:data.beds,
      //     baths:data.baths,
      //    propertyType:data.propertyType,

      // }
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}

export const delete_listing = async (id) => {
  try {
    const response = await apiService.delete(`/listing/delete-listing/${id}`, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}

export const deactive_listing = async (id, status) => {
  try {
    let data = {
      status
    }
    const response = await apiService.put(`/listing/update-status/${id}`, data, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}

export const get_searchResult = async (type, term, data, page, pageSize) => {
  try {
    const response = await apiService.get(`/listing/search-result/${type}?page=${page}&pageSize=${pageSize}&query=${term}&timeCondition=${data.sort}&investmentType=${data.investmentType}&investmentCategory=${data.investmentCategory}&beds=${data.beds}&baths=${data.baths}&propertyType=${data.propertyType}&furnishingOption=${data.furnishingOption}&paymentPlan=${data.paymentPlan}&minPrice=${data.minPrice}&maxPrice=${data.maxPrice}`, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}
export const get_searchSimilarProperty = async (term) => {
  try {
    const response = await apiService.get(`/listing/search-result/?query=${term}`, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}

export const get_searchListing = async (term, listingType) => {
  try {
    const response = await apiService.get(`/listing/search-listing?query=${term}&listingType=${listingType}`, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}
export const save_listing = async (listingData) => {
  const userId = localStorage.getItem('id')
  try {
    const response = await apiService.post(`/saved/save-listing/${userId}/${listingData.listingId}`, listingData, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
export const check_savedListing = async (data) => {
  const userId = localStorage.getItem('id')
  try {
    const response = await apiService.get(`/saved/check-isSaved/${userId}/${data}`, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const fetch_saveListings = async (listingType) => {
  const userId = localStorage.getItem('id')
  try {
    const response = await apiService.get(`/saved/saved-listings/${userId}?listingType=${listingType}`, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;

  } catch (error) {
    throw error.response.data;

  }
};

export const delete_saveListings = async (listingId) => {
  const userId = localStorage.getItem('id')
  try {
    const response = await apiService.delete(`/saved/remove-listing/${userId}/${listingId}`, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;

  } catch (error) {
    throw error.response.data;

  }
};

export const schedule_tour = async (data) => {
  const userId = localStorage.getItem('id')
  try {
    const response = await apiService.post(`/tour/schedule/${userId}`, data, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;

  } catch (error) {
    throw error.response.data;

  }
};


export const send_message = async (data) => {
  try {
    const response = await apiService.post(`/message/send/${data.propertyId}/${data.userId}`, data, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}


export const send_userMessage = async (data) => {
  try {
    const response = await apiService.post(`/message/send-user/${data.messageId}`, data, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}
export const get_allMessage = async () => {
  let userId = localStorage.getItem('id')
  try {
    const response = await apiService.get(`/message/user/${userId}`, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}

export const get_userMessages = async (id) => {
  try {
    const response = await apiService.get(`/message/user-message/${id}`, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}


export const delete_userMessages = async (id) => {
  try {
    const response = await apiService.delete(`/message/delete-message/${id}`, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}

export const get_allScheduleTour = async () => {
  let userId = localStorage.getItem('id')
  try {
    const response = await apiService.get(`/tour/get-scheduleTour/${userId}`, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}

export const get_scheduledTour = async (id) => {
  try {
    const response = await apiService.get(`/tour/${id}`, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}
export const update_scheduledTour = async (id, data) => {
  try {
    const response = await apiService.put(`/tour/status/${id}`, data, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}

export const get_AllReviews = async () => {
  try {
    const response = await apiService.get('/reviews/list-review', {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}
export const get_reviewById = async (id) => {
  try {
    const response = await apiService.get(`/reviews/list-review/${id}`, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}

export const send_notifications = async (data) => {
  try {
    const response = await apiService.post('/notifications/create-notification', data, {
      headers: {
        'x-api-key': 'application/javascript',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token")
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data
  }
}