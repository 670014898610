import { useState, useEffect } from "react";
import React from "react";
import userCircle from "../../../logo/logo/user-circle96.png";
import Input from "../../input/Input";
import { user_details } from "../../../apiServices";
import { update_profile } from "../../../apiServices";
import { Cloudinary } from 'cloudinary-core';
import Avatar from '@mui/material/Avatar';
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/slice/auth-slice";
import { useNavigate } from "react-router-dom";
import './Profile.css'

function MyProfile({ setShowTabs }) {
  const [fullName, setFullName] = useState("");
  const [username, setUsername] = useState("");
  const [number, setNumber] = useState("");
  const [pincode, setPinCode] = useState("");
  const [isUpdated, setIsUpdated] = useState(false)
  const [userPhoto, setUserPhoto] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [fullNameCompleted, setFullNameCompleted] = useState(false);
  const [usernameCompleted, setUsernameCompleted] = useState(false);
  const [numberCompleted, setNumberCompleted] = useState(false);
  const [pincodeCompleted, setPincodeCompleted] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const cloud_name = process.env.REACT_APP_CLOUD_NAME

  const cloudinary = new Cloudinary({ cloud_name: { cloud_name } });
  const upload_preset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
  const allFieldsCompleted = fullName && username && number && pincode;

  const handleImageChange = async (event) => {
    const files = event.target.files;

    for (const file of files) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', upload_preset); // Specify your Cloudinary upload preset

      try {
        const response = await fetch(`https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`, {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          setUserPhoto(data.secure_url);
        } else {
          console.error('Failed to upload image:', response.statusText);
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }


  }
  const fetchData = async () => {
    try {
      const response = await user_details();
      const data = response;
      if (data.fullName != null && data.telephoneNumber != null && data.userName != null && data.postCode != null) {
        setFullName(data.fullName);
        setNumber(data.telephoneNumber);
        setUsername(data.userName);
        setPinCode(data.postCode);
        setUserPhoto(data.photoUrl)
        localStorage.setItem('name', data.fullName)
        localStorage.getItem('userId', data.id)
        const user = {
          name: data.fullName
        }
        dispatch(authActions.addName(user))

      }

    } catch (error) {
      alert(error)
      console.log(error);
    }
  };


  const handleSubmit = async (e) => {
    // if(fullName=='' || number =='' || username=='' || pincode=='' || userPhoto==''){
    //   alert('Please enter all the value')
    // }
    e.preventDefault()
    const data = {
      userId: localStorage.getItem("id"),
      fullName: fullName,
      telephoneNumber: number,
      userName: username,
      pinCode: pincode,
      photoUrl: userPhoto
    }
    try {
      const response = await update_profile(data);
      setIsUpdated(true)
      fetchData()
      dispatch(authActions.updateIsProfile(true))
      localStorage.setItem('isProfileUpdated', true)

    }
    catch (error) {
      alert(error);

    }
  }
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (isUpdated) {
      const timer = setTimeout(() => {
        setIsUpdated(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isUpdated]);

  return (
    <div>
      {
        isUpdated && <div className="col-11 ms-lg-5 ms-3 me-5 p-3 d-flex justify-content-between  bg-[#EBFFFD] rounded-[12px]">
          <div className="text-[18px] font-medium  ms-2">
            Your profile has been successfully updated.
          </div>
          <spna onClick={() => setIsUpdated(false)} className="">
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-8    me-1 "
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </spna>
        </div>
      }
      <form onSubmit={handleSubmit}>
        <div className="  ">

          <div className="profileTitle h-[36px]  font-semibold ml-3 "> <i onClick={() => setShowTabs(true)} className="showTabBack ri-arrow-left-s-line"></i>
            Profile</div>
          <div className="ms-2 h-[154px] text-[28px] font-semibold  ">
            <div className=" h-[114px] my-[20px] text-[28px] font-semibold ">
              {
                userPhoto == '' || userPhoto == undefined ? <img src={userCircle && userCircle} className=" h-[80px] w-[80px]" /> : <Avatar sx={{
                  width: '80px', height: '80px'
                }} alt="" src={userPhoto} />
              }

              <label htmlFor="fileInput" className="text-[#098895] text-[16px] font-medium text-center pl-[14px]">
                Upload
                <input
                  id="fileInput"
                  type="file"
                  onChange={handleImageChange}
                  // onChange={(e) => handleFileChange(e, setSelectedFiles1)}
                  style={{ display: 'none' }} // Hide the input element visually
                />
              </label>
            </div>
          </div>
          <div style={{ boxShadow: ' 0px 3px 8px 2px #3232470d', borderRadius: '20px', padding: '24px', }} className="h-[443px]  ">


            <div className="   h-[312px]  ">
              <h2 className=" h-[32px] profileSubTitle  font-semibold mb-[15px]">
                Personal info
              </h2>
              <div className="">
                <Input
                  extrastyle=" h-[50px]  mb-[20px]"
                  placeholder="Full name"
                  type="text"
                  value={fullName}
                  onChange={(e) => {
                    setFullName(e.target.value)
                    setFullNameCompleted(!!e.target.value); // Set to true if input is not empty

                  }}

                />
              </div>
              <div className="">
                <Input
                  extrastyle=" h-[50px]  mb-[20px]"
                  placeholder="Username"
                  type="text"
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value)
                    setUsernameCompleted(!!e.target.value); // Set to true if input is not empty

                  }}
                />
              </div>
              <div className="">
                <Input
                  extrastyle=" h-[50px]  mb-[20px]"
                  placeholder="Telephone number"
                  type="text"
                  value={number}
                  onChange={(e) => {
                    setNumber(e.target.value)
                    setNumberCompleted(!!e.target.value); // Set to true if input is not empty

                  }}
                />
              </div>
              <div className="">
                <Input
                  extrastyle=" h-[50px]  "
                  placeholder="Post code"
                  type="text"
                  value={pincode}
                  onChange={(e) => {
                    setPinCode(e.target.value)
                    setPincodeCompleted(!!e.target.value); // Set to true if input is not empty

                  }}
                />
              </div>
            </div>
            <div className="mt-[24px]  h-[50px]  ">
              <button disabled={!allFieldsCompleted}
                style={{
                  fontWeight: '500', color: '#0D0E0F', fontSize: '18px', opacity: allFieldsCompleted ? '1' : '0.4' // Adjust opacity based on completion status
                }} type="submit" className="w-[178px] float-right  text-[18px] rounded-[12px] px-[16px] py-[12px] font-medium bg-[#E8AE00] "
              >
                Save changes
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 float-right "
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

      </form>

    </div>
  );
}

export default MyProfile;
