// Import necessary dependencies
import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const Circlegraph = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    // Create a Chart.js instance
    const ctx = chartRef.current.getContext('2d');
    const myChart = new Chart(ctx, {
      type: 'doughnut', // You can use 'pie' for a full circle
      data: {
        labels: ['Impression Count', 'Saved Count', 'Message Count'],
        datasets: [
          {
            data,
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCD56'],
          },
        ],
      },
    });

    // Clean up the Chart.js instance on component unmount
    return () => {
      myChart.destroy();
    };
  }, [data]);

  return (
    <div >
      <canvas className='col-2 ' ref={chartRef} width="10" height="10"></canvas>
    </div>
  );
};

export default Circlegraph;
