import React, { useState, useEffect } from 'react';
import { Link, useLocation,useNavigate} from 'react-router-dom';
import Signin from '../Popupfile/Signin';
import maillogo from '../../assets/Images/navimage/hdlogo.svg';
import Addpounds from '../../assets/Images/navimage/Home pounds.png';
import Homepounds from '../../assets/Images/navimage/homepng.png';
import Salepounds from '../../assets/Images/navimage/salepng.png';
import Rentpounds from '../../assets/Images/navimage/rentpng.png';
import Investmentpounds from '../../assets/Images/navimage/investmentpng.png';
import Blogpounds from '../../assets/Images/navimage/blogpng.png';
import Accountpounds from '../../assets/Images/navimage/accountpng.png';
import { useSelector } from 'react-redux';
import DashboardPage from '../dashboard/DashboardPage';
import WarningModal from '../AddListing/WarningModal';
import CancelIcon from '../../assets/cancel-icon.png'
import './Navbar.css'
import { listingActions } from '../../store/slice/listing-slice';
import { homeActions } from '../../store/slice/home-slice';
import { useDispatch} from 'react-redux';
import LOGO from '../../assets/Images/navimage/LOGO.png'

const Navbar = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);
  const location = useLocation();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const [isOpen,setIsOpen]=useState(false)
  const isAddListing=useSelector(state=>state.listing.isAddListing)
  const isNavbarOpen=useSelector(state=>state.home.isNavbarOpen)
  const [isAuth,setIsAuth]=useState(false)
  const dispatch=useDispatch()

  const navigate = useNavigate();
const [activeItem, setActiveItem] = useState("home");
const [selectedLink, setSelectedLink] = useState('Home'); // Initialize selectedLink with 'Home'

const handleCloseLink = () => {
  // This function is called when any link is clicked
  setSelectedLink('');
};

const handleLinkClick = (linkName) => {
  setSelectedLink(linkName);
};

  const onSignInClick = () => {
    setIsUserSignedIn(!isUserSignedIn);
    navigate('/dashboard/my-propertymain');
  };

  const handleOpen = () => {
    setPopupOpen(true);
    dispatch(homeActions.openNavbar())
  };

  const handleClose = () => {
    setPopupOpen(false);
    dispatch(homeActions.closeNavbar())

  };

  const handleItemClick = (item) => {
    setActiveItem(item);
    setPopupOpen(false);
    dispatch(homeActions.closeNavbar())

  };

  const handleSign = (item) => {
    setActiveItem(item);
    setOpen(true);
    setPopupOpen(false);
    dispatch(homeActions.closeNavbar())

    setIsAuth(true)
  };


  // useEffect(() => {
  //   setActiveItem(location.pathname);
  // }, [location.pathname]);
  const handleImageClick = () => {
    if (isAddListing) {
      setIsOpen(true);
    } else {
      navigate('/');
    }
  };
  useEffect(() => {
    const handlePopState = () => {
      // Check if isAddListing is true when navigating back
      if (isAddListing) {
        setIsOpen(false);
        // Replace the current URL to prevent going back
        dispatch(listingActions.addIsListing(false))
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [isAddListing, setIsOpen]);

  return (
    <div className="w-100  " style={{ position: 'fixed', zIndex: '999',boxShadow: '0px 3px 8px -1px #3232470D',backgroundColor:'white'}}>
      <div className={`d-flex justify-content-between navBarContainer align-items-center ${location.pathname.includes('dashboard')||window.innerWidth<700 ? 'px-4' : 'px-20'}`}>
      <img onClick={handleImageClick} style={{cursor:'pointer'}} className={`maillogo mt-2 mb-2 ms-lg-0 ${window.innerWidth<700 ? 'ms-1' : 'ms-5'}`} src={maillogo} alt="" />

       <img src={LOGO} className='maillogoMobile' />
        {isNavbarOpen && (
          <div
          className='col-7  d-lg-none bg-white px-3 '
          style={{ position: 'absolute', top: '7vmax', right: '0.5px', zIndex: '999',height:'100vh',borderRadius:'20px 0px 0px 20px', }}
        >
          <Link         onClick={() => { handleItemClick('Home'); }}
          style={{ textDecoration: 'none', color: 'black' }} to="/">
            {' '}
            <h6 className={activeItem === 'Home' ? 'navbarMenu__textSelected navbarMenu__text' : 'navbarMenu__text'}>
              Home
            </h6>
          </Link>
          <Link  onClick={() => { handleItemClick('For sale');  }} style={{ textDecoration: 'none', color: 'black' }} to="/forsale">
            <h6 className={activeItem === 'For sale' ? 'navbarMenu__textSelected navbarMenu__text' : 'navbarMenu__text'}>For sale</h6>
          </Link>
          <Link  onClick={() => { handleItemClick('For rent');  }}  style={{ textDecoration: 'none', color: 'black' }} to="/forrent">
            <h6 className={activeItem === 'For rent' ? 'navbarMenu__textSelected navbarMenu__text' : 'navbarMenu__text'}>For rent</h6>
          </Link>
          <Link
          onClick={() => { handleItemClick('For investment');  }}
            style={{ textDecoration: 'none', color: 'black' }}
            to="/forinvestment"
          >
            <h6 className={activeItem === 'For investment' ? 'navbarMenu__textSelected navbarMenu__text' : 'navbarMenu__text'}>For investment</h6>
          </Link>
          <Link onClick={() => { handleItemClick('Blog');  }} style={{ textDecoration: 'none', color: 'black' }} to="/blog">
            <h6 className={activeItem === 'Blog' ? 'navbarMenu__textSelected navbarMenu__text' : 'navbarMenu__text'}>Blog</h6>
          </Link>

          {isAuthenticated ? (
            <Link  onClick={() => { handleItemClick('Account'); }} style={{ textDecoration: 'none', color: 'black' }} to="/dashboard/my-propertymain">

            <h6 className={activeItem === 'Account' ? 'navbarMenu__textSelected navbarMenu__text' : 'navbarMenu__text'} onClick={handleClose}>
              Account
            </h6>
            </Link>
          ) : (
            <h6 className='navbarMenu__text' onClick={() => handleSign('signin')}>
              Sign in
            </h6>
          )}
          <Link
            onClick={handleClose}
            className='text-decoration-none text-dark'
            to="/addlisting"
          >
            <button
              className='navbarMenu__cta  fw-semibold d-flex  mb-5'
            >
              <img src={Addpounds} alt="" style={{marginRight:'6px'}} /> Add Listing
            </button>
          </Link>
        </div>
         
        )}
        {isNavbarOpen && isAddListing==true && (
          <div
            className='col-7  d-lg-none bg-white px-3 '
            style={{ position: 'absolute', top: '7vmax', right: '0.5px', zIndex: '999',height:'100vh',borderRadius:'20px 0px 0px 20px', }}
          >
              {' '}
              <h6 onClick={() => { handleClose();setIsOpen(true) }} className={activeItem === 'Home' ? 'navbarMenu__textSelected navbarMenu__text' : 'navbarMenu__text'}>
                Home
              </h6>
              <h6 onClick={() => {handleClose(); setIsOpen(true) }} className={activeItem === 'For sale' ? 'navbarMenu__textSelected navbarMenu__text' : 'navbarMenu__text'}>For sale</h6>
              <h6 onClick={() => { handleClose(); setIsOpen(true) }} className={activeItem === 'For rent' ? 'navbarMenu__textSelected navbarMenu__text' : 'navbarMenu__text'}>For rent</h6>

              <h6  onClick={() => { handleClose(); setIsOpen(true)  }} className={activeItem === 'For investment' ? 'navbarMenu__textSelected navbarMenu__text' : 'navbarMenu__text'}>For investment</h6>
              <h6 onClick={() => {handleClose(); setIsOpen(true)  }}  className={activeItem === 'Blog' ? 'navbarMenu__textSelected navbarMenu__text' : 'navbarMenu__text'}>Blog</h6>


            {isAuthenticated ? (

              <h6 onClick={() => { handleClose(); setIsOpen(true)}} className={activeItem === 'Account' ? 'navbarMenu__textSelected navbarMenu__text' : 'navbarMenu__text'} >
                Account
              </h6>
            ) : (
              <h6 className='navbarMenu__text' onClick={() => handleSign('signin')}>
                Sign in
              </h6>
            )}
           
              <button
                className='navbarMenu__cta  fw-semibold d-flex  mb-5'
                onClick={()=>{handleClose();setIsOpen(true)}}
              >
                <img src={Addpounds} alt="" style={{marginRight:'6px'}} /> Add Listing
              </button>
          </div>
        )}
        {
          isAddListing==true ? (
            <div className='cursorHover d-lg-flex d-none  d-lg-initial gap-2 justify-content-center align-items-center'>
           
            <>
            <h5
            style={{ backgroundColor: activeItem === 'home' ? '#ebfffd' : 'transparent' }}
            onClick={()=>setIsOpen(true)}
          >
            {activeItem ==='home' && 
            <img className='mb-1' style={{ display: 'inline' }} src={Homepounds} alt="" />
            } Home
          </h5>
            </>
            <>
            <h5
            style={{
              backgroundColor: activeItem === 'forsale' ? '#ebfffd' : 'transparent',
            }}
            onClick={()=>setIsOpen(true)}
          >
            {activeItem ==='forsale' && 
            <img className='mb-1' style={{ display: 'inline' }} src={Salepounds} alt="" />
            } 
            For sale
          </h5>
            </>
            <>
            <h5
              style={{
                backgroundColor: activeItem === 'forrent' ? '#ebfffd' : 'transparent',
              }}
              onClick={()=>setIsOpen(true)}
            >
              {activeItem ==='forrent' && 
              <img className='mb-1' style={{ display: 'inline' }} src={Rentpounds} alt="" />
              } 
              For rent
            </h5>
            </>
           
           <>
           <h5
             style={{
               backgroundColor: activeItem === 'forinvestment' ? '#ebfffd' : 'transparent',
             }}
             onClick={()=>setIsOpen(true)}
           >
             {activeItem ==='forinvestment' && 
             <img className='mb-1' style={{ display: 'inline' }} src={Investmentpounds} alt="" />
             } 
             For investment
           </h5>
           </>
           <>
                     
           <h5
             style={{
               backgroundColor: activeItem === 'blog' ? '#ebfffd' : 'transparent',
             }}
             onClick={()=>setIsOpen(true)}
           >
             {activeItem ==='blog' && 
             <img className='mb-1' style={{ display: 'inline' }} src={Blogpounds} alt="" />
             } 
             Blog
           </h5>
           </>

            {isAuthenticated ? (
            
                                <h5 style={{ backgroundColor: activeItem === 'account' ? '#ebfffd' : 'transparent' }}
                onClick={()=>setIsOpen(true)}>
                {activeItem ==='account' && 
                <img className='mb-1' style={{ display: 'inline' }} src={Accountpounds} alt="" />
                } 
                  Account
                </h5>
            ) : (
              <Link className='hoverLink' style={{ textDecoration: 'none' }}>
                {' '}
                <h5
                  style={{
                    backgroundColor: activeItem === 'signin' ? '#ebfffd' : 'transparent',
                  }}
                  onClick={() => handleSign('signin')}
                >
                  {activeItem ==='signin' && 
                <img className='mb-1' style={{ display: 'inline' }} src={Accountpounds} alt="" />
                } 
                  Sign In
                </h5>
              </Link>
            )}
          </div>
          ):(
            <div className='cursorHover d-lg-flex d-none  d-lg-initial gap-2 justify-content-center align-items-center'>
            <Link className='hoverLink' style={{ textDecoration: 'none'}} to="/">
              <h5
                style={{ backgroundColor: activeItem === 'Home' ? '#ebfffd' : 'transparent' }}
                onClick={() => handleItemClick('Home')}
              >
                {activeItem ==='Home' && 
                <img className='mb-1' style={{ display: 'inline' }} src={Homepounds} alt="" />
                } Home
              </h5>
            </Link>
            <Link className='hoverLink' style={{ textDecoration: 'none' }} to='/forsale'>
              <h5
                style={{
                  backgroundColor: activeItem === 'For sale' ? '#ebfffd' : 'transparent',
                }}
                onClick={() => handleItemClick('For sale')}
              >
                {activeItem ==='For sale' && 
                <img className='mb-1' style={{ display: 'inline' }} src={Salepounds} alt="" />
                } 
                For sale
              </h5>
            </Link>
            <Link className='hoverLink' style={{ textDecoration: 'none' }} to='/forrent'>
              <h5
                style={{
                  backgroundColor: activeItem === 'For rent' ? '#ebfffd' : 'transparent',
                }}
                onClick={() => handleItemClick('For rent')}
              >
                {activeItem ==='For rent' && 
                <img className='mb-1' style={{ display: 'inline' }} src={Rentpounds} alt="" />
                } 
                For rent
              </h5>
            </Link>
            <Link className='hoverLink' style={{ textDecoration: 'none' }} to='/forinvestment'>
              <h5
                style={{
                  backgroundColor: activeItem === 'For investment' ? '#ebfffd' : 'transparent',
                }}
                onClick={() => handleItemClick('For investment')}
              >
                {activeItem ==='For investment' && 
                <img className='mb-1' style={{ display: 'inline' }} src={Investmentpounds} alt="" />
                } 
                For investment
              </h5>
            </Link>
            <Link className='hoverLink' style={{ textDecoration: 'none' }} to='/blog'>
              <h5
                style={{
                  backgroundColor: activeItem === 'Blog' ? '#ebfffd' : 'transparent',
                }}
                onClick={() => handleItemClick('Blog')}
              >
                {activeItem ==='Blog' && 
                <img className='mb-1' style={{ display: 'inline' }} src={Blogpounds} alt="" />
                } 
                Blog
              </h5>
            </Link>
            {isAuthenticated ? (
              <Link className='hoverLink' style={{ textDecoration: 'none' }}
              to='/dashboard/my-propertymain'
              >
                
                {' '}
                <h5 style={{ backgroundColor: activeItem === 'Account' ? '#ebfffd' : 'transparent' }}
                onClick={() => handleItemClick('Account')}>
                {activeItem ==='Account' && 
                <img className='mb-1' style={{ display: 'inline' }} src={Accountpounds} alt="" />
                } 
                  Account
                </h5>
              </Link>
            ) : (
              <Link className='hoverLink' style={{ textDecoration: 'none' }}>
                {' '}
                <h5
                  style={{
                    backgroundColor: activeItem === 'Signin' ? '#ebfffd' : 'transparent',
                  }}
                  onClick={() => handleSign('Signin')}
                >
                  {activeItem ==='Signin' && 
                <img className='mb-1' style={{ display: 'inline' }} src={Accountpounds} alt="" />
                } 
                  Sign In
                </h5>
              </Link>
            )}
          </div>
          )
        }
      
          <div>
        {
          isAddListing ?
          (
<>
            <button
              className='border border-none fw-semibold p-2  d-none d-lg-flex align-items-center'
              style={{ backgroundColor: location.pathname.includes('add') ? 'rgba(181,136,0,1)' : '#e8ae00',borderRadius:"12px",height:'55px', width:'173px', justifyContent:'center',borderRadius:'12px',fontSize:'17px', fontWeight:'500'  }}
              onClick={()=>setActiveItem("")}
            >
              <img src={Addpounds} alt="" style={{marginRight:'6px'}} /> Add Listing
            </button>
</>
          ):(
            <Link className='text-decoration-none text-dark' to="/addlisting">
            <button
              className='border border-none fw-semibold p-2  d-none d-lg-flex align-items-center'
              style={{ backgroundColor: location.pathname.includes('add') ? 'rgba(181,136,0,1)' : '#e8ae00',borderRadius:"12px",height:'55px', width:'173px', justifyContent:'center',borderRadius:'12px',fontSize:'17px', fontWeight:'500'   }}
              onClick={()=>setActiveItem("")}
            >
              <img src={Addpounds} alt="" style={{marginRight:'6px'}} /> Add Listing
            </button>
          </Link>
          )
        }
        </div>
        {isNavbarOpen ? (
          <img src={CancelIcon} onClick={handleClose} className='fs-[40px]' />
        ) : (
          <i onClick={handleOpen} className={`ri-menu-line d-lg-none  fw-bold fs-2 ${window.innerWidth<700 ? 'ms-1' : 'ms-5'} `}></i>
        )}
      </div>
      <Signin isAuth={isAuth} open={open} setOpen={setOpen} onSignInClick={onSignInClick} />
      <WarningModal isOpen={isOpen} setIsOpen={setIsOpen} />

       </div>
  );
};

export default Navbar;

