import React, { useState, useEffect } from 'react'
import "./Declined.css"
import InactiveImg from "../../../assets/Images/property/Inactivve.png"
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import DeleteProperty from '../../Popupfile/DeletePropert'
import DeactivateProperty from "../../Popupfile/DeactivateProperty";
import Insight from '../../Popupfile/Insight';
import { deactive_listing, delete_listing, get_listingByStatus } from '../../../apiServices';
import Info from '../../../assets/info.png'
import Delete from '../../../assets/Delete/Vector (2).png'
import bathub24 from "../../../assets/Images/property/bathub24.png";
import PhotoImg from '../../../assets/image2.png'
import MoreOption from '../../../assets/Group.png'
import More from "../../Popupfile/MoreOption";
import InvestIcon from '../../../assets/InvestIcon.png'
import Chairs24 from "../../../assets/Images/property/Chairs24.png";
const Declined = ({ activeLink, userListings }) => {

  const [deleted, setDeleted] = useState(false)
  const [deactive, setDeactive] = useState(false)
  const [insight, setInsight] = useState(false)
  const [propertyId, setPropertyId] = useState('')
  const [isDeleted, setIsDeleted] = useState(false)
  const [loading, setLoading] = useState(true);
  const [more, setMore] = useState(false)
  const [isDeclinePop,setIsDeclinedPop]=useState(false)

  const navigate = useNavigate();
  const location = useLocation();
  const [listings, setListings] = useState([])
  const { link } = useParams()

  const filterListings = async () => {
    let listingType;
    if (link == 'for-sale') {
      listingType = 'FOR_SALE'
    } else if (link == 'for-rent') {
      listingType = 'FOR_RENT'

    } else {
      listingType = 'FOR_INVESTMENT'

    }

    try {
      setLoading(true)

      const res = await get_listingByStatus(listingType, 'DECLINE')
      setListings(res.listings)
      setLoading(false)

    } catch (err) {
      setLoading(false)

    }

  };
  const handleDeleteListing = async () => {
    try {
      const response = await delete_listing(propertyId)
      // Optionally update the state or fetch listings again
      setDeleted(false)
      setIsDeleted(true)
      filterListings()
    } catch (error) {
      console.error(error);
      // Handle error
    }
  }

  const handleUpdateStatus = async () => {
    try {
      let status = 'INACTIVE'
      const response = await deactive_listing(propertyId, status)
      // Optionally update the state or fetch listings again
      setDeactive(false)
      alert('Prooperty successfully deactivated from listing!')
      filterListings()

    } catch (error) {
      console.error(error);
      // Handle error
    }
  };

  useEffect(() => {
    filterListings()
  }, [location])
  return (
    <div className='Declined'>
      {
        isDeleted && <div className="col-11 ms-3 me-5 mb-4 p-3 d-flex justify-content-between  bg-[#EBFFFD] rounded-[12px]">
          <div className="text-[16px] font-medium  ms-2">
            Your listing has been successfully deleted.
          </div>
          <spna onClick={() => setIsDeleted(false)} className="">
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-8    me-1 "
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </spna>
        </div>
      }
      <div class="d-flex flex-lg-row flex-column ">
        <div class="col">
          <div className="myPropertyTitle  text-[26px] font-semibold ">My properties</div>
        </div>
        <div class="col">
          <div className="myPropertyOptions w-[308px] mt-[6px] h-[24px] text-center ">

            <p style={{ cursor: "pointer" }} className={`myPropertyTitle float-left text-[18px] font-medium pr-[24px] ${location.pathname.includes('for-sale') ? 'active-link' : ' '}`}

              onClick={() => navigate('/dashboard/my-propertymain')}>
              For sale
            </p>
            <p style={{ cursor: "pointer", }} onClick={() => navigate('/dashboard/for-rent')}
              className={`myPropertyTitle float-left text-[18px] font-medium pr-[24px] ${location.pathname.includes('for-rent') ? 'active-link' : ' '}`}>
              For rent

            </p>
            <p style={{ cursor: "pointer", }} onClick={() => navigate('/dashboard/for-investment')}
              className={`myPropertyTitle text-[18px] font-medium ${location.pathname.includes('for-investment') ? 'active-link' : ' '}`}>
              For investment</p>
          </div>
        </div>
        <div class="col"></div>
      </div>
      <div className=' '>
        <div class="p-0 container ">
          <div class="row">
            <div class="p-0 ml-3 col">
              <p style={{ cursor: "pointer", }} onClick={() => navigate('/dashboard/my-propertymain')} className="myPropertyTitle text-start text-[18px] font-semibold">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="myPropertyBackIcon w-5 h-5 float-left mt-[3px]"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
                </svg>
                Declined
              </p>
            </div>
            <div class="col myPropertyTotalValue
        mr-6">
              <p className=" text-[18px] font-semibold text-[#098895] text-end myPropertyTitle  ">
                {listings.length} Properties
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="Propertycrds">
        {
          loading ? (
            <h6 className='notifyLoading'>Loading...</h6>

          ) : (
            <>
              {
                listings.length != 0 ? (
                  listings?.map((listings, i) => {
                    return (
                      <>
                        <div style={{ opacity: '0.7' }} key={i} className="CHouses">
                          <div className="cLeft">
                            <div className="  w-[100%] position-relative">
                              <img style={{
                                height: '364px', borderTopLeftRadius: '12px', // Border radius for the top-left corner
                                borderBottomLeftRadius: '12px'
                              }} src={listings?.listingMedia[0]} className='myPropertyImg' />
                              {/* Overlay */}
                              <div
                                style={{
                                  position: 'absolute',
                                  bottom: '15px',
                                  right: '15px',
                                  backgroundColor: '#F9FAFB',
                                  color: '#0D0E0F',
                                  padding: '4px 8px',
                                  borderRadius: '12px',
                                  fontSize: '14px',
                                  fontWeight: '400',
                                  display: 'flex'
                                }}
                                className='myPropertyImg__total'

                              >
                                <img
                                  style={{ width: '12px', height: '12px', marginTop: '5px', marginRight: '3px' }}
                                  src={PhotoImg}
                                  alt=''
                                />

                                {0 + 1}/{listings.listingMedia.length}
                              </div>
                            </div>

                          </div>
                          <div className="cRight">
                            <div className="tp">

                              <div className="price">
                                <div className='declinedHint' style={{ display: 'flex', backgroundColor: " #F9FAFB", fontSize: '14px', padding: '4px 8px', borderRadius: '8px', marginBottom: '10px' }}>
                                  <img style={{ width: "12px", height: '12px', marginTop: '5px', marginRight: '5px', }} src={Info} />
                                  <span >Hint: EPC verification failed</span>

                                </div>
                                <div onClick={() => navigate(`/property-detail/${listings?._id}`)}>{link == 'for-rent' && <h2 className=" myPropertyPrice text-[36px] font-semibold ">
                                £{listings?.rentalPrice} </h2>}
                                {link == 'for-sale' && <h2 className="myPropertyPrice text-[36px] font-semibold ">
                                  £{listings?.price}</h2>}
                                {link == 'for-investment' && <h2 className="myPropertyPrice text-[36px] font-semibold ">
                                  £{listings?.minInvestment}</h2>}</div>
                                <div className=' myPropertyIcon2 d-flex '>
                                  <img style={{ width: "9px", height: '9px', marginRight: '15px',marginTop: '3px' }} src={Info} />

                                  <img style={{ height: '12px'}} onClick={() => {setMore(true)
                                    setIsDeclinedPop(true)
                                  }} src={MoreOption} />

                                </div>
                              </div>

                            </div>
                            <div style={{ cursor: 'pointer' }} onClick={() => navigate(`/property-detail/${listings?._id}`)} className="myPropertyContainer2 md">
                              <h3 className='myPropertyType myPropertyType2'>{listings?.propertyType}</h3>
                              {
                                listings?.addressLine1 ? (
                                  <p className='myPropertyPlace myPropertyPlace2'>{listings?.addressLine1}, {listings?.town} </p>

                                ) : (
                                  <p className='myPropertyPlace myPropertyPlace2'>{listings?.postalCode} </p>

                                )
                              }                      </div>
                            <div className="declinedButtons lt">
                              <button style={{ border: "2px solid #E8AE00" }}><i class="ri-edit-box-fill"></i>Edit & Publish</button>
                              <button style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: "2px solid #E53935" }} onClick={() => {
                                setPropertyId(listings?._id);

                                setDeleted(true)
                              }} >
                                <img style={{ width: '11px', height: '14px' }} src={Delete} />
                                <span style={{ marginLeft: '6px' }}>
                                  Delete
                                </span></button>
                            </div>
                            <div className="myPropertyContainer3 bm">
                              <p><i class="ri-hotel-bed-fill"></i>{listings?.numberofBedrooms}<span className='myPropertyContainer3__iconsSpan'>beds</span></p>
                              <p><img
                                src={bathub24}
                                alt=""
                                className="w-[24px] h-[24px] float-left mx-[4px] "
                              />{listings?.numberofBathrooms}<span className='myPropertyContainer3__iconsSpan'>baths</span></p>
                              <p>
                              {
                                link=='for-investment' ?(
                                  <>
                                  <img
                                  src={InvestIcon}
                                  alt=""
                                  className="myPropertyContainer3__icons w-[24px] h-[24px] float-left ]"
                                  style={{ filter: 'brightness(0)', color: 'black' }} 
                                />{listings?.investmentType}
                                </>
                                ):(
                                  <>
                                  <i class="ri-sofa-fill"></i>{listings?.furnishingOption}
                                </>
                                )
                              }
                              
                            </p>
                            </div>
                          </div>
                        </div>
                        <More setPropertyId={setPropertyId} isDeclinePop={isDeclinePop} more={more} setMore={setMore} listingId={listings?._id} setDeleted={setDeleted} />

                      </>
                    )
                  })
                ) : (
                  <h6>You don't have any declined property yet</h6>
                )
              }
            </>
          )
        }


      </div>
      <DeleteProperty handleDeleteListing={handleDeleteListing} deleted={deleted} setDeleted={setDeleted} />
      <DeactivateProperty handleUpdateStatus={handleUpdateStatus} deactive={deactive} setDeactive={setDeactive} />
    </div>
  )
}

export default Declined