import React, { useState, useEffect } from 'react'
import DeclineTour from '../../Popupfile/DeclineTour'
import leeds from "../../../assets/Images/property/featuredimg2.jpg"
import { useNavigate, useParams } from 'react-router-dom'
import { get_scheduledTour, update_scheduledTour } from '../../../apiServices'
import './ScheduleTour.css'
import ActiveIcon from '../../../assets/Active.png'
import { formatInTimeZone } from 'date-fns-tz';


const ScheduleTour = () => {

  const navigate = useNavigate()
  const [declineTour, setDeclineTour] = useState(false)
  const [tour, setTour] = useState({})
  const { id } = useParams()


  const getTour = async () => {
    try {
      const response = await get_scheduledTour(id)
      setTour(response)
    } catch (err) {
      console.log(err)
    }
  }
  const updateTourStatus = async (status) => {
    try {
      const data = {
        status,
      }
      const response = await update_scheduledTour(id, data)
      setTour(response)
    } catch (err) {
      console.log(err)
    }
  }

  const handleDate = (timestamp) => {
    const date = new Date(timestamp);

    // Define options for formatting the date and time
    const options = { month: 'short', day: '2-digit', year: 'numeric' };

    // Format the date and time using toLocaleString()
    const formattedDateTime = date.toLocaleString('en-US', options);

    return formattedDateTime
  }

  // const handleTime = (inputTime) => {
  // const inputTimeZone = 'Europe/Paris'; // GMT +1

  // // Parse the input time as a Date object (assuming today's date)
  // const parsedTime = parse(inputTime, 'HH:mm', new Date());

  // // Convert the parsed time to UTC
  // const utcTime = zonedTimeToUtc(parsedTime, inputTimeZone);

  // // Add 11 hours to convert to 1 PM in the same time zone
  // const newTime = addHours(utcTime, 11);

  // // Format the new time in 12-hour format with AM/PM
  // const formattedTime = formatTZ(newTime, 'h a', { timeZone: inputTimeZone });
  // return formattedTime
  // }
  function convertTimeToGMT(timeString) {
    console.log(timeString)
    // Parse the time string
    if (!timeString) {
      return 'Invalid time';
    }
  
    // Parse the time string
    const timeParts = timeString.split(':');
    if (timeParts.length !== 2) {
      return 'Invalid time format';
    }
  
    const [hours, minutes] = timeParts.map(Number);
  
    // Check if hours and minutes are valid
    if (isNaN(hours) || isNaN(minutes) || hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
      return 'Invalid time';
    }
  
    // Adjust hours for GMT +1
    let gmtHours = hours + 1;
    if (gmtHours > 12) {
      gmtHours -= 12;
    }
    const amPm = gmtHours >= 12 ? 'pm' : 'am';
  
    // Format the time
    const formattedTime = `${gmtHours}${amPm} (GMT +1)`;
  
    return formattedTime;
  }
  useEffect(() => {
    getTour()
  }, [])

  return (
    <div className='ScheduleTour'>
      <>
        <div>
          <h3>Message</h3>
          <div className='d-flex align-items-center'>
            <i style={{ cursor: 'pointer' }} onClick={() => navigate('/dashboard/my-account/message')} className='ri-arrow-left-s-line fs-4'></i>
            {tour?.property?.listingMedia?.length > 0 ? (
              <img
                className='rounded me-2 ms-2'
                src={tour.property.listingMedia[0]}
                width={80}
                alt=""
              />
            ) : (
              <img
                className='rounded me-2 ms-2'
                src={leeds}
                width={80}
                alt="Fallback"
              />
            )}            <div className=''>
              {
                tour?.property?.addressLine1 ? (
                  <h5 className='messageMainPlace' style={{ color: "#098895" }}>{tour?.property?.addressLine1}</h5>

                ) : (
                  <h5 className='messageMainPlace' style={{ color: "#098895" }}>{tour?.property?.postalCode}</h5>

                )
              }
              <p style={{ marginBottom: '0' }} className='messageMainName text-warning'>{tour?.user?.fullName}</p>
            </div>
          </div>
          <div style={{
            border: 'none', padding: '24px'
          }} className='tourSub col-10 form-control text-center'>
            <p>{handleDate(tour?.currentDate)}</p>
            <div style={{ backgroundColor: '#F9FAFB', border: 'none',padding:'20px' }} className=' tourSub2 col-8 form-control d-flex flex-column align-items-center '>
              <h3>Tour Details</h3>
              <p>Time:{convertTimeToGMT(tour?.scheduledTime)}</p>
              <p>Date: {handleDate(tour?.scheduledDate)}</p>
              <p>Organizer: <span style={{ color: "#098895", fontWeight: "700" }}>{tour?.organizer}</span></p>
              <div className='mb-4 tourButtons col-5 d-flex justify-content-evenly'>
                {
                  tour?.status == 'pending' && <>
                    <button className='border border-danger p-2 px-5  me-2' onClick={() => setDeclineTour(true)}>Decline</button>
                    <button onClick={() => updateTourStatus('accepted')} className='bg-warning p-2 px-5  ms-2'>Accept</button>
                  </>
                }

                {
                  tour?.status == 'accepted' && <>
                    <button className='bg-warning p-2 px-5  ms-2'>Accepted</button>
                  </>
                }
                {
                  tour?.status == 'declined' && <>
                    <button className='border border-danger p-2 px-5  me-2'>Declined</button>
                  </>
                }
              </div>
            </div>
          </div>
        </div>

      </>
      <DeclineTour updateTourStatus={updateTourStatus} declineTour={declineTour} setDeclineTour={setDeclineTour} />
    </div>
  )
}

export default ScheduleTour