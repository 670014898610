import React, { useState, useEffect } from "react";
import "../MyPropertymain.css";
import myproperyimg from "../../../../assets/Images/property/myproperty.png";
import editIcons from "../../../../assets/Images/property/editIcons24.png";
import insideIcons from "../../../../assets/Images/property/insideIcons24.png";
import bubleIcons from "../../../../assets/Images/property/bubleIcons24.png";
import deletIcons from "../../../../assets/Images/property/deletIcons24.png";
import Insight from '../../../Popupfile/Insight';
import './ActiveProperty.css'
import bed24 from "../../../../assets/Images/property/bed24.png";
import bathub24 from "../../../../assets/Images/property/bathub24.png";
import Chairs24 from "../../../../assets/Images/property/Chairs24.png";
import MoreOption from '../../../../assets/Group.png'
import DeleteProperty from '../../../Popupfile/DeletePropert'
import DeactivateProperty from "../../../Popupfile/DeactivateProperty";
import PhotoImg from '../../../../assets/image2.png'
import More from "../../../Popupfile/MoreOption";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { deactive_listing, delete_listing, get_listingByStatus } from "../../../../apiServices";
import axios from "axios";
import { UseDispatch, useDispatch } from "react-redux";
import { listingActions } from "../../../../store/slice/listing-slice";
import BoostedImg from '../../../../assets/Boost.png'

import InvestIcon from '../../../../assets/InvestIcon.png'
function ActiveProperty(props) {

  const { activeLink, userListings, status, setShowTabs } = props;
  const [deleted, setDeleted] = useState(false)
  const [deactive, setDeactive] = useState(false)
  const [insight, setInsight] = useState(false)
  const [activeListings, setActiveListings] = useState([])
  const [propertyId, setPropertyId] = useState('')
  const [insightData, setInsightData] = useState({})
  const [isDeleted, setIsDeleted] = useState(false)
  const location = useLocation()
  const [isDeactive, setIsDeactive] = useState(false)
  const { link } = useParams()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true);
  const [more, setMore] = useState(false)

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const filterListings = async () => {
    // console.log(userListings)
    let listingType;
    if (link == 'for-sale') {
      listingType = 'FOR_SALE'
    } else if (link == 'for-rent') {
      listingType = 'FOR_RENT'

    } else {
      listingType = 'FOR_INVESTMENT'

    }
    try {
      setLoading(true)
      const res = await get_listingByStatus(listingType, 'ACTIVE')
      setActiveListings(res.listings)
      setLoading(false)

    } catch (err) {
      setLoading(false)

    }

  };
  const handleDeleteListing = async () => {
    try {
      const response = await delete_listing(propertyId)
      // Optionally update the state or fetch listings again
      setDeleted(false)
      setIsDeleted(true)
      // alert('Prooperty successfully deleted from listing!')
      filterListings()
    } catch (error) {
      console.error(error);
      setActiveListings([])
      // Handle error
    }
  }

  const handleUpdateStatus = async () => {
    try {
      let status = 'INACTIVE'
      const response = await deactive_listing(propertyId, status)
      // Optionally update the state or fetch listings again
      setDeactive(false)
      setIsDeactive(true)
      filterListings()

    } catch (error) {
      console.error(error);
      // setActiveListings([])
      // Handle error
    }
  };
  const getPropertyImpressionInsights = async (id) => {
    try {
      // Make a GET request to the backend endpoint
      dispatch(listingActions.addListingId(id))

      setPropertyId(id)
      const response = await axios.get(`https://linkedbricks-backend.onrender.com/listing/get-propertyInsight/${id}`);
      // Extract the impression insights from the response data
      setInsightData(response.data)
      setInsight(true)
    } catch (error) {
      // Handle any errors that occur during the API request
      console.error('Error fetching property impression insights:', error);
      throw error;
    }
  };
  useEffect(() => {
    filterListings()
  }, [location])

  const navigate = useNavigate();
  function capitalizeFirstLetter(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
  return (
    <div style={{ marginBottom: "18vmax" }} className="">
      {
        isDeleted && <div className="col-11 ms-3 me-5 mb-4 p-3 d-flex justify-content-between  bg-[#EBFFFD] rounded-[12px]">
          <div className="text-[16px] font-medium  ms-2">
            Your listing has been successfully deleted.
          </div>
          <spna onClick={() => setIsDeleted(false)} className="">
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-8    me-1 "
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </spna>
        </div>
      }
      {
        isDeactive && <div className="col-11 ms-3 me-5 mb-4 p-3 d-flex justify-content-between  bg-[#EBFFFD] rounded-[12px]">
          <div className="text-[16px] font-medium  ms-2">
            Your listing has been deactivated.
          </div>
          <spna onClick={() => setIsDeactive(false)} className="">
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-8    me-1 "
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </spna>
        </div>
      }


      <div className="">
        <div class="p-0 container">
          <div class="d-flex flex-lg-row flex-column">
            <div class="col">
              <div className="myPropertyTitle  text-[26px] font-semibold flex ">My properties</div>
            </div>
            <div class="col">
              <div className="myPropertyOptions w-[308px] mt-[6px] h-[24px] text-center ">

                <p style={{ cursor: "pointer" }} className={`myPropertyTitle float-left text-[18px] font-medium pr-[24px] ${location.pathname.includes('for-sale') ? 'active-link' : ' '}`}

                  onClick={() => navigate('/dashboard/my-propertymain')}>
                  For sale
                </p>
                <p style={{ cursor: "pointer", }} onClick={() => navigate('/dashboard/for-rent')}
                  className={`myPropertyTitle float-left text-[18px] font-medium pr-[24px] ${location.pathname.includes('for-rent') ? 'active-link' : ' '}`}>
                  For rent

                </p>
                <p style={{ cursor: "pointer", }} onClick={() => navigate('/dashboard/for-investment')}
                  className={`myPropertyTitle text-[18px] font-medium ${location.pathname.includes('for-investment') ? 'active-link' : ' '}`}>
                  For investment</p>
              </div>
            </div>
            <div class="col"></div>
          </div>
        </div>
      </div>
      <div className="  ">
        <div className="  ">
          <div class="container myProperty-Top2">
            <div class="row">
              <div style={{ padding: '0' }} class="col">
                <p style={{ cursor: "pointer" }} onClick={() => navigate('/dashboard/my-propertymain')} className="mb-1 myPropertyTitle text-start text-[18px] font-semibold">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="myPropertyBackIcon w-5 h-5 float-left mt-[3px]"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.75 19.5L8.25 12l7.5-7.5"
                    />
                  </svg>
                  Active

                </p>
              </div>
              <div class="col myPropertyTotalValue">
                <p className="text-[18px] font-semibold text-[#098895] text-end  myPropertyTitle ">
                  {activeListings.length} Properties
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* ------------ */}
        {
          loading ? (
            <h6 className='notifyLoading'>Loading...</h6>
          ) : (
            <>
              {
                activeListings.length != 0 ? (
                  activeListings?.map((listing, idx) => {
                    let address = `${listing?.addressLine1}, ${listing?.town}`;
                    return (
                      <>
                        <div key={idx} className="cursor-pointer active-card h-[246px]  mb-4">
                          <div class="  ms-0 mt-2 ">
                            <div class="d-flex">
                              <div className="position-relative">
                                <img
                                  onClick={() => navigate(`/property-detail/${listing?._id}`)}
                                  src={listing?.listingMedia[0]}
                                  alt=""
                                  style={{
                                    height: '246px',
                                    borderTopLeftRadius: '12px',
                                    borderBottomLeftRadius: '12px',
                                    width: '525px', // Set the width of the image
                                     objectFit: 'cover'

                                  }}
                                  className="myPropertyImg col-lg-7 col-10 h-[246px]  float-left "
                                />
                                {/* Overlay */}
                                <div
                                  style={{
                                    position: 'absolute',
                                    bottom: '15px',
                                    right: '15px',
                                    backgroundColor: '#F9FAFB',
                                    color: '#0D0E0F',
                                    padding: '4px 8px',
                                    borderRadius: '12px',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    display: 'flex'
                                  }}
                                  className='myPropertyImg__total'
                                >
                                  <img
                                    style={{ width: '12px', height: '12px', marginTop: '5px', marginRight: '3px' }}
                                    src={PhotoImg}
                                    alt=''
                                  />

                                  {selectedImageIndex + 1}/{listing.listingMedia.length}
                                </div>
                              </div>

                              <div class="col">
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '500px', }} className="myPropertyContainer1 ml-[23px] my-[20px]  h-[44px]">
                                  <div onClick={() => navigate(`/property-detail/${listing?._id}`)}>{link == 'for-rent' && <h2 className=" myPropertyPrice text-[36px] font-semibold ">
                                    £{listing?.rentalPrice} </h2>}
                                    {link == 'for-sale' && <h2 className="myPropertyPrice text-[36px] font-semibold ">
                                      £{listing?.price}</h2>}
                                    {link == 'for-investment' && <h2 className="myPropertyPrice text-[36px] font-semibold ">
                                      £{listing?.minInvestment}</h2>}</div>
                                  <div className='myPropertyIcon' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div class="col ml-2">

                                      <EditIcon />
                                    </div>
                                    <div class="col ml-2">
                                      <div style={{ cursor: "pointer" }}
                                        onClick={() => getPropertyImpressionInsights(listing?._id)}>

                                        <InsideIcon />
                                      </div>

                                    </div>
                                    <div class="col ml-2">
                                      <div style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setDeactive(true)
                                          setPropertyId(listing?._id)

                                        }}>

                                        <BubleIcon />
                                      </div>

                                    </div>
                                    <div class="col ml-2">
                                      <div style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setDeleted(true)
                                          setPropertyId(listing?._id)
                                        }}>

                                        <DeleteIcon />
                                      </div>
                                    </div>
                                  </div>
                                  <div className='mt-2 myPropertyIcon2 '>
                                    <img onClick={() => setMore(true)} src={MoreOption} />

                                  </div>
                                </div>
                                <div style={{ cursor: 'pointer' }} onClick={() => navigate(`/property-detail/${listing?._id}`)} className="myPropertyContainer2 ml-[15px] h-[66px]">
                                  <div class="container myPropertySubContainer2__main ">
                                    <div class="">
                                      <div className=" myPropertySubContainer2 h-[36px] ">
                                        {link == 'for-investment' ? <h2 className="myPropertyType text-[36px] font-semibold text-[#135966] ">
                                          {capitalizeFirstLetter(listing?.investmentCategory)}</h2> :
                                          <h2 className="myPropertyType text-[26px] font-semibold text-[#135966]">

                                            {listing?.propertyType}
                                          </h2>

                                        }


                                      </div>
                                      <div className=" mt-[4px] h-[26px]">
                                        {
                                          listing?.addressLine1 ? (
                                            <div style={{ display: 'flex', height: '22px', justifyContent: 'space-between' }}>
                                              {
                                                listing?.address.length >= 25 ?
                                                  <p style={{ color: '#414141', display: 'flex', justifyContent: 'space-between' }} className="myPropertyPlace text-[18px]  font-normal "> {address} </p>
                                                  : <p style={{ color: '#414141', display: 'flex', justifyContent: 'space-between' }} className="myPropertyPlace text-[18px]  font-normal "> {address}   </p>

                                              }
                                              {listing.isBoosted && <span style={{ fontSize: '10px', color: 'black', display: 'flex', backgroundColor: "#E8F5E9", padding: '4px 8px', borderRadius: '12px' }}><img style={{ width: '10px', height: '10px', marginTop: '3px' }} src={BoostedImg} /> Boosted</span>}

                                            </div>

                                          ) : (
                                            <div style={{ display: 'flex', height: '22px', justifyContent: 'space-between' }}>
                                              {
                                                listing?.postalCode.length >= 25 ?
                                                  <p style={{ color: '#414141', display: 'flex', justifyContent: 'space-between' }} className="myPropertyPlace text-[18px]  font-normal ">{listing?.postalCode?.substring(0, 25)}...  </p>
                                                  : <p style={{ color: '#414141', display: 'flex', justifyContent: 'space-between' }} className="myPropertyPlace text-[18px]  font-normal ">{listing?.postalCode}  </p>

                                              }
                                              {listing.isBoosted && <span style={{ fontSize: '10px', color: 'black', display: 'flex', backgroundColor: "#E8F5E9", padding: '4px 8px', borderRadius: '12px' }}><img style={{ width: '10px', height: '10px', marginTop: '3px' }} src={BoostedImg} /> Boosted</span>}

                                            </div>
                                          )
                                        }
                                      </div>

                                    </div>
                                  </div>
                                </div>
                                <div className="myPropertyContainerTop3 ml-[10px] my-[20px] h-[56px]">
                                  <div style={{ padding: '10px', paddingLeft: '30px', textAlign: 'center', width: '500px', borderRadius: '12px' }} class="myPropertyContainer3  container ">
                                    <div class="myPropertySubContainer3__main d-flex align-items-center">
                                      <div class="myPropertySubContainer3 col ">
                                        <div style={{ width: 'max-content' }} className=" text-start d-flex flex-lg-row ">
                                          <img
                                            src={bed24}
                                            alt=""
                                            className="myPropertyContainer3__icons w-[24px] h-[24px] float-left mx-[4px] "
                                          />
                                          <span className="myPropertyContainer3__iconsText text-[14px] font-normal">
                                            {listing?.numberofBedrooms} <span className='myPropertyContainer3__iconsSpan'>beds</span>
                                          </span>
                                        </div>
                                      </div>
                                      <div class="col myPropertySubContainer3 ">
                                        <div style={{ marginLeft: '15px', width: 'max-content' }} className="text-start d-flex flex-lg-row ">
                                          <img
                                            src={bathub24}
                                            alt=""
                                            className="myPropertyContainer3__icons w-[24px] h-[24px] float-left mx-[4px] "
                                          />
                                          <span className="myPropertyContainer3__iconsText text-[14px] font-normal ">
                                            {listing?.numberofBathrooms} <span className='myPropertyContainer3__iconsSpan'>baths</span>
                                          </span>
                                        </div>
                                      </div>
                                      <div class="col myPropertySubContainer3 ">
                                        <div style={{ marginLeft: '25px', width: 'max-content' }} className="text-start d-flex flex-lg-row ">
                                          {
                                            link == 'for-investment' ? (
                                              <img
                                                src={InvestIcon}
                                                alt=""
                                                className="myPropertyContainer3__icons w-[24px] h-[24px] float-left ]"
                                                style={{ filter: 'brightness(0)', color: 'black' }}
                                              />
                                            ) : (
                                              <img
                                                src={Chairs24}
                                                alt=""
                                                className="myPropertyContainer3__icons w-[24px] h-[24px] float-left ]"
                                              />
                                            )
                                          }


                                          {
                                            link == 'for-investment' ? <span style={{ textTransform: 'capitalize' }} className="myPropertyContainer3__iconsText text-[14px] font-normal">
                                              {listing?.investmentType}
                                            </span> : <span className="myPropertyContainer3__iconsText text-[14px] font-normal">
                                              {listing?.furnishingOption}
                                            </span>
                                          }

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <More setPropertyId={setPropertyId} more={more} setMore={setMore} listingId={listing?._id} setDeactive={setDeactive} setDeleted={setDeleted} getPropertyImpressionInsights={getPropertyImpressionInsights} />

                      </>
                    )
                  })
                ) : (
                  <h6 style={{ marginLeft: '15px' }}>You don't have any active property {link}</h6>
                )
              }
            </>
          )
        }

      </div>
      <DeleteProperty handleDeleteListing={handleDeleteListing} deleted={deleted} setDeleted={setDeleted} />
      <DeactivateProperty handleUpdateStatus={handleUpdateStatus} deactive={deactive} setDeactive={setDeactive} />
      <Insight propertyId={propertyId} insightData={insightData} insight={insight} setInsight={setInsight} />
    </div>
  );
}
const EditIcon = () => {
  return (
    <svg width="45" height="45" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#F9FAFB" />
      <path d="M22.6015 29.0001H13.5232C12.1321 29.0001 11 27.8684 11 26.4769V17.3982C11 16.0071 12.1321 14.875 13.5232 14.875H17.6343C17.871 14.875 18.0623 15.0668 18.0623 15.303C18.0623 15.5393 17.871 15.731 17.6343 15.731H13.5232C12.6042 15.731 11.856 16.4788 11.856 17.3982V26.4769C11.856 27.3959 12.6038 28.144 13.5232 28.144H22.6019C23.5209 28.144 24.269 27.3963 24.269 26.4769V22.3658C24.269 22.1295 24.4604 21.9378 24.6971 21.9378C24.9338 21.9378 25.1251 22.1295 25.1251 22.3658V26.4769C25.1251 27.868 23.993 29.0001 22.6019 29.0001H22.6015Z" fill="#0D0E0F" />
      <path d="M22.0848 13.8867L14.8829 21.0886C14.4493 21.5217 14.2109 22.0974 14.2109 22.7108V24.5059C14.2109 25.2138 14.7871 25.7899 15.495 25.7899H17.2901C17.9026 25.7899 18.4783 25.5515 18.9123 25.118L26.1142 17.9161L22.0848 13.8867Z" fill="#0D0E0F" />
      <path d="M27.5139 14.0936L25.9054 12.4851C25.259 11.8383 24.1308 11.8383 23.4845 12.4851L22.6875 13.282L26.7169 17.3114L27.5139 16.5144C28.1811 15.8467 28.1811 14.7608 27.5139 14.0936Z" fill="#0D0E0F" />
    </svg>
  )

}
const InsideIcon = () => {
  return (
    <svg width="45" height="45" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#F9FAFB" />
      <path d="M28.3379 26.2442H26.7811V18.6747C26.7811 18.4242 26.5842 18.2274 26.3337 18.2274H24.8842C24.6337 18.2274 24.4368 18.4242 24.4368 18.6747V26.2442H22.9337V20.8937C22.9337 20.6432 22.7368 20.4463 22.4863 20.4463H21.0368C20.7863 20.4463 20.5895 20.6432 20.5895 20.8937V26.2442H19.0863V22.7905C19.0863 22.54 18.8895 22.3432 18.6389 22.3432H17.1895C16.9389 22.3432 16.7421 22.54 16.7421 22.7905V26.2442H13.3242V24.4547H14.5589C14.9168 24.4547 15.2211 24.1505 15.2211 23.7926C15.2211 23.4347 14.9168 23.1305 14.5589 23.1305H13.3242V21.3411H14.5589C14.9168 21.3411 15.2211 21.0368 15.2211 20.6789C15.2211 20.3211 14.9168 20.0168 14.5589 20.0168H13.3242V18.2274H14.5589C14.9168 18.2274 15.2211 17.9232 15.2211 17.5653C15.2211 17.2074 14.9168 16.9032 14.5589 16.9032H13.3242V15.1316H14.5589C14.9168 15.1316 15.2211 14.8274 15.2211 14.4695C15.2211 14.1116 14.9168 13.8074 14.5589 13.8074H13.3242V12.6621C13.3242 12.3042 13.02 12 12.6621 12C12.3042 12 12 12.3042 12 12.6621V26.9242C12 27.2821 12.3042 27.5863 12.6621 27.5863H28.3379C28.6958 27.5863 29 27.2821 29 26.9242C29 26.5484 28.6958 26.2442 28.3379 26.2442Z" fill="#0D0E0F" />
    </svg>

  )
}
const BubleIcon = () => {
  return (
    <svg width="45" height="45" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#F9FAFB" />
      <path d="M23.9191 14.0215C25.4339 14.8961 26.618 16.2461 27.2873 17.8622C27.9567 19.4782 28.0742 21.2699 27.6215 22.9595C27.1687 24.6491 26.1711 26.1421 24.7833 27.207C23.3956 28.2719 21.6954 28.849 19.946 28.849C18.1969 28.849 16.4965 28.2719 15.1089 27.207C13.7212 26.1422 12.7235 24.6491 12.2708 22.9595C11.818 21.2699 11.9354 19.4781 12.6048 17.8622C13.2742 16.2461 14.4582 14.8961 15.973 14.0215" stroke="#0D0E0F" stroke-width="2" stroke-linecap="round" />
      <path d="M20.0715 11C20.7711 11 21.3382 11.7738 21.3382 12.4011V18.3512C21.3382 19.1249 20.7711 19.7522 20.0715 19.7522C19.3718 19.7522 18.8047 18.9784 18.8047 18.3512V12.4011C18.8047 11.6273 19.3718 11 20.0715 11Z" fill="#0D0E0F" />
      <path d="M20.0715 11C20.7711 11 21.3382 11.7738 21.3382 12.4011V18.3512C21.3382 19.1249 20.7711 19.7522 20.0715 19.7522C19.3718 19.7522 18.8047 18.9784 18.8047 18.3512V12.4011C18.8047 11.6273 19.3718 11 20.0715 11Z" stroke="#0D0E0F" />
    </svg>

  )
}
const DeleteIcon = () => {
  return (
    <svg width="45" height="45" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#F9FAFB" />
      <path d="M26.6957 13.2174C27.0558 13.2174 27.3478 13.5094 27.3478 13.8696C27.3478 14.2298 27.0558 14.5217 26.6957 14.5217H26.6865C26.6144 14.5217 26.5539 14.5788 26.5512 14.6527L26.0816 27.3304C26.0473 28.256 25.2737 29 24.3478 29H16C15.0737 29 14.3004 28.2546 14.2662 27.3304L13.7967 14.6527C13.794 14.5804 13.735 14.5217 13.6613 14.5217H13.6522C13.292 14.5217 13 14.2298 13 13.8696C13 13.5094 13.292 13.2174 13.6522 13.2174H16.8318C16.9034 13.2174 16.9778 13.1604 16.997 13.0929L17.2097 12.3486C17.4279 11.585 18.2036 11 18.9978 11H21.35C22.1443 11 22.9199 11.5848 23.1381 12.3486L23.3508 13.0929C23.3704 13.1617 23.4438 13.2174 23.5161 13.2174H26.6957ZM19.6522 16.8696V25.7391C19.6522 26.0273 19.8858 26.2609 20.1739 26.2609C20.4621 26.2609 20.6957 26.0273 20.6957 25.7391V16.8696C20.6957 16.5814 20.4621 16.3478 20.1739 16.3478C19.8858 16.3478 19.6522 16.5814 19.6522 16.8696ZM16.7828 16.8849L17.0437 25.7545C17.0522 26.0425 17.2925 26.2691 17.5806 26.2606C17.8686 26.2522 18.0952 26.0118 18.0867 25.7238L17.8259 16.8542C17.8174 16.5662 17.577 16.3396 17.289 16.3481C17.001 16.3565 16.7744 16.5969 16.7828 16.8849ZM22.522 16.8542L22.2611 25.7238C22.2526 26.0118 22.4792 26.2522 22.7673 26.2606C23.0553 26.2691 23.2957 26.0425 23.3041 25.7545L23.565 16.8849C23.5735 16.5969 23.3468 16.3565 23.0588 16.3481C22.7708 16.3396 22.5304 16.5662 22.522 16.8542ZM18.4501 13.2174H21.8977C21.9715 13.2174 22.014 13.1621 21.9945 13.0938L21.884 12.7069C21.8257 12.5032 21.562 12.3043 21.35 12.3043H18.9978C18.7859 12.3043 18.522 12.5034 18.4639 12.7069L18.3533 13.0938C18.3336 13.1628 18.3772 13.2174 18.4501 13.2174Z" fill="#E53935" />
    </svg>

  )
}

export default ActiveProperty;
