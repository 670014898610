import { Fragment, useRef, useState, } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Budget from './Budget'
import './Boost.css'
import CancelIcon from '../../assets/cancel-icon.png'

export default function Boost({ boost, setBoost, propertyId }) {
    const [budget, setBudget] = useState(false)
    const cancelButtonRef = useRef(null)
    return (
        <>
            <Transition.Root show={boost} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setBoost}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 ">
                        <div className="">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="  ">

                                    <div className="Boost d-flex mt-5">
                                        <div className='col-4   mt-[5vmax]'>
                                            <div className='boostMainContainer form-control d-flex flex-column ' style={{ position: "relative" }}>
                                                <div className='text-end' style={{ position: "absolute", top: "-5px", right: "-5px", cursor: "pointer" }}>
                                                    <img src={CancelIcon} onClick={() => setBoost(false)} />
                                                </div>

                                                <h3 className='boostTitle'>Boost your property to reach a wider audience </h3>
                                                <p className='boostSubTitle mt-3'>✓ More messages count</p>
                                                <p className='boostSubTitle'>✓ Special ad category</p>
                                                <p className='boostSubTitle'>✓ Featured listing during property search </p>
                                                <button onClick={() => setBudget(true)} className='boostContinue__btn  mt-3 p-2'>Continue</button>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <Budget propertyId={propertyId} budget={budget} setBudget={setBudget} />

        </>

    )
}








