import { Fragment, useRef, useState, } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import ReviewAd from './ReviewAd'
import CancelIcon from '../../assets/cancel-icon.png'

export default function Budget({ budget, setBudget }) {

    const [reviewAd, setReviewAd] = useState(false)

    const cancelButtonRef = useRef(null)
    const [amount, setAmount] = useState('');
    const [duration, setDuration] = useState('');
    const handleAmountChange = (event) => {
        setAmount(event.target.value);
    };

    const handleDurationChange = (event) => {
        setDuration(event.target.value);
    };

    return (
        <>
            <Transition.Root show={budget} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setBudget}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 ">
                        <div className="">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="  ">

                                    <div className="Boost d-flex  mt-5">
                                        <div className='col-3 budgetContainer mt-[5vmax]'>
                                            <div className='boostMainContainer form-control d-flex flex-column p-4' style={{ position: "relative" }}>
                                                <div className='text-end' style={{ position: "absolute", top: "-5px", right: "-5px", cursor: "pointer" }}>
                                                <img src={CancelIcon} onClick={() => setBudget(false)} />
                                                </div>

                                                <h3 className='boostTitle'><i class="ri-arrow-left-s-line"></i>Budget & Duration </h3>
                                                <label className='mt-3 budgetLabel' htmlFor="">Daily budget</label>
                                                <input value={amount}
                                                required
                                                style={{marginBottom:'5px'}}
                                                    onChange={handleAmountChange} className='changeInput p-2 mt-1' type="text" placeholder='£50.00' />
                                                <label className='mt-1 budgetLabel' htmlFor="">Duration</label>
                                                <input value={duration}
                                                required
                                                    onChange={handleDurationChange} className='changeInput p-2 mt-1' type="text" placeholder='6 Days' />
                                                <button onClick={() => {
                                                    if(amount && duration){
                                                        setReviewAd(true)
                                                    }
                                                  }} className='boostContinue__btn mt-2 p-2'>Continue</button>
                                            </div>
                                        </div>
                                    </div>


                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <ReviewAd amount={amount} duration={duration} reviewAd={reviewAd} setReviewAd={setReviewAd} />
        </>

    )
}








