import React, { useState, useEffect, Suspense } from "react";
import MyAccount from "./my_accountPages/Myaccount";
import { Link, Route, Routes } from "react-router-dom";
import MyProfile from "./my_accountPages/MyProfile";
import MyPropertymain from "./myproperty_dashboard/MyPropertymain";
import MyProperty from "./myproperty_dashboard/PropertyStatus/ActiveProperty";
import PropertyReview from "./myproperty_dashboard/PropertyReview";
import Inactive from "./myproperty_dashboard/Inactive";
import Declined from "./myproperty_dashboard/Declined";
import Forrent from "./myproperty_dashboard/Forrent";
import Forinvestment from "./myproperty_dashboard/Forinvestment";
import Changepassword from "./myproperty_dashboard/Changepassword";
import ChangeEmail from "./myproperty_dashboard/ChangeEmail";
import ManagePreferance from "./myproperty_dashboard/ManagePreferance";
import Notification from "./myproperty_dashboard/Notification";
import DeleteAcccount from "./myproperty_dashboard/DeleteAccount";
import ScheduleTour from "./message/ScheduleTour";
import ReadMessage2 from "./message/ReadMessage2";
import "./DashboardPage.css"
import Rating from "../Popupfile/Rating";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom'
import { authActions } from '../../store/slice/auth-slice'
import { get_allUserListing, user_dataCounts, user_isUpdated } from "../../apiServices";
import SavedRent from "./myproperty_dashboard/SavedProperty/SavedRent/SavedRent";
import SavedInvestment from "./myproperty_dashboard/SavedProperty/SavedInvestment/SavedInvestment";
import SaveProperty from "./myproperty_dashboard/SavedProperty/SaveProperty";
import Signout from "../Popupfile/Signout";
import axios from "axios";
import { homeActions } from '../../store/slice/home-slice';

// const SaveProperty = React.lazy(() => import('./myproperty_dashboard/SavedProperty/SaveProperty'))


function DashboardPage() {
  const [dashboard, setDashboard] = useState(true)
  const [openRating, setOpenRating] = useState(false)

  const [activeLink, setActiveLink] = useState('for-sale');
  const [status, setStatus] = useState('');
  const [hasReview, setHasReview] = useState(null);
  const [listingCount, setListingCount] = useState()
  const [msgCount, setMsgCount] = useState()

  const [savedCount, setSavedCount] = useState()
  const [notificationCount, setNotificationCount] = useState()
  const [signOut,setSignOut]=useState(false)
  const [showTabs, setShowTabs] = useState(true);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [dashLink, setDashLink] = useState('my-propertymain');
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSignOut = () => {
    dispatch(authActions.logout())
    localStorage.removeItem('token')
    localStorage.removeItem('id')
    localStorage.removeItem('userDbId')
    localStorage.removeItem('userEmail')

    navigate('/')
  }
  const [userListings, setUserListings] = useState([])
  //  const [saleListings,setSaleListings]=useState([])
  //  const [rentListings,setRentListings]=useState([])
  //  const [investmentListings,setInvestmentListings]=useState([])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getAllSaleListing = async () => {
    const userId = localStorage.getItem('id')
    try {
      const response = await get_allUserListing(userId)
      setUserListings(response.listings)

    } catch (err) {
      // console.log(err)
    }
  }

  const getProfileUpdatedStatus = async () => {
    try {
      const response = await user_isUpdated()
      localStorage.setItem('isProfileUpdated', response.isUpdate)
      dispatch(authActions.updateIsProfile(response.isUpdate))

    } catch (err) {
      console.log(err)
    }
  }

  const getUserDataCounts = async () => {
    try {
      const response = await user_dataCounts()
      setListingCount(response.listingCounts)
      setMsgCount(response.messageCounts)
      setSavedCount(response.savedCounts)
      setNotificationCount(response.notificationCounts)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    const handleHasReview = () => {
      const userId = localStorage.getItem('id')
      const res = axios.get(`https://linkedbricks-backend.onrender.com/reviews/has-review/${userId}`, {
        headers: {
          'x-api-key': 'application/javascript',
          'Content-Type': 'application/json',
          "Authorization": localStorage.getItem("token")
        },
      })
        .then(response => {
          const data = response.data;
          let isReview = data.hasReview;
          setHasReview(data.hasReview);

          if (localStorage.getItem("rw") && isReview == false) {
            setOpenRating(true);

          }
          localStorage.removeItem("rw");
        })
        .catch(error => {
          console.error('Error:', error);
          // Handle error
        });

    }

    handleHasReview()

  }, [])
  useEffect(() => {
    getAllSaleListing()
    getProfileUpdatedStatus()
    getUserDataCounts()

  }, [])

  return (
    <div>
      <Rating openRating={openRating} setOpenRating={setOpenRating} />
      <div className="main  d-flex flex-lg-row flex-md-row flex-column mb-lg-0 mb-5" >

        <div onClick={() => setDashboard(prevState => !prevState)} className="toggledashbutton  mb-3 bg-dark me-3 ms-3 rounded ">
          <img className="ms-3 dashboard-icon me-3" src="dashboards.png" alt="" />
          <p style={{ fontSize: '18px', }} className=" bg-dark text-light mt-3">Dashboard</p>
        </div>
        {showTabs && (
          <div  className="dashboard-tabs responsive col-md-3 col-12 px-3 pt-3">

            <div className="d-lg-flex d-none align-items-center mb-3 bg-dark mt-3 ylwborder">
              {/* <img className="ms-3 me-3" src={dashboardpng} alt="" /> */}
              <div className="dashboard-icon ms-3 me-3">
                <Dashboardsvg />
              </div>
              <p style={{ fontSize: '18px', }} className="dashboard-Title bg-dark text-light mt-3 d-flex"> Dashboard</p>
            </div>
            <div className={`d-flex align-items-center dashboardTabBtn justify-content-start ${dashLink === 'my-propertymain' && 'active'}`}onClick={() =>{
              setDashLink('my-propertymain')
              if (isMobile) setShowTabs(false);
              dispatch(homeActions.closeNavbar())

            } }
              style={{ borderRadius: "12px", backgroundColor: (dashLink === "my-propertymain") ? "rgb(235, 255, 253)" : "" }}
            >
              {/* <img className="ms-3 me-3" src={propertypng} alt="" /> */}
              <div className="dashboard-icon ms-3 me-3">
                <Propertysvg />
              </div>
              <Link className="text col-8 mt-3" style={{ textDecoration: "none", color: "black", fontSize: '17px' }} to="/dashboard/my-propertymain">  <p >My properties</p></Link>
              <span>{listingCount}</span>
            </div>
            <div className={`d-flex align-items-center dashboardTabBtn ${dashLink === 'message' && 'active'}`} onClick={() => {setDashLink('message')
            if (isMobile) setShowTabs(false);
            dispatch(homeActions.closeNavbar())

            }}
              style={{ borderRadius: "12px", backgroundColor: (dashLink === "message") ? "rgb(235, 255, 253)" : "" }}>
              {/* <img className="ms-3 me-3" src={messagepng} alt="" /> */}
              <div className="dashboard-icon ms-3 me-3">
                <Messagesvg />
              </div>
              <Link className="col-8 mt-3" style={{ textDecoration: "none", color: "black", fontSize: '17px' }} to="/dashboard/my-account/message" >
                <p >Messages</p>
              </Link>
              <span >{msgCount}</span>
            </div>
            <div className={`d-flex align-items-center dashboardTabBtn ${dashLink === 'save-property' && 'active'}`} onClick={() => {setDashLink('save-property')
            if (isMobile) setShowTabs(false);
            dispatch(homeActions.closeNavbar())

            }}
              style={{ borderRadius: "12px", backgroundColor: (dashLink === "save-property") ? "rgb(235, 255, 253)" : "" }}>
              {/* <img className="me-3 ms-3" src={heartpng} alt="" /> */}
              <div className="dashboard-icon ms-3 me-3">
                <Heartsvg />
              </div>
              <Link className="col-8 mt-3" style={{ textDecoration: "none", color: "black", fontSize: '17px' }} to="/dashboard/save-property/sale" >
                {" "}
                <p >Saved properties</p>
              </Link>
              <span>{savedCount}</span>
            </div>
            <div className={`d-flex align-items-center dashboardTabBtn  ${dashLink === 'notification' && 'active'}`} onClick={() => {setDashLink('notification')    
            if (isMobile) setShowTabs(false);
            dispatch(homeActions.closeNavbar())
          }}
              style={{ borderRadius: "12px", backgroundColor: (dashLink === "notification") ? "rgb(235, 255, 253)" : "" }}>
              {/* <img className="me-3 ms-3" src={notificationpng} alt="" /> */}
              <div className="dashboard-icon ms-3 me-3">
                <Notificationsvg />
              </div>
              <Link className="col-8 mt-3" style={{ textDecoration: "none", color: "black", fontSize: '17px' }} to="/dashboard/notification" >
                {" "}
                <p >Notifications</p>
              </Link>

              <span>{notificationCount}</span>
            </div>
            {/* <div className="d-flex align-items-center">
            <img className="me-3 ms-3" src="profile.png" alt="" />
            <Link style={{textDecoration:"none", color:"black"}} to="/dashboard/my-account/account" className="no-underline">
              {" "}
              <p className="col-8 mt-3">Account</p>
            </Link>
            <span></span>
          </div> */}
            <div className={`d-flex align-items-center dashboardTabBtn  ${dashLink === 'my-profile' && 'active'}`} onClick={() => {setDashLink('my-profile')
            if (isMobile) setShowTabs(false);
            dispatch(homeActions.closeNavbar())

            }}
              style={{ borderRadius: "12px", backgroundColor: (dashLink === "my-profile") ? "rgb(235, 255, 253)" : "" }}>
              {/* <img className="me-3 ms-3" src={profilepng} alt="" /> */}
              <div className="dashboard-icon ms-3 me-3">
                <Profilesvg />
              </div>
              <Link style={{ textDecoration: "none", color: "black", fontSize: '17px' }} to="/dashboard/my-profile" className="no-underline">
                {" "}
                <p className="col-8 mt-3">Profile</p>
              </Link>
              <span></span>
            </div>
            <div className="mt-4 mb-3">
              <h6 className="ms-3 mt-2 text-[18px] text-secondary fw-dark bg-opacity-75">
                Settings
              </h6>
            </div>
            <div className={`d-flex align-items-center dashboardTabBtn ${dashLink === 'manage-prefernace' && 'active'}`} onClick={() => {setDashLink('manage-preferance')
            if (isMobile) setShowTabs(false);
            dispatch(homeActions.closeNavbar())

            }}
              style={{ borderRadius: "12px", backgroundColor: (dashLink === "manage-preferance") ? "rgb(235, 255, 253)" : "" }}>
              {/* <img className="me-3 ms-3" src={preferencespng} alt="" /> */}
              <div className="dashboard-icon ms-3 me-3">
                <Preferencesvg />
              </div>
              {/* <p className="col-8 mt-3">Manage preferences</p> */}
              <Link className="col-8 mt-3" style={{ textDecoration: "none", color: "black", fontSize: '17px' }} to="/dashboard/manage-preferance" >
                {" "}
                <p >Manage preferances</p>
              </Link>

              <span></span>
            </div>
            <div className={`d-flex align-items-center dashboardTabBtn  ${dashLink === 'change-password' && 'active'}`} onClick={() => {setDashLink('change-password')
            if (isMobile) setShowTabs(false);
            dispatch(homeActions.closeNavbar())

            }}
              style={{ borderRadius: "12px", backgroundColor: (dashLink === "change-password") ? "rgb(235, 255, 253)" : "" }}>
              {/* <img className="me-3 ms-3" src={editpng} alt="" /> */}
              <div className="dashboard-icon ms-3 me-3">
                <Editsvg />
              </div>
              <Link className="col-8 mt-3" style={{ textDecoration: "none", color: "black", fontSize: '17px' }} to="/dashboard/change-password" >
                {" "}
                <p >Change password</p>
              </Link>

              <span></span>
            </div>
            <div className={`d-flex align-items-center dashboardTabBtn  ${dashLink === 'change-email' && 'active'}`} onClick={() => {setDashLink('change-email')
            if (isMobile) setShowTabs(false);
            dispatch(homeActions.closeNavbar())

            }}
              style={{ borderRadius: "12px", backgroundColor: (dashLink === "change-email") ? "rgb(235, 255, 253)" : "" }}>
              {/* <img className="me-3 ms-3" src={editpng} alt="" /> */}
              <div className="dashboard-icon ms-3 me-3">
                <Editsvg />
              </div>
              <Link className="col-8 mt-3" style={{ textDecoration: "none", color: "black", fontSize: '17px' }} to="/dashboard/change-email" >
                {" "}
                <p >Change Email address</p>
              </Link>

              <span></span>
            </div>
            <div style={{ cursor: 'pointer' }} onClick={()=>{setSignOut(true); dispatch(homeActions.closeNavbar())}} className="d-flex dashboardTabBtn align-items-center" >
              {/* <img className="me-3 ms-3" src={signoutpng} alt="" /> */}
              <div className="dashboard-icon ms-3 me-3">
                <Signoutsvg />
              </div>
              {" "}
              <p style={{ fontSize: '17px', paddingTop: '15px' }}>Sign out</p>

              <span></span>
            </div>
            <div className="d-flex align-items-center dashboardTabBtn">
              {/* <img className="me-3 ms-3" src={rateuspng} alt="" /> */}
              <div className="dashboard-icon ms-3 me-3">
                <Rateussvg />
              </div>

              <Link className="col-8 mt-3" style={{ textDecoration: "none", color: "black", fontSize: '17px' }} onClick={() => {setOpenRating(true);dispatch(homeActions.closeNavbar())}}>
                {" "}
                <p >Rate us</p>
              </Link>

              <span></span>
            </div>
            <div className="d-flex dashboardTabBtn align-items-center mb-4 mt-4" onClick={() => {setDashLink('delete-account')
            if (isMobile) setShowTabs(false);
            dispatch(homeActions.closeNavbar())
            }}
              style={{ borderRadius: "12px", backgroundColor: (dashLink === "delete-account") ? "rgb(235, 255, 253)" : "" }}>
              {/* <img className="me-3 ms-3" src={deletepng} alt="" /> */}
              <div className="dashboard-icon ms-3 me-3">
                <Deletesvg />
              </div>
              <Link className="col-8 mt-3" style={{ textDecoration: "none", color: "red" }} to="/dashboard/delete-account" >
                {" "}
                <p >Delete account</p>
              </Link>
              <span></span>
            </div>
          </div>
         )}
        <Signout signOut={signOut} setSignOut={setSignOut} handleSignOut={handleSignOut} />
        <div className={`dashboard-main col-lg col-md-9 col-12 px-9 pt-9 ${showTabs && isMobile ? 'd-none' : 'd-block'}`} >
    
            <Routes>
              <Route path="/my-account/*" element={<MyAccount getUserDataCounts={getUserDataCounts} setShowTabs={setShowTabs} />} />
              <Route path="/my-profile" element={<MyProfile setShowTabs={setShowTabs} />} />
              
              <Route path="/my-propertymain" element={<MyPropertymain setShowTabs={setShowTabs} setStatus={setStatus} setActiveLink={setActiveLink} activeLink={activeLink} />} />
              <Route path="/for-rent" element={<Forrent setShowTabs={setShowTabs} setStatus={setStatus} setActiveLink={setActiveLink} activeLink={activeLink} />} />
              <Route path="/for-investment" element={<Forinvestment setShowTabs={setShowTabs} setStatus={setStatus} setActiveLink={setActiveLink} activeLink={activeLink} />} />
              <Route path="/change-password" element={<Changepassword setShowTabs={setShowTabs}  />} />
              <Route path="/change-email" element={<ChangeEmail setShowTabs={setShowTabs}  />} />
              <Route path="/manage-preferance" element={<ManagePreferance setShowTabs={setShowTabs}  />} />
              <Route path="/notification" element={<Notification getUserDataCounts={getUserDataCounts} setShowTabs={setShowTabs}  />} />
              <Route path="/delete-account" element={<DeleteAcccount setShowTabs={setShowTabs}  />} />

              <Route path="/save-property/sale" element={<SaveProperty getUserDataCounts={getUserDataCounts} setShowTabs={setShowTabs}  />} />

              <Route path="/save-property/rent" element={<SavedRent getUserDataCounts={getUserDataCounts} setShowTabs={setShowTabs}  />} />
              <Route path="/save-property/investment" element={<SavedInvestment getUserDataCounts={getUserDataCounts} setShowTabs={setShowTabs}  />} />

              {/* ----------- */}
              <Route path='/:link/active' element={<MyProperty setShowTabs={setShowTabs} status={status} userListings={userListings} activeLink={activeLink} />} />
              <Route path='/:link/reviewing' element={<PropertyReview setShowTabs={setShowTabs} status={status} userListings={userListings} activeLink={activeLink} />} />
              <Route path='/:link/inactive' element={<Inactive setShowTabs={setShowTabs} status={status} userListings={userListings} activeLink={activeLink} />} />
              <Route path='/:link/decline' element={<Declined setShowTabs={setShowTabs} status={status} userListings={userListings} activeLink={activeLink} />} />

              {/* ------------ */}
            <Route path="/my-account/message/:messageId/:senderId/:recipientId/:propertyId" element={<ReadMessage2 getUserDataCounts={getUserDataCounts} setShowTabs={setShowTabs} />} />
              <Route path="/my-account/message/tour/:id" element={<ScheduleTour setShowTabs={setShowTabs} />} />

            </Routes>

          <div>
            {/* image ke upr ka content yha likhe */}

            {/* image ke niche ka content yha likhe  */}
          </div>
          {/* <MyProfile /> */}
        </div>
      </div>

    </div>
  );
}


const Dashboardsvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.4179 3H4.2204C3.54639 3 3 3.60233 3 4.34535V7.92031C3 8.66332 3.54639 9.26565 4.2204 9.26565H12.4179C13.0919 9.26565 13.6383 8.66332 13.6383 7.92031V4.34535C13.6383 3.60233 13.0919 3 12.4179 3Z" fill="white" />
      <path d="M12.4179 10.6982H4.2204C3.54639 10.6982 3 11.3006 3 12.0436V19.6542C3 20.3972 3.54639 20.9996 4.2204 20.9996H12.4179C13.0919 20.9996 13.6383 20.3972 13.6383 19.6542V12.0436C13.6383 11.3006 13.0919 10.6982 12.4179 10.6982Z" fill="white" />
      <path d="M19.7795 3H16.4665C15.7925 3 15.2461 3.60233 15.2461 4.34535V19.6547C15.2461 20.3977 15.7925 21 16.4665 21H19.7795C20.4535 21 20.9999 20.3977 20.9999 19.6547V4.34535C20.9999 3.60233 20.4535 3 19.7795 3Z" fill="white" />
    </svg>
  )
}
const Propertysvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.51119 21.6506C2.8296 21.6506 2.8296 20.5972 3.51119 20.5972H4.50258V15.3924H10.5749V20.5972H11.1325V14.8967H8.46816V4.42513L12.8675 3L17.0809 4.42513V11.6747H12.8675V20.5972H13.4251V12.2324H19.4974V20.5972H20.4888C21.1704 20.5972 21.1704 21.6506 20.4888 21.6506H3.51119Z" fill="#0D0E0F" />
    </svg>
  )
}
const Rateussvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.3715 8.88107L14.488 9.11728L14.7486 9.15488L19.9851 9.91014L16.2008 13.6021L16.013 13.7853L16.0571 14.044L16.9469 19.2655L12.2631 16.7976L12.03 16.6748L11.7969 16.7976L7.11482 19.2646L8.01276 14.0448L8.05739 13.7853L7.86877 13.6017L4.0763 9.90994L9.31138 9.15488L9.57204 9.11728L9.68847 8.88107L12.03 4.13087L14.3715 8.88107Z" stroke="#0D0E0F" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0321 3V17.2453L6.44994 20.18L7.51603 13.9642L3 9.56218L9.241 8.65531L12.0321 3Z" fill="#0D0E0F" />
    </svg>
  )
}
const Deletesvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C17.5 2 22 6.5 22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2ZM6.08333 10.1667H17.9167C18.1667 10.1667 18.4167 10.3333 18.4167 10.6667V13.4167C18.4167 13.6667 18.1667 13.8333 17.9167 13.8333H6.08333C5.83333 13.8333 5.58333 13.6667 5.58333 13.4167V10.6667C5.58333 10.3333 5.83333 10.1667 6.08333 10.1667Z" fill="#F44336" />
    </svg>
  )
}
const Notificationsvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9744 4.21977V3.85C10.9744 3.28333 11.3162 3 12 3C12.6838 3 13.0256 3.28333 13.0256 3.85V4.21977C15.8898 4.49323 17.9519 6.64278 17.9487 8.43931C17.9487 9.23508 17.9583 9.85355 17.9487 10.6493C17.919 11.8198 18.2343 13.405 18.9744 14.3893C19.2179 14.7064 19.5598 14.9898 20 15.2393V16.9393H4V15.2393C4.44024 14.9898 4.78212 14.7064 5.02564 14.3893C5.76575 13.405 6.08097 11.8198 6.05128 10.6493C6.0417 9.85355 6.05128 9.23508 6.05128 8.43931C6.04811 6.64278 8.11018 4.49323 10.9744 4.21977ZM12 20C10.3007 20 8.92308 18.8583 8.92308 17.45H15.0769C15.0769 18.8583 13.6993 20 12 20Z" fill="#0D0E0F" />
    </svg>
  )
}
const Signoutsvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.84766 3H18.1523C18.9076 3 19.6319 3.30002 20.1659 3.83406C20.7 4.3681 21 5.09241 21 5.84766V18.1523C21 18.9076 20.7 19.6319 20.1659 20.1659C19.6319 20.7 18.9076 21 18.1523 21H5.84766C5.09241 21 4.3681 20.7 3.83406 20.1659C3.30002 19.6319 3 18.9076 3 18.1523V5.84766C3 5.09241 3.30002 4.3681 3.83406 3.83406C4.3681 3.30002 5.09241 3 5.84766 3ZM13.2582 15.6731C13.8278 15.6714 14.3984 15.6875 14.9668 15.6594C15.3722 15.6403 15.7551 15.4679 16.0381 15.177C16.3211 14.8861 16.4829 14.4986 16.4909 14.0929C16.5084 13.395 16.4944 12.7031 16.4944 12H16.4979C16.4979 11.332 16.4902 10.67 16.4996 10.0063C16.513 9.09855 15.8159 8.47172 15.1806 8.36098C15.0439 8.33786 14.9055 8.3261 14.7668 8.32582C14.217 8.32195 13.6671 8.32336 13.1173 8.32582C12.8786 8.32582 12.8343 8.37047 12.8237 8.61129C12.8054 9.03316 12.8311 9.05918 13.2456 9.05918C13.7508 9.05918 14.256 9.05918 14.7615 9.05918C15.381 9.05918 15.7652 9.43992 15.7663 10.0583C15.7686 11.3511 15.7686 12.6441 15.7663 13.9371C15.7663 14.5573 15.3831 14.9373 14.7647 14.9391C14.2148 14.9391 13.665 14.9391 13.1152 14.9391C12.8634 14.9391 12.8339 14.9689 12.8237 15.2182C12.8058 15.6471 12.8307 15.6745 13.2582 15.6731ZM7.64414 12.2921C8.65805 13.3091 9.67324 14.325 10.6897 15.3398C10.8114 15.4615 10.9527 15.5258 11.119 15.4587C11.2853 15.3915 11.3623 15.2477 11.3616 15.0667C11.3595 14.5963 11.3672 14.1259 11.3672 13.6555V13.4766H11.5518C12.3045 13.4766 13.0571 13.4723 13.8098 13.4716C14.1688 13.4716 14.2932 13.3451 14.2936 12.9886C14.2936 12.33 14.2936 11.6711 14.2936 11.0118C14.2936 10.6567 14.1681 10.5284 13.8088 10.528C13.0561 10.528 12.3034 10.5234 11.5507 10.5234H11.3626C11.3595 10.4531 11.3552 10.406 11.3552 10.3533C11.3552 9.87797 11.3577 9.40406 11.3552 8.9291C11.3531 8.64785 11.1745 8.47594 10.9313 8.5248C10.8452 8.54511 10.7664 8.58893 10.7038 8.65137C9.68168 9.66715 8.66215 10.6855 7.6452 11.7064C7.45184 11.8984 7.45219 12.0998 7.64414 12.2921Z" fill="#0D0E0F" />
    </svg>
  )
}
const Editsvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.6015 21.0001H5.52318C4.13211 21.0001 3 19.8684 3 18.4769V9.39818C3 8.00711 4.13211 6.875 5.52318 6.875H9.63431C9.871 6.875 10.0623 7.06675 10.0623 7.30302C10.0623 7.53929 9.871 7.73104 9.63431 7.73104H5.52318C4.60422 7.73104 3.85604 8.47879 3.85604 9.39818V18.4769C3.85604 19.3959 4.60379 20.144 5.52318 20.144H14.6019C15.5209 20.144 16.269 19.3963 16.269 18.4769V14.3658C16.269 14.1295 16.4604 13.9378 16.6971 13.9378C16.9338 13.9378 17.1251 14.1295 17.1251 14.3658V18.4769C17.1251 19.868 15.993 21.0001 14.6019 21.0001H14.6015Z" fill="#0D0E0F" />
      <path d="M14.0838 5.88672L6.88195 13.0886C6.44837 13.5217 6.20996 14.0974 6.20996 14.7108V16.5059C6.20996 17.2138 6.78608 17.7899 7.49402 17.7899H9.28913C9.90163 17.7899 10.4773 17.5515 10.9113 17.118L18.1132 9.9161L14.0838 5.88672Z" fill="#0D0E0F" />
      <path d="M19.5158 6.09355L17.9073 4.48505C17.261 3.83832 16.1327 3.83832 15.4864 4.48505L14.6895 5.28203L18.7188 9.3114L19.5158 8.51443C20.1831 7.84672 20.1831 6.76083 19.5158 6.09355Z" fill="#0D0E0F" />
    </svg>
  )
}
const Preferencesvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.6155 3H4.38459C3.61991 3 3 3.61991 3 4.38459V19.6154C3 20.3801 3.61991 21 4.38459 21H19.6154C20.3801 21 21 20.3801 21 19.6154V4.38459C21 3.61991 20.3802 3 19.6155 3ZM8.53849 15.9808H7.67307V18.2308C7.67307 18.5175 7.44049 18.7501 7.15384 18.7501C6.86719 18.7501 6.6346 18.5175 6.6346 18.2308V15.9808H5.76918C5.48253 15.9808 5.24994 15.7482 5.24994 15.4616C5.24994 15.1749 5.48253 14.9423 5.76918 14.9423H6.6346V5.76925C6.6346 5.48259 6.86719 5.25001 7.15384 5.25001C7.44049 5.25001 7.67307 5.48259 7.67307 5.76925V14.9423H8.53849C8.82514 14.9423 9.05773 15.1749 9.05773 15.4616C9.05773 15.7482 8.82514 15.9808 8.53849 15.9808ZM13.3847 9.05773H12.5193V18.2308C12.5193 18.5175 12.2867 18.7501 12 18.7501C11.7134 18.7501 11.4808 18.5175 11.4808 18.2308V9.05773H10.6154C10.3287 9.05773 10.0961 8.82514 10.0961 8.53849C10.0961 8.25184 10.3287 8.01925 10.6154 8.01925H11.4808V5.76925C11.4808 5.48259 11.7134 5.25001 12 5.25001C12.2867 5.25001 12.5193 5.48259 12.5193 5.76925V8.01925H13.3847C13.6713 8.01925 13.9039 8.25184 13.9039 8.53849C13.9039 8.82514 13.6713 9.05773 13.3847 9.05773ZM18.2309 15.9808H17.3655V18.2308C17.3655 18.5175 17.1329 18.7501 16.8462 18.7501C16.5596 18.7501 16.327 18.5175 16.327 18.2308V15.9808H15.4616C15.1749 15.9808 14.9423 15.7482 14.9423 15.4616C14.9423 15.1749 15.1749 14.9423 15.4616 14.9423H16.327V5.76925C16.327 5.48259 16.5596 5.25001 16.8462 5.25001C17.1329 5.25001 17.3655 5.48259 17.3655 5.76925V14.9423H18.2309C18.5175 14.9423 18.7501 15.1749 18.7501 15.4616C18.7501 15.7482 18.5175 15.9808 18.2309 15.9808Z" fill="#0D0E0F" />
    </svg>
  )
}
const Profilesvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2ZM12.0081 7C13.6651 7 15.0081 8.343 15.0081 10C15.0081 11.657 13.6651 13 12.0081 13C10.3511 13 9.00806 11.657 9.00806 10C9.00806 8.343 10.3511 7 12.0081 7ZM12 20.5C9.79 20.5 7.77001 19.65 6.26001 18.26C6.70001 16.88 7.84004 15.5699 10.29 15.5699H13.71C16.15 15.5699 17.29 16.89 17.74 18.26C16.23 19.65 14.21 20.5 12 20.5Z" fill="#0D0E0F" />
    </svg>
  )
}
const Messagesvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 5H6C4 5 3 6 3 8V17C3 19 4 20 6 20H18C20 20 21 19 21 17V8C21 6 20 5 18 5ZM17.9409 9.606L13.0291 13.178C12.7211 13.402 12.36 13.514 12 13.514C11.64 13.514 11.2779 13.402 10.9709 13.179L6.05908 9.606C5.72408 9.363 5.65004 8.893 5.89404 8.558C6.13704 8.224 6.60389 8.14801 6.94189 8.39301L11.854 11.965C11.942 12.028 12.059 12.029 12.147 11.965L17.0591 8.39301C17.3961 8.14801 17.8639 8.224 18.1069 8.558C18.3509 8.894 18.2759 9.363 17.9409 9.606Z" fill="#0D0E0F" />
    </svg>
  )
}
const Heartsvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.8301 11.4651C19.6571 17.5151 12.0001 21.0001 12.0001 21.0001C12.0001 21.0001 4.34304 17.5151 3.16905 11.4651C2.44904 7.75308 4.0221 4.01906 8.0211 4.00006C11.0001 3.98606 12.0001 6.98803 12.0001 6.98803C12.0001 6.98803 13.0001 3.98506 15.9781 4.00006C19.9861 4.01906 21.5501 7.75408 20.8301 11.4651Z" fill="#0D0E0F" />
    </svg>
  )
}

export default DashboardPage;
