import React, { useState,useEffect } from 'react'
import "./Changepassword.css"
import PasswordSuccess from '../../Popupfile/PasswordSuccess';
import { useNavigate } from 'react-router-dom';
import { change_password } from '../../../apiServices';

import { authActions } from '../../../store/slice/auth-slice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

const Changepassword = ({setShowTabs}) => {
  const [changepass, setChangepass] = useState(false)
  const [show, setShow] = useState(false)
  const [enterNew, setEnterNew] = useState(false)
  const [confirmPass, setConfirmPass] = useState(false)
  const [currPass, setCurrPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confNewPass, setConfNewPass] = useState("");
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isProfileUpdated = useSelector(state => state.auth.isProfileUpdated)
  const allFieldsCompleted = currPass && newPass && confNewPass;
  const [isUpdated,setIsUpdated]=useState(false)


  const handleChangePass = async () => {

    if (newPass !== confNewPass) {
      alert("New password and confirm new password do not match!");
      return;
    }
    try {
      const data = {
        userId: localStorage.getItem("id"),
        currentPassword: currPass,
        newPassword: newPass,
        confirmPassword: confNewPass
      }
      const response = await change_password(data);
      setIsUpdated(true);
      setConfNewPass('')
      setNewPass('')
      setCurrPass('')

      // dispatch(authActions.logout())
      // navigate('/')
    } catch (error) {
      alert(error.message);
    }

  }
  useEffect(() => {
    if (isUpdated) {
      const timer = setTimeout(() => {
        setIsUpdated(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isUpdated]);
  return (
    <div className='Changepassword'>
      <div className="passwordchange">
        {/* ------------------------------- */}
        {
          isUpdated && <div className=" mb-3 col-11 ms-lg-5 ms-3 me-5 p-3 d-flex justify-content-between  bg-[#EBFFFD] rounded-[12px]">
            <div className="text-[18px] font-medium  ms-2">
              Your password has been successfully updated.
            </div>
            <spna onClick={() => setIsUpdated(false)} className="cursor-pointer">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-8    me-1 "
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </spna>
          </div>
        }
        {
          isProfileUpdated == false && <div className="TopOne mb-8">
            <div className="topOneLeft">
              <h5 className="topOneTitle">Welcome to your account</h5>
              <p className="topOnePara">Complete your profile to seamlessly connect with property owners and do so much more.</p>
              <button className="topOneButton" onClick={() => navigate('/dashboard/my-profile')}><span>Complete profile</span> <i style={{ fontSize: '24px' }} className="ri-arrow-right-s-line"></i></button>
            </div>
            <div className="topOneRight">
              <img className="topOneImg" src="https://myproperties.vercel.app/BuildingIllustration.png" alt="" />
            </div>
          </div>
        }

        {/* ------------------------------- */}
        <h3  className='flex passwordTitle'><i onClick={() => setShowTabs(true)} className="showTabBack ri-arrow-left-s-line"></i>Change Password</h3>
        <div className="changeContainer h-[340px]">

          <div className="ipt">
            <div className='old-password'>
              <input className='changeInput ' type={show ? ("text") : ("password")} placeholder='Enter current password'
                value={currPass}
                onChange={(e) => setCurrPass(e.target.value)} />
              {show ? (<i onClick={() => setShow(false)} class="passView ri-eye-fill"></i>) : (<i onClick={() => setShow(true)} class="passView ri-eye-off-fill"></i>)}
            </div>
            <div className='old-password'>
              <input className='changeInput' type={enterNew ? ("text") : ("password")} placeholder=' New password'
                value={newPass}
                onChange={(e) => setNewPass(e.target.value)} />
              {enterNew ? (<i onClick={() => setEnterNew(false)} class="passView ri-eye-fill"></i>) : (<i onClick={() => setEnterNew(true)} class="passView ri-eye-off-fill"></i>)}
            </div>
            <div className='old-password'>
              <input className='changeInput ' type={confirmPass ? ("text") : ("password")} placeholder='Confirm new password'
                value={confNewPass}
                onChange={(e) => setConfNewPass(e.target.value)} />
              {confirmPass ? (<i onClick={() => setConfirmPass(false)} class="passView ri-eye-fill"></i>) : (<i onClick={() => setConfirmPass(true)} class="passView ri-eye-off-fill"></i>)}
            </div>

          </div>
          <div className="mt-[24px]  h-[50px]  ">
            <button onClick={handleChangePass} disabled={!allFieldsCompleted}

              style={{
                fontWeight: '500', color: '#0D0E0F', fontSize: '18px', opacity: allFieldsCompleted ? '1' : '0.5'

              }} type="submit" className="border w-[178px] float-right  text-[18px] rounded-[12px] px-[16px] py-[12px] font-medium bg-[#E8AE00] "
            >
              Save changes
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6 float-right "
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </button>
          </div>
        </div>

      </div>
      <PasswordSuccess changepass={changepass} setChangepass={setChangepass} />
    </div>
  )
}

export default Changepassword