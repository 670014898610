import React, { useState, useEffect } from "react";
import CtaCards from "../../Card/CtaCards";
import imgMessage from "../../../assets/Images/messageImg/ImgMessage1.png";
import {
  arrowRight,
} from "../../svg/svg";
import ImageCards from "../../Card/ImageCards";
import { useNavigate } from "react-router-dom";
import { get_allMessage, get_allScheduleTour } from "../../../apiServices";
import { socket } from "../../../App";

function MyMessage({setShowTabs,getUserDataCounts}) {
  const [message, setMessage] = useState(true)

  const navigate = useNavigate()
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);

  const [tour, setTour] = useState([]);
  const [showDelete, setShowDelete] = useState(null);
  const [unReadMsg, setUnReadMsg] = useState(0)


  useEffect(() => {
    const fetchMessages = async () => {
      try {
        setLoading(true);

        const response = await get_allMessage();
        setMessages(response);
        const isRecipient = localStorage.getItem('id') == message?.recipient?.userId

        const unreadMessages = response.filter(message => {
          const userId = localStorage.getItem('id');
          const recipientUserId = message?.recipient?.userId;
          const isMessageRead = message.read;


          return userId == recipientUserId && !isMessageRead;
        });
        const unreadMessagesCount = unreadMessages.length;
        setUnReadMsg(unreadMessagesCount)
        setLoading(false);

      } catch (error) {
        console.error('Error fetching messages:', error);
        setLoading(false);
        // Handle error, e.g., display an error message to the user
      }
    };
    const fetchTours = async () => {
      try {
        setLoading2(true);

        const response = await get_allScheduleTour();
        setTour(response);
        setLoading2(false);
      } catch (error) {
        console.error('Error fetching messages:', error);
        setLoading2(false);
        // Handle error, e.g., display an error message to the user
      }
    };

    fetchMessages();
    fetchTours()
  }, []);

  const handleDate = (timestamp) => {
    const date = new Date(timestamp);

    // Define options for formatting the date and time
    const options = { month: 'short', day: '2-digit', year: 'numeric' };

    // Format the date and time using toLocaleString()
    const formattedDateTime = date.toLocaleString('en-US', options);

    return formattedDateTime
  }

  const handleJoinRoom = (messageId, senderId, recipientId, propertyId) => {
    const data = {
      roomId: messageId
    }
    socket.emit('join-room', data)

    navigate(`/dashboard/my-account/message/${messageId}/${senderId}/${recipientId}/${propertyId}`)

  }

  return (
    <div style={{ marginBottom: "18vmax" }} className="">
      <div className="">
        <div style={{ padding: '0' }} class="container">
          <div class="d-flex flex-lg-row flex-column">
            <div class="col">
              <div className=" flex messageTitle  font-semibold "><i onClick={() => setShowTabs(true)} className="showTabBack ri-arrow-left-s-line"></i>Messages</div>
            </div>
            <div class="col">

              <div className="flex mt-[6px] text-center ">
                <p
                  onClick={() => setMessage(true)}
                  style={{
                    cursor: "pointer",
                    color: message ? "#098895" : "initial",
                    textDecoration: message ? "underline" : "none",
                  }}
                  className="float-left messageSubTitle  font-normal pr-[12px] "
                >
                  Direct messages
                </p>
                <p
                  onClick={() => setMessage(false)}
                  style={{
                    cursor: "pointer",
                    color: !message ? "#098895" : "initial",
                    textDecoration: !message ? "underline" : "none",
                  }}
                  className="messageSubTitle font-normal pr-[24px]"
                >
                  Scheduled tours
                </p>
              </div>


            </div>
            <div class="col">
              <p className="messageTotal text-[18px] font-semibold text-[#098895] text-end me-5">
                {
                  message ? `${unReadMsg} unread messages` : ` ${tour.length} scheduled tours`
                }

              </p>
            </div>
          </div>
        </div>
      </div>
      {
        message ? (
          <>
            {
              loading ? (
                <h6 className='notifyLoading'>Loading...</h6>
              ) : (
                <>
                  {
                    messages.length != 0 ? (
                      messages?.map((message, index) => {
                        const loggedInUserId = localStorage.getItem('id');
                        const nameToShow = loggedInUserId == message?.sender?.userId ? message?.recipient?.fullName : message?.sender?.fullName;
                        let messageId = message?._id;
                        let senderId = message?.sender?._id;
                        let recipientId = message?.recipient?._id;
                        let propertyId = message?.propertyId?._id;
                        const isRecipient = loggedInUserId == message?.recipient?.userId
                        const isSender = loggedInUserId == message?.sender?.userId
                        const userName = message.place === 'undefined, undefined' ? message.propertyId.postalCode : message.place;
                        return (
                          <ImageCards
                            imgs={message?.propertyId?.listingMedia?.[0]}
                            userName={userName}
                            userDate={handleDate(message?.content?.[message?.content?.length - 1]?.timestamp)}
                            text={nameToShow}
                            links={`/dashboard/my-account/message/${message?._id}/${message?.sender?._id}/${message?.recipient?._id}/${propertyId}`}
                            userMesg={message?.content?.[message?.content?.length - 1]?.message}
                            arrowIcon={arrowRight}
                            senderId={message?.sender?._id}
                            recipientId={message?.recipient?._id}
                            isRead={message?.read}
                            messageId={messageId}
                            isRecipient={isRecipient}
                            isSender={isSender}
                          />
                        )
                      })
                    ) : (
                      <h6>You don't have any message yet!</h6>
                    )
                  }
                </>
              )
            }
          </>

        ) : (
          <>

            {
              loading2 ? (
                <h6 className='notifyLoading'>Loading...</h6>
              ) : (
                <>
                  {
                    tour.length != 0 ? (
                      tour?.map((tour, index) => {
                        const isOrganizer = tour?.organizerId?.userId == localStorage.getItem('id');
                        const userName = isOrganizer ? `You (${tour.user.fullname})` : tour?.user?.fullname;
                        const isAccepted = tour?.status == 'accepted'
                        const isDeclined = tour?.status == 'declined'
                        return (
                          <>
                            <div key={index} onMouseEnter={() => setShowDelete(index)} onMouseLeave={() => setShowDelete(null)} onClick={() => {
                              if (!isOrganizer) {
                                navigate(`/dashboard/my-account/message/tour/${tour?._id}`)
                              }
                            }
                            }
                              className="  mb-[16px]  cursor-pointer">
                              <ImageCards
                                index={index}
                                imgs={tour?.property?.listingMedia[0]}
                                userName={isOrganizer ? (
                                  tour?.property?.addressLine1 ?
                                    `${tour?.property?.addressLine1}, ${tour?.property?.town}` :
                                    tour?.property?.postalCode) : (
                                  (tour?.status === 'accepted' ?
                                    tour?.property?.addressLine1 ?
                                      `${tour?.property?.addressLine1}, ${tour?.property?.town}` :
                                      tour?.property?.postalCode :
                                    (tour?.property?.addressLine1 ?
                                      `${tour?.property?.addressLine1}, ${tour?.property?.town} was scheduled for a tour` :
                                      `${tour?.property?.postalCode} was scheduled for a tour`
                                    )))}
                                userDate={`${handleDate(tour?.currentDate)}`}
                                text={tour?.user?.fullName}
                                // onClick={() => {navigate('/dashboard/tour')}}
                                // links={`/dashboard/my-account/message/tour/${tour?._id}`}
                                // userMesg= {isOrganizer ? `You organized tour with ${tour?.user?.fullName}` : `${tour?.organizer} organized tour with you`}
                                isOrganizer={isOrganizer}
                                arrowIcon={arrowRight}
                                isAccepted={isAccepted}
                                scheduleDate={tour?.scheduledDate}
                                tourId={tour?._id}
                                showDelete={showDelete}
                                setShowDelete={setShowDelete}
                                isRead={tour?.read}
                                isDeclined={isDeclined}
                                getUserDataCounts={getUserDataCounts}
                              />
                            </div>

                          </>
                        )
                      })
                    ) : (
                      <h6>You don't have any scheduled tour yet!</h6>
                    )
                  }
                </>
              )
            }
          </>
        )
      }

    </div>

  );
}

export default MyMessage;
