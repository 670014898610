
import React from "react";

const Input = ({ extrastyle, placeholder, type, ...otherprops }) => {
  return (
    <div className="mr-[10px] ">
      <input
        placeholder={placeholder}
        type={type}
        style={{border: '1px solid #FFEBB0'}}
        className={`w-[100%] h-[50px] rounded-xl py-3 px-4  text-[18px] font-normal placeholder:text-[#A6A7A8]  focus outline-none  sm:text-sm sm:leading-6 border-[#FFEBB0] ${extrastyle}`}
        {...otherprops}
        required
      />
    </div>
  );
};

export default Input;
