import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import andrew from "../../assets/Images/Blog/avatar.png"
import NotifySendmsg from './NotifySendmsg'
import { send_message, send_notifications } from '../../apiServices'
import { useSelector } from 'react-redux'
import { Avatar } from '@mui/material'
import './/NotifyUser.css'
import CancelIcon from '../../assets/cancel-icon.png'
import { useNavigate } from 'react-router-dom'
export default function NotifyUser({ notify, setNotify, user, propertyId, address, town, postcode }) {

    const cancelButtonRef = useRef(null)
    const userToken = useSelector(state => state.auth.userToken)

    const [sendmsg, setSendmsg] = useState(false)
    const [content, setContent] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [name, setName] = useState('')
    const navigate=useNavigate()
    
    const sendMessage = async () => {
        const data = {
            propertyId,
            recipientId: user.userId,
            senderId: localStorage.getItem('userDbId'),
            content,
            phoneNumber,
            name,
            userId: localStorage.getItem('id'),
            place: `${address}, ${town}`

        }
        try {
            const response = await send_message(data);
            sendNotification()
            setContent('')
            setName('')
            setPhoneNumber('')

            setSendmsg(true)

        } catch (error) {
            console.error('Error sending message:', error);
            // Handle error, e.g., show an error message to the user
        }
    };


    const sendNotification = async () => {
        try {
            const data = {
                propertyId,
                user: user?.id,
                message: `${name} send you a message`,
                senderId: localStorage.getItem('id')
            }
            const res = await send_notifications(data)
        } catch (err) {
            console.log(err)
        }

    }

    return (
        <>
            <Transition.Root show={notify} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setNotify}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 ">
                        <div className="">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="  ">

                                    <div className="NotifyUser d-flex justify-content-center mt-5">
                                        <div className='col-lg-3 col-11   mt-5'>
                                            <div className='form-control messagePopup  d-flex flex-column p-4' style={{ position: "relative" }}>
                                                <div className='text-end' style={{ position: "absolute", top: "-6px", right: "-4px", cursor: "pointer" }}>
                                                    <img src={CancelIcon} onClick={() => setNotify(false)} />
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    {
                                                        user?.photoUrl ? <Avatar sx={{width:"50px",height:"50px"}} src={user.photoUrl} alt="" /> : <img style={{width:"80px",height:'80px'}} src={andrew} alt="" />
                                                    }

                                                    <h4 className='ms-3 messageUsername'>{user?.fullName}</h4>
                                                </div>
                                                <input value={name} onChange={(e) => setName(e.target.value)} className='changeInput text-[#A6A7A8]  p-2 mt-4' type="text" placeholder='Full name' />
                                                <input value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} className='changeInput text-[#A6A7A8]  p-2' type="text" placeholder='Phone number' />
                                                <textarea value={content} onChange={(e) => setContent(e.target.value)} placeholder='Write a message'  className='messageInputContainer text-[#A6A7A8]  p-2 ' name="" id="" cols="30" rows="4"></textarea>
                                                <button onClick={sendMessage} className='bg-warning messageSendBtn  mt-3 p-2'><i class="ri-send-plane-fill me-2"></i>Send Message</button>
                                                <p className='messageTerm'>By submitting this form, you accept our <span style={{cursor:'pointer'}} onClick={()=>{setNotify(false);
                                                    navigate('/termsofuse')}} className='messageTermSpan'>Terms of Use</span> and <span style={{cursor:'pointer'}} onClick={()=>{setNotify(false);
                                                        navigate('/privacypolicy')}} className='messageTermSpan'>Privacy Policy</span></p>
                                            </div>
                                        </div>
                                    </div>


                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <NotifySendmsg sendmsg={sendmsg} setSendmsg={setSendmsg} />
        </>

    )
}
