import React, { Suspense } from "react";
import Navbar from "./component/Homepage/Navbar";
import Footer from "./component/Homepage/Footer";
import { Route, Routes } from "react-router-dom";

import CircularProgress from '@mui/material/CircularProgress';

import "./App.css";
import { io } from "socket.io-client"
import isAuth from "./component/Auth/isAuth";
// import VerifyEmail from "./pages/VerifyEmail/VerifyEmail";

const Homepage = React.lazy(() => import('./pages/Home/Homepage'))
const Blog = React.lazy(() => import('./pages/Blog/Blog'))
const BlogDetailPage = React.lazy(() => import('./component/Blog/BlogDetailPage'))
const ForRent = React.lazy(() => import('./pages/Listing/ForRent'))
const ForSale = React.lazy(() => import('./pages/Listing/ForSale'))
const ForInvestment = React.lazy(() => import('./pages/Listing/ForInvestment'))
const SearchForSale = React.lazy(() => import('./component/forsale/SearchForSale'))
const PropertyDetailPage = React.lazy(() => import("./component/forrent/PropertyDetailPage"))
const About = React.lazy(() => import("./pages/About/About"))
const AddListingPage = React.lazy(() => import("./pages/AddListing/AddListingPage"))
const ContactUs = React.lazy(() => import("./pages/Contact/ContactUs"))
const ContactUsValidation = React.lazy(() => import("./pages/Contact/ContactUsValidation"))
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy/PrivacyPolicy"))
const TermsOfUse = React.lazy(() => import("./pages/Term/TermsOfUse"))
const DashboardPage = React.lazy(() => import("./component/dashboard/DashboardPage"))
const ListingSteps = React.lazy(() => import("./pages/ListingForms/ListingSteps/ListingSteps"))
const ReviewPage = React.lazy(() => import("./component/review/ReviewPage"))
const SalePropertyDetailPage = React.lazy(() => import("./component/forsale/SalePropertyDetailPage"))
const InvestmentPropertyDetailPage = React.lazy(() => import("./component/forinvestment/InvestmentPropertyDetailPage"))
const Deletion = React.lazy(() => import("./component/dashboard/myproperty_dashboard/Deletion"))
const ReviewMsgShow = React.lazy(() => import("./component/Popupfile/ReviewMsgShow"))
const EmailPasswordChange = React.lazy(() => import("./component/Homepage/EmailPasswordChange"))
const VerifyEmail = React.lazy(() => import('./pages/VerifyEmail/VerifyEmail'))
const PasswordChangeSuccess = React.lazy(() => import("./component/Homepage/PassworChangeSuccess"))

const AuthenticatedDashboard = isAuth(DashboardPage)
const AuthenticatedAddListing = isAuth(ListingSteps)
const AuthenticatedDeleted = isAuth(Deletion)


export const socket = io('https://linkedbricks-backend.onrender.com', {
  withCredentials: true,
  transports: ['websocket', 'polling', 'flashsocket']
})

const App = () => {
  return (
    <Suspense fallback={<div style={{ display: 'flex', justifyContent: 'center', alignItem: 'center', paddingTop: '300px' }}>
      <CircularProgress color='inherit' size={100} />
    </div>}>
      <div className="app">
        <Navbar />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-detailPage/:id" element={<BlogDetailPage />} />
          <Route path="/forrent" element={<ForRent />} />
          <Route path="/forsale" element={<ForSale />} />
          <Route path="/forinvestment" element={<ForInvestment />} />
          <Route path="/search/:type" element={<SearchForSale />} />
          <Route path="/property-detail/:id" element={<PropertyDetailPage />} />
          <Route path="/salepropertyDetailPage/:id" element={<SalePropertyDetailPage />} />
          <Route path="/investmentpropertyDetailPage/:id" element={<InvestmentPropertyDetailPage />} />
          <Route path="/reviewMsgShow/:id" element={<ReviewMsgShow />} />
          <Route path="/about" element={<About />} />
          <Route path="/addlisting" element={<AddListingPage />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/contactusvalidation" element={<ContactUsValidation />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/termsofuse" element={<TermsOfUse />} />
          <Route path="/add-listing-steps" element={<AuthenticatedAddListing />} />
          <Route path="/reviewpage" element={<ReviewPage />} />
          <Route path="/deletion" element={<Deletion />} />
          <Route path="/passwordchangemail/:token" element={<EmailPasswordChange />} />
          <Route path="/passwordchangesuccess" element={<PasswordChangeSuccess />} />

          <Route path="/verified" element={<VerifyEmail />} />
          <Route path="/dashboard/*" element={<AuthenticatedDashboard />} />
        </Routes>
        <Footer />
      </div>
    </Suspense>
  );
};

export default App;
