import React from "react";
import { Route, Routes } from "react-router-dom";
// import Accounts from "./Accounts";
import MyMessage from "../message/MyMessage";
import "../../forrent/Forrent.css"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function MyAccount({setShowTabs,getUserDataCounts}) {
  const isProfileUpdated = useSelector(state => state.auth.isProfileUpdated)
  const navigate = useNavigate()
  return (
    <div>
      {
        isProfileUpdated == false && <div className="TopOne mb-8">
          <div className="topOneLeft">
            <h5 className="topOneTitle">Welcome to your account</h5>
            <p className="topOnePara">Complete your profile to seamlessly connect with property owners and do so much more.</p>
            <button className="topOneButton" onClick={() => navigate('/dashboard/my-profile')}><span>Complete profile</span> <i style={{ fontSize: '24px' }} className="ri-arrow-right-s-line"></i></button>
          </div>
          <div className="topOneRight">
            <img className="topOneImg" src="https://myproperties.vercel.app/BuildingIllustration.png" alt="" />
          </div>
        </div>
      }

      {/* ===============================My ProperTies============================ */}

      <Routes>
        {/* <Route path="/account" element={<Accounts />} /> */}
        <Route path="/message" element={<MyMessage getUserDataCounts={getUserDataCounts} setShowTabs={setShowTabs}/>} />
      </Routes>
    </div>
  );
}

export default MyAccount;
