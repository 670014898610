import React from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import notificationImg from "../../assets/Delete/Vector.png"
import './ImageCard.css'
import ActiveIcon from '../../assets/Active.png'
function ImageCards({
  imgs,
  userName,
  userDate,
  text,
  userMesg,
  links,
  arrowIcon,
  isOrganizer,
  isAccepted,
  scheduleDate,
  tourId,
  index,
  showDelete,
  setShowDelete,
  isRead,
  isDeclined,
  messageId,
  isRecipient,
  isSender,
  getUserDataCounts
}) {
  const navigate = useNavigate()

  const deleteTour = async () => {
    try {
      const currentDate = new Date();

      const parsedScheduledDate = new Date(scheduleDate);


      // Check if the current date is greater than or equal to the scheduled date
      if (currentDate < parsedScheduledDate) {
        alert('Cannot delete tour before scheduled date!')
        throw new Error('Cannot delete tour before scheduled date');
      }

      const response = await axios.delete(`https://linkedbricks-backend.onrender.com/tour/${tourId}`);
      getUserDataCounts()

    } catch (error) {
      console.error('Error deleting tour:', error.message);
      // Handle errors, display error messages, etc.
    }
  };
  const handleUpdateTourReadStatus = async () => {
    if (!isOrganizer) {
      try {

        const res = await axios.put(`https://linkedbricks-backend.onrender.com/tour/read/${tourId}`);

      } catch (error) {
        console.error('Error updating read status:', error);
      }
    }

  };


  const handleMarkAsRead = async () => {
    try {
      const res = await axios.put(`https://linkedbricks-backend.onrender.com/message/mark-as-read/${messageId}`);
    } catch (error) {
      console.error('Error marking message as read:', error);
    }
  };

  return (
    <div
      style={{
        height: '95px', boxShadow: '0px 3px 8px -1px #3232470D',
        borderRadius: '12px'
      }}
      className={`messageCardContainer col-12 gap-10 mt-2 cursor-pointer flex relative items-center  hover:bg-[#fcfcfc] `}
      onClick={isRecipient ? handleMarkAsRead : handleUpdateTourReadStatus}


    >
      <div onClick={() => navigate(links)}  className="messageCardContainer1 col-11 d-flex flex-lg-row  justify-content-start align-items-center">
        {
          !isSender && !isRead && (
            <div style={{ width: '10px', height: '10px', marginTop: "2px", marginLeft: "2px" }} className="unread-icon absolute top-1 left-1"></div>
          )
        }

       <div className="messageCardSubContainer1 col-lg-1 ">
       <img src={imgs} className=" d-lg-flex rounded-xl py-[4px] pr-[3px] " />

       </div>
        <div className="messageCardSubContainer2 col-lg-10 ms-2 col-11  gap-1 my-[11px] flex items-center ">
          <div className="col-12 mt-[16px]   text-[18px] font-medium">
            <p className="text-[#098895] messageCardPlace text-[15px] font-semibold  mb-[4px]">
              {userName} {isAccepted ? <span style={{ color: 'black' }}> tour</span> : ''}
              <span className="text-[#0D0E0F] messageCardDate px-2 text-[13px] font-normal ">
                • {userDate}
              </span>
            </p>
            <p className="text-[#E8AE00] messageCardName text-[13px] font-semibold flex   mb-[4px]">
              {text}  {isAccepted ? <span style={{ fontSize:'12px',color: 'black',display:'flex',backgroundColor:"#E8F5E9" , padding:'4px 8px',borderRadius:'12px',marginLeft:"5px"}}><img style={{width:'10px',height:'10px',marginTop:'3px'}} src={ActiveIcon} /> Accepted</span> : ''}
            </p>
            <p className=" messageCardText text-[13px] font-normal mt-0  ">{userMesg?.substring(0, 100)}{userMesg && '...' }</p>
          </div>
        </div>
      </div>
      <div className="messageCardContainer2 col-1 h-[24px] flex items-center text-[18px] font-medium text-end float-right">
        <div className=" w-[2px] text-[18px] font-medium   ">
        {
          !isOrganizer ? (
            (isAccepted || isDeclined) ? (
              // If not organizer and tour status is accepted or declined, show delete button
              <>
                {showDelete === index ? (
                  <div className="deleteCard-icon" onClick={deleteTour}>
                    <img src={notificationImg} />
                  </div>
                ) : null}
                <div className="deleteCard-icon-responsive" onClick={deleteTour}>
                  <img src={notificationImg} />
                </div>
              </>
            ) : (
              // If not organizer and tour status is not accepted or declined, show link
              <i style={{ fontSize: '2vmax' }} className="ri-arrow-right-s-line"></i>
            )
          ) : null
        }
  
        </div>
      </div>
    </div>
  );
}

export default ImageCards;
