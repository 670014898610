import { createSlice } from '@reduxjs/toolkit'

export const initialHomeState = {
    isNavbarOpen: false

}

const HomeSlice = createSlice({
    name: 'home',
    initialState: initialHomeState,
    reducers: {
        openNavbar(state, action) {
            state.isNavbarOpen = true
        },
        closeNavbar(state, action) {
            state.isNavbarOpen = false
        }
    }
})


export const homeActions = HomeSlice.actions
export default HomeSlice.reducer