import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import "./Insight.css"
import Circlegraph from "../../../src/component/Popupfile/Circlegraph"
import sale4 from "../../assets/Images/property/sale4.png"
import Boost from './Boost'


export default function Insight({ insight, setInsight, insightData, propertyId }) {

    const [boost, setBoost] = useState(false)

    const cancelButtonRef = useRef(null)
    const { impressionCount, savedCount, messageCount, isBoosted } = insightData;

    // Constructing the data array for the graph
    const data = [impressionCount, savedCount, messageCount];

    return (
        <>
            <Transition.Root show={insight} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setInsight}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500  transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 ">
                        <div className="">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="  ">

                                    <div className="Insightsmain">
                                        <div className="insightmid">
                                            <div className='insightpop align-items-center  d-flex justify-content-center'>
                                                <div className='insightContainerMain col-10 bg-light  '>
                                                    <h4 className='insightTitle fs-2 mt-3 ms-4 fw-bold'>Property Insights</h4>
                                                    {/* <div className='col-12 d-flex flex-wrap '>
//                 <h6 className='col-4'><img className='ellipseinsight' src="Ellipse 213.png" alt="" />Impression Count</h6>
//                 <h6  className='col-4'><img className='ellipseinsight' src="Ellipse 213.png" alt="" />Phone view Count</h6>
//                 <h6  className='col-4'><img className='ellipseinsight' src="Ellipse 213.png" alt="" />Message Count</h6>
//                 <h6  className='col-4'><img className='ellipseinsight' src="yellow.png" alt="" />Ad View Count</h6>
//                 <h6  className='col-4'><img className='ellipseinsight' src="blue.png" alt="" />Saved Count</h6>
//             </div> */}
                                                    <div className='d-flex justify-content-center'>
                                                        <div className='mt-2 col-8   ' style={{ position: "relative" }}>
                                                            <Circlegraph data={data} />
                                                            <img className='   col-3 d-lg-flex d-none' src={sale4} style={{ position: "absolute", top: "9vmax", left: "7.1vmax" }} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='col-12 mt-4'>
                                                        <h4 className='insightTitle ms-4 fs-2 fw-bold'>Overview</h4>
                                                        <div className='mt-4 ms-4 col-12 d-flex justify-content-between'>
                                                            <h6 className='insightSubTitle col-6'><img className='ellipseinsight' src="Ellipse 213.png" alt="" />Impression Count</h6>
                                                            <h6 className='insightSubTitle col-3'>{impressionCount}</h6>
                                                        </div>

                                                        <div className='col-12 ms-4 d-flex justify-content-between'>
                                                            <h6 className='insightSubTitle col-6 '><img className='ellipseinsight' src="Ellipse 213.png" alt="" />Message Count</h6>
                                                            <h6 className='insightSubTitle col-3'>{messageCount}</h6>
                                                        </div>
                                                        <div className='col-12 ms-4 d-flex justify-content-between'>
                                                            <h6 className='insightSubTitle col-6 '><img className='ellipseinsight' src="Ellipse 213.png" alt="" />Saved Count</h6>
                                                            <h6 className='insightSubTitle col-3'>{savedCount}</h6>
                                                        </div>
                                                    </div>
                                                    <div className=' mb-3 mt-4 d-flex justify-content-evenly'>
                                                        <button onClick={() => setInsight(false)} className='insightButton1 col-5 p-2 '>Close</button>
                                                        {
                                                            isBoosted ? <button className='insightButton2 col-5 p-2 '>Boosted</button> :
                                                            <button onClick={() => setBoost(true)} className='insightButton2 col-5 p-2 '>Boost</button>


                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <Boost propertyId={propertyId} boost={boost} setBoost={setBoost} />
        </>

    )
}









