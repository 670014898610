import { Fragment, useRef, useState, } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import CancelIcon from '../../assets/cancel-icon.png'
import PaymentPopup from '../../component/Popupfile/PaymentPopup'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function ReviewAd({ reviewAd, setReviewAd, amount, duration }) {

    const [opencard, setOpencard] = useState(false)
    const [isBoost, setIsBoost] = useState(false)

    const cancelButtonRef = useRef(null)
    let totalCost = Number(amount) * Number(duration)

    return (
        <>
            <Transition.Root show={reviewAd} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setReviewAd}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 ">
                        <div className="">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="  ">

                                    <div className="Boost d-flex mt-5">
                                        <div className='col-3 budgetContainer  mt-[5vmax]'>
                                            <div className='boostMainContainer form-control d-flex flex-column p-4' style={{ position: "relative" }}>
                                                <div className='text-end' style={{ position: "absolute", top: "-5px", right: "-5px", cursor: "pointer" }}>
                                                <img src={CancelIcon} onClick={() => setReviewAd(false)} />
                                                </div>

                                                <h3 className='boostTitle'><i class="ri-arrow-left-s-line"></i>Budget & Duration </h3>
                                                <div className='d-flex mt-3 fw-semibold justify-content-between'>
                                                    <p>Daily Budget</p>
                                                    <p>£{amount}.00 </p>
                                                </div>
                                                <div className='d-flex  fw-semibold justify-content-between'>
                                                    <p>Duration</p>
                                                    <p>{duration} days</p>
                                                </div>
                                                <div className='d-flex  fw-semibold justify-content-between'>
                                                    <p>Total cost</p>
                                                    <p>£{totalCost}.00</p>
                                                </div>
                                                <button onClick={() => {
                                                    setOpencard(true)
                                                    setIsBoost(true)
                                                }} className='boostContinue__btn mt-3 p-2'>Boost post</button>
                                                <p className='messageTerm mt-2'>By submitting this form, you accept our <span className='messageTermSpan'>Terms of Use</span> and <span className='messageTermSpan'>Privacy Policy</span></p>
                                            </div>
                                        </div>
                                    </div>


                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <Elements stripe={stripePromise} >

                <PaymentPopup isBoost={isBoost} totalCost={totalCost} opencard={opencard} setOpencard={setOpencard} />
            </Elements>
        </>

    )
}








