import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

//high order component for authenticated routes
const isAuth = (WrappedComponent) => {
    return (props) => {
        const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

        if (isAuthenticated) {
            return <WrappedComponent {...props} />;
        } else {
            return <Navigate to="/" />;
        }
    };
};

export default isAuth;