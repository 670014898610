import React, { useState, useEffect } from 'react'
import "./Notification.css"
import { useNavigate } from 'react-router-dom';
import notificationImg from "../../../assets/Delete/Vector.png"
import NotifyUser from '../../Popupfile/NotifyUser';
import axios from 'axios'

import { useSelector } from 'react-redux';
const Notification = ({ setShowTabs,getUserDataCounts }) => {

  const navigate = useNavigate()
  const [notify, setNotify] = useState(false)
  const [notifications, setNotifications] = useState([]);
  const [showDelete, setShowDelete] = useState(null);
  const [user, setUser] = useState({})
  const [property, setProperty] = useState({})
  const isProfileUpdated = useSelector(state => state.auth.isProfileUpdated)
  const [isLoading, setIsLoading] = useState(true)

  // Function to fetch notifications
  const handleDate = (timestamp) => {
    const date = new Date(timestamp);

    // Define options for formatting the date and time
    const options = { month: 'short', day: '2-digit', year: 'numeric' };

    // Format the date and time using toLocaleString()
    const formattedDateTime = date.toLocaleString('en-US', options);

    return formattedDateTime
  }
  const fetchNotifications = async () => {
    const id = localStorage.getItem('id')
    try {
      setIsLoading(true)
      const response = await axios.get(`https://linkedbricks-backend.onrender.com/notifications/user/${id}`);
      setNotifications(response.data);
      setIsLoading(false)

    } catch (error) {
      console.log(error)
      setIsLoading(false)

    }
  };
  const updateNotificationReadStatus = async (id) => {
    try {
      await axios.put(`https://linkedbricks-backend.onrender.com/notifications/isRead-notification/${id}`);
      fetchNotifications()
    } catch (error) {
      console.error('Error updating notification read status:', error);
    }
  }

  const deleteNotificationById = async (notificationId) => {
    try {
      await axios.delete(`https://linkedbricks-backend.onrender.com/notifications/delete-notification/${notificationId}`);
      fetchNotifications()
      getUserDataCounts()

      // Optionally, you can handle success or perform additional actions here
    } catch (error) {
      console.error('Error deleting notification:', error);
      // Handle error appropriately
    }
  };

  useEffect(() => {
    // Call the fetchNotifications function
    fetchNotifications();
  }, []); //

  const handleNotify = (data, value, id) => {
    updateNotificationReadStatus(id)
    setUser(data)
    setProperty(value)
    setNotify(true)
  }
  const unreadCount = notifications.filter(notification => !notification.read).length;
  
  return (
    <div className='Notification'>
      <div className="notify">
        {/* ------------------------------- */}
        {
          isProfileUpdated == false && <div className="TopOne mb-8">
            <div className="topOneLeft">
              <h5 className="topOneTitle">Welcome to your account</h5>
              <p className="topOnePara">Complete your profile to seamlessly connect with property owners and do so much more.</p>
              <button className="topOneButton" onClick={() => navigate('/dashboard/my-profile')}><span>Complete profile</span> <i style={{ fontSize: '24px' }} className="ri-arrow-right-s-line"></i></button>
            </div>
            <div className="topOneRight">
              <img className="topOneImg" src="https://myproperties.vercel.app/BuildingIllustration.png" alt="" />
            </div>
          </div>
        }

        {/* ------------------------------- */}

        <div className="NotificationBox">
          <h5 className='flex justify-between'>
            <div className='flex profileTitle'><i onClick={() => setShowTabs(true)} className="showTabBack ri-arrow-left-s-line"></i>Notifications</div>
            <span>{unreadCount} new notifications</span></h5>
          {
            isLoading ? (
              <h6 className='notifyLoading'>Loading...</h6>
            ) : (
              <>
                {
                  notifications.length != 0 ? (
                    notifications?.map((data, index) => {
                      return (
                        <div key={index} className="NoticeBox" onMouseEnter={() => setShowDelete(index)} onMouseLeave={() => setShowDelete(null)}>
                          <div className='flex'>
                            {!data.read && <div className="unread-icon"></div>}

                            <img className='notifyProperty' src={data?.propertyId?.listingMedia[0]} alt="" />
                            <div style={{ paddingTop: '9px' }} className="txt">
                              <h6 style={{ cursor: "pointer" }} onClick={() => handleNotify(data?.senderId, data?.propertyId, data?._id)}>{data?.message}  <span className="text-[#0D0E0F] notifyDate px-2 text-[14px] font-normal ">
                                • {handleDate(data?.createdAt)}
                              </span></h6>
                              {
                                data?.propertyId?.town ? <p style={{ cursor: 'pointer' }} onClick={() => navigate(`/property-detail/${data?.propertyId?._id}`)}>{data?.propertyId?.town}, {data?.propertyId?.country} </p> : (
                                  <p style={{ cursor: 'pointer' }} onClick={() => navigate(`/property-detail/${data?.propertyId?._id}`)}>{data?.propertyId?.postalCode} </p>
                                )
                              }

                            </div>
                          </div>

                          {showDelete === index && (
                            <div className="mt-4 delete-icon" onClick={() => deleteNotificationById(data._id)}>
                              <img
                                className='notifyDelete'
                                src={notificationImg} />
                            </div>
                          )}
                          <div className="mt-4 delete-icon-responsive" onClick={() => deleteNotificationById(data._id)}>
                            <img
                              className='notifyDelete'
                              src={notificationImg} />
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <h6>You don't have any notifications</h6>
                  )
                }
              </>
            )
          }


        </div>
      </div>
      <NotifyUser propertyId={property?._id} address={property?.addressLine1} town={property?.town} postcode={property?.postalCode} user={user} notify={notify} setNotify={setNotify} />
    </div>
  )
}

export default Notification