import { Fragment, useRef, } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import './Deactivateproperty.css'

export default function DeactivateProperty({ deactive, setDeactive, handleUpdateStatus, isInActive }) {

    const cancelButtonRef = useRef(null)

    return (
        <>
            <Transition.Root show={deactive} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setDeactive}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 ">
                        <div className="">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="  ">
                                    <div className='DeleteProperty'>
                                        <div className="Deleted">
                                            {/* <i onClick={() => setOpencard(false)} className="signclose ri-close-circle-line"></i> */}
                                            <div className="Deletepop">
                                                <h5>Activate Property</h5>
                                                <p>Are you sure you want to  activate this property? The listing will be moved to your active dashboard section.</p>
                                                <div className="desidebtn">
                                                    <button onClick={() => setDeactive(false)} style={{ border: "1.5px solid red" }} className='bg-white'>Cancel</button>
                                                    <button style={{border:'1.5px solid rgb(232, 174, 0)',backgroundColor:'white'}} onClick={handleUpdateStatus}>Activate</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            {/* <Signup signUpOpen={signUpOpen} setSignUpOpen={setSignUpOpen} /> */}
        </>

    )
}
