import { Fragment, useRef, } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useSelector } from 'react-redux'
import './AccountDelete.css'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { delete_user } from '../../apiServices'
import { authActions } from '../../store/slice/auth-slice'

export default function AccountDelete({ accountDlt, setAccountDlt,deleteData,userPass }) {
    const email = useSelector(state => state.auth.userEmail)
    const navigate = useNavigate()
    const cancelButtonRef = useRef(null)
    const dispatch = useDispatch()

    const handleDeleteAccount = async () => {
        try {
            const data = {
                userId: localStorage.getItem("id"),
                deleteData,
                password:userPass
            }
            const response = await delete_user(data);
            localStorage.removeItem('userEmail')
            localStorage.removeItem('userDbId')
            localStorage.removeItem('token')
            localStorage.removeItem('id')
            localStorage.removeItem('name')


            navigate('/deletion')

        } catch (err) {
            console.log(err)
            alert(err.message)
        }
    }

    return (
        <>
            <Transition.Root show={accountDlt} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setAccountDlt}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 ">
                        <div className="">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="  ">
                                    <div className='DeleteProperty'>
                                        <div className="Deleted">
                                            {/* <i onClick={() => setOpencard(false)} className="signclose ri-close-circle-line"></i> */}
                                            <div className="Deletepop">
                                                <h5>Delete Account</h5>
                                                <p>You’re about to permanently delete your Linked Bricks account for: {email}.</p>
                                                <p>Once deleted, you won’t be able to reopen it and all of the data will be lost.</p>
                                                <div className="desidebtn">
                                                    <button onClick={() => setAccountDlt(false)} style={{ border: "2px solid red" }} className='bg-light'>Cancel</button>
                                                    <button onClick={handleDeleteAccount}>Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            {/* <Signup signUpOpen={signUpOpen} setSignUpOpen={setSignUpOpen} /> */}
        </>

    )
}
