import { Fragment, useRef, } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import './DeleteProperty.css'
import { useNavigate } from 'react-router-dom'

export default function NotifySendmsg({ sendmsg, setSendmsg }) {
    const navigate = useNavigate()

    const cancelButtonRef = useRef(null)

    return (
        <>
            <Transition.Root show={sendmsg} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setSendmsg}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 ">
                        <div className="">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="  ">
                                    <div className='DeleteProperty'>
                                        <div className="Deleted">
                                            {/* <i onClick={() => setOpencard(false)} className="signclose ri-close-circle-line"></i> */}
                                            <div className="Deletepop">
                                                <h5 className='fs-lg-0 fs-4'>Message successfully sent!</h5>
                                                <p>Usman Andrew will receive your message. If no response within 24 hours, feel free to call them.</p>
                                                <div className="desidebtn">
                                                    <button onClick={() => navigate('/dashboard/my-account/message')} style={{ border: "2px solid red",padding:'0' }} className='flex items-center justify-center border border-warning bg-transparent'>View Message</button>
                                                    <button className='bg-warning' onClick={() => setSendmsg(false)}>Done</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            {/* <Signup signUpOpen={signUpOpen} setSignUpOpen={setSignUpOpen} /> */}
        </>

    )
}
