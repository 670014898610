import React, { useState,useEffect } from 'react'
import "./ChangeEmail.css"
import { useNavigate } from 'react-router-dom';
import EmailSuccess from '../../Popupfile/EmailSuccess';
import { change_email } from '../../../apiServices';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '../../../store/slice/auth-slice';
const ChangeEmail = ({ setShowTabs }) => {
  const [emailsuccess, setEmailsuccess] = useState(false)
  const [confirmPass, setConfirmPass] = useState(false)
  const [newEmail, setNewEmail] = useState("");
  const [pass, setPass] = useState("");
  const isProfileUpdated = useSelector(state => state.auth.isProfileUpdated)
  const userEmail = useSelector(state => state.auth.userEmail)
  const [email, setEmail] = useState(userEmail);
  const [isUpdated, setIsUpdated] = useState(false)
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const handleChangeEmail = async () => {
    try {
      const data = {
        userId: localStorage.getItem("id"),
        currentEmail: email,
        newEmail: newEmail,
        password: pass
      }
      const response = await change_email(data);
      setIsUpdated(true)
      setEmail(newEmail)
      const userData = {
        email: newEmail
      }
      localStorage.setItem('userEmail', newEmail)
      dispatch(authActions.addEmail(userData))
      setNewEmail('')
      setPass('')
    } catch (error) {
      alert(error.message);
    }
  }
  const allFieldsCompleted = newEmail && pass;
  useEffect(() => {
    if (isUpdated) {
      const timer = setTimeout(() => {
        setIsUpdated(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isUpdated]);

  return (
    <div className='ChnageEmail'>
      <div className="chagemail">
        {/* ------------------------------- */}
        {
          isUpdated && <div className=" mb-3 col-11 ms-lg-5 ms-3 me-5 p-3 d-flex justify-content-between  bg-[#EBFFFD] rounded-[12px]">
            <div className="text-[18px] font-medium  ms-2">
              Your email has been successfully updated.
            </div>
            <spna onClick={() => setIsUpdated(false)} className="cursor-pointer">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-8    me-1 "
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </spna>
          </div>
        }
        {
          isProfileUpdated == false && <div className="TopOne mb-8">
            <div className="topOneLeft">
              <h5 className="topOneTitle">Welcome to your account</h5>
              <p className="topOnePara">Complete your profile to seamlessly connect with property owners and do so much more.</p>
              <button className="topOneButton" onClick={() => navigate('/dashboard/my-profile')}><span>Complete profile</span> <i style={{ fontSize: '24px' }} className="ri-arrow-right-s-line"></i></button>
            </div>
            <div className="topOneRight">
              <img className="topOneImg" src="https://myproperties.vercel.app/BuildingIllustration.png" alt="" />
            </div>
          </div>
        }

        {/* ------------------------------- */}
        <h3 className='passwordTitle flex'><i onClick={() => setShowTabs(true)} className="showTabBack ri-arrow-left-s-line"></i>Change Email Address</h3>
        <div style={{ height: '384px' }} className=" changeContainer">

          <p style={{ fontSize: '18px', fontWeight: '600' }}>Current email address</p>
          <div className="inptss">
            <p style={{ border: 'none' }} className='changeInput bg-[#F9FAFB]'  >{email}</p>
            <input className='changeInput' type="text" placeholder=' New email address'
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)} />

            <div className='old-password'>
              <input className='changeInput ' type={confirmPass ? ("text") : ("password")} placeholder='Confirm password'
                value={pass}
                onChange={(e) => setPass(e.target.value)} />
              {confirmPass ? (<i onClick={() => setConfirmPass(false)} class="passView ri-eye-fill"></i>) : (<i onClick={() => setConfirmPass(true)} class="passView ri-eye-off-fill"></i>)}
            </div>
          </div>
          <div className="mt-[24px]  h-[50px]  ">
            <button onClick={handleChangeEmail} disabled={!allFieldsCompleted}

              style={{
                fontWeight: '500', color: '#0D0E0F', fontSize: '18px', opacity: allFieldsCompleted ? '1' : '0.5'

              }} type="submit" className="border w-[178px] float-right  text-[18px] rounded-[12px] px-[16px] py-[12px] font-medium bg-[#E8AE00] "
            >
              Save changes
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6 float-right "
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </button>
          </div>

        </div>

      </div>
      <EmailSuccess emailsuccess={emailsuccess} setEmailsuccess={setEmailsuccess} />
    </div>
  )
}

export default ChangeEmail