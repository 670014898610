import React, { useState, useEffect } from "react";
import bed24 from "../../../assets/Images/property/bed24.png";
import bathub24 from "../../../assets/Images/property/bathub24.png";
import Chairs24 from "../../../assets/Images/property/Chairs24.png";

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { get_listingByStatus } from "../../../apiServices";

import PhotoImg from '../../../assets/image2.png'
import InvestIcon from '../../../assets/InvestIcon.png'

function PropertyReview(props) {

  const { activeLink, userListings } = props;
  const location = useLocation()
  const navigate = useNavigate();

  const [listings, setListings] = useState([])
  const { link } = useParams()
  const [loading, setLoading] = useState(true);

  const filterListings = async () => {
    let listingType;
    if (link == 'for-sale') {
      listingType = 'FOR_SALE'
    } else if (link == 'for-rent') {
      listingType = 'FOR_RENT'

    } else {
      listingType = 'FOR_INVESTMENT'

    }

    try {
      setLoading(true)
      const res = await get_listingByStatus(listingType, 'REVIEWING')
      setListings(res.listings)
      setLoading(false)

    } catch (err) {
      setLoading(false)

    }
  };

  useEffect(() => {
    filterListings()
  }, [location])


  return (
    <div className=" mb-[18vmax]">
      <div className="">
        <div class="container p-0">
          <div class="d-flex flex-lg-row flex-column">
            <div class="col">
              <div className="myPropertyTitle  text-[26px] font-semibold ">My properties</div>
            </div>
            <div class="col">
              <div className="w-[308px] mt-[6px] h-[24px] text-center ">

                <p style={{ cursor: "pointer" }} className={`myPropertyTitle float-left text-[18px] font-medium pr-[24px] ${location.pathname.includes('for-sale') ? 'active-link' : ' '}`}

                  onClick={() => navigate('/dashboard/my-propertymain')}>
                  For sale
                </p>
                <p style={{ cursor: "pointer", }} onClick={() => navigate('/dashboard/for-rent')}
                  className={`myPropertyTitle float-left text-[18px] font-medium pr-[24px] ${location.pathname.includes('for-rent') ? 'active-link' : ' '}`}>
                  For rent

                </p>
                <p style={{ cursor: "pointer", }} onClick={() => navigate('/dashboard/for-investment')}
                  className={`myPropertyTitle text-[18px] font-medium ${location.pathname.includes('for-investment') ? 'active-link' : ' '}`}>
                  For investment</p>
              </div>
            </div>
            <div class="col"></div>
          </div>
        </div>
      </div>
      <div className="  ">
        <div className="  ">
          <div class="container">
            <div class="row">
              <div class="p-0 col">
                <p style={{ cursor: "pointer", marginBottom: '0' }} onClick={() => navigate('/dashboard/my-propertymain')} className="myPropertyTitle text-start text-[18px] font-semibold">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="myPropertyBackIcon w-5 h-5 float-left mt-[3px]"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.75 19.5L8.25 12l7.5-7.5"
                    />
                  </svg>
                  Reviewing
                </p>
              </div>
              <div class="col myPropertyTotalValue">
                <p className=" text-[18px] font-semibold text-[#098895] text-end myPropertyTitle">
                  {listings.length} Properties
                </p>
              </div>
            </div>
          </div>
        </div>
        {
          loading ? (
            <h6 className='notifyLoading'>Loading...</h6>

          ) : (
            <>
              {
                listings.length != 0 ? (
                  listings?.map((listings, i) => {
                    return (
                      <div onClick={() => navigate(`/property-detail/${listings?._id}`)} key={i} className="cursor-pointer active-card review mb-4">
                        <div class=" ms-0 mt-2">
                          <div class="d-flex ">
                            <div className="position-relative">
                              <img
                                src={listings?.listingMedia[0]}
                                alt=""
                                style={{
                                  height: '246px', borderTopLeftRadius: '12px', // Border radius for the top-left corner
                                  borderBottomLeftRadius: '12px',
                                  width: '525px'
                                }}
                                className="myPropertyImg col-lg-7 col-10  h-[246px]  float-left"
                              />
                              {/* Overlay */}
                              <div
                                style={{
                                  position: 'absolute',
                                  bottom: '15px',
                                  right: '15px',
                                  backgroundColor: '#F9FAFB',
                                  color: '#0D0E0F',
                                  padding: '4px 8px',
                                  borderRadius: '12px',
                                  fontSize: '14px',
                                  fontWeight: '400',
                                  display: 'flex'
                                }}
                                className='myPropertyImg__total'

                              >
                                <img
                                  style={{ width: '12px', height: '12px', marginTop: '5px', marginRight: '3px' }}
                                  src={PhotoImg}
                                  alt=''
                                />

                                {0 + 1}/{listings.listingMedia.length}
                              </div>
                            </div>

                            <div class="col">
                              <div className="myPropertyContainer1 ml-[23px] my-[20px]  h-[44px]">
                                <div class="container ">
                                  <div class="row">
                                    <div class="col">
                                    {link == 'for-rent' && <h2 className="myPropertyPrice text-[36px] font-semibold ">
                                  £{listings?.rentalPrice} </h2>}
                                  {link == 'for-sale' && <h2 className="myPropertyPrice text-[36px] font-semibold ">
                                    £{listings?.price}</h2>}
                                  {link == 'for-investment' && <h2 className="myPropertyPrice text-[36px] font-semibold ">
                                    £{listings?.minInvestment}</h2>}
                                    </div>

                                  </div>
                                </div>
                              </div>
                              <div className="myPropertyContainer2 ml-[23px] h-[66px]">
                                <div class="container myPropertySubContainer2__main ">
                                  <div style={{ cursor: 'pointer' }} class="">
                                    <div className="myPropertySubContainer2 h-[36px]">
                                      {link == 'for-investment' ? <h2 style={{ textTransform: 'capitalize' }} className="myPropertyType text-[26px] font-semibold text-[#135966] ">
                                        {listings?.investmentCategory}</h2> :
                                        <h2 className="myPropertyType text-[26px] font-semibold text-[#135966]">

                                          {listings?.propertyType}
                                        </h2>

                                      }
                                
                                    </div>
                                    <div className="mt-[4px] h-[26px]">
                                      {
                                        listings?.addressLine1 ? (
                                          <h2 className="myPropertyPlace text-[18px] font-normal ">
                                            {listings?.addressLine1}, {listings?.town}
                                          </h2>
                                        ) : (
                                          <h2 className="myPropertyPlace text-[18px] font-normal ">{listings?.postalCode} </h2>
                                        )
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="myPropertyContainerTop3 ml-[23px] my-[20px] h-[56px]">
                                <div style={{ backgroundColor: '#F9FAFB', padding: '10px', paddingLeft: '30px', textAlign: 'center', width: '500px', borderRadius: '15px' }} class="myPropertyContainer3 container ">
                                  <div class="myPropertySubContainer3__main d-flex align-items-center">
                                    <div class="col myPropertySubContainer3 ">
                                      <div style={{width:'max-content'}} className=" text-start d-flex flex-lg-row ">
                                        <img
                                          src={bed24}
                                          alt=""
                                          className="myPropertyContainer3__icons w-[24px] h-[24px] float-left mx-[4px] "
                                        />
                                        <span className="myPropertyContainer3__iconsText text-[14px] font-normal">
                                          {listings?.numberofBedrooms} <span className='myPropertyContainer3__iconsSpan'>beds</span>
                                        </span>
                                      </div>
                                    </div>
                                    <div  class="col myPropertySubContainer3">
                                      <div style={{width:'max-content'}} className="text-start d-flex flex-lg-row ">
                                        <img
                                          src={bathub24}
                                          alt=""
                                          className="myPropertyContainer3__icons w-[24px] h-[24px] float-left mx-[4px] "
                                        />
                                        <span className="myPropertyContainer3__iconsText text-[14px] font-normal ">
                                          {listings?.numberofBathrooms} <span className='myPropertyContainer3__iconsSpan'>baths</span>
                                        </span>
                                      </div>
                                    </div>
                                    <div  class="col myPropertySubContainer3 ">
                                      <div style={{width:'max-content'}} className="text-start d-flex flex-lg-row ">
                                      {
                                        link=='for-investment' ?(
                                          <img
                                          src={InvestIcon}
                                          alt=""
                                          className="myPropertyContainer3__icons w-[24px] h-[24px] float-left ]"
                                          style={{ filter: 'brightness(0)', color: 'black' }} 
                                        />
                                        ):(
                                          <img
                                          src={Chairs24}
                                          alt=""
                                          className="myPropertyContainer3__icons w-[24px] h-[24px] float-left ]"
                                        />
                                        )
                                      } 
                                        {
                                          link == 'for-investment' ? <span style={{ textTransform: 'capitalize' }} className="myPropertyContainer3__iconsText text-[14px] font-normal">
                                            {listings?.investmentType}
                                          </span> : <span className="myPropertyContainer3__iconsText text-[14px] font-normal">
                                            {listings?.furnishingOption}
                                          </span>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <h6 style={{ marginLeft: '15px' }}>You don't have any property in reviewing </h6>
                )
              }
            </>
          )
        }
        {/* ------------ */}
      </div>
    </div>
  );
}

export default PropertyReview;
