import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import axios from 'axios';
import './SuccessPop.css'
import { useNavigate } from 'react-router-dom';
import CancelIcon from '../../assets/cancel-icon.png'

import { useDispatch } from 'react-redux';
import { listingActions } from '../../store/slice/listing-slice';
export default function SuccessPop({ success, setSuccess,isBoost }) {
    const [hasReview, setHasReview] = useState(null);
  const dispatch=useDispatch()
    const cancelButtonRef = useRef(null)

    const navigate = useNavigate();
    const handleHasReview = () => {
        const userId = localStorage.getItem('id')
        const res = axios.get(`https://linkedbricks-backend.onrender.com/reviews/has-review/${userId}`)
            .then(response => {
                const data = response.data;
                setHasReview(data.hasReview);
            })
            .catch(error => {
                console.error('Error:', error);
                // Handle error
            });

    }
    useEffect(() => {
        handleHasReview()
    }, [])
    const handleDashboard=()=>{
        const listingAddress={
            searchAddress:'',
            address:'',
            town:'',
            country:'',
            postalCode:''
          }
          dispatch(listingActions.addListingAddress(listingAddress))
          const listingData = {
      
            propertyType:'',
            furnishingOption:"",
            price:'',
            numberofBathrooms: '',
            numberofBedrooms: '',
            propertyAge:'',
            carpetArea:'',
            listingMedia: [],
            floorImage: [],
      
            propertyDesc:'',
            selectedDate:"",
            phoneNumber:'',
            showNumber:false,
            statusGroup:'Agent',
            parking:false,
            securityAlarm:false,
            balconies:false,
            garden:false,
            swimmingPool:false,
            kitchen:false,
            otherFeatures:'',
            depositAmount:null,
            occupancyLimit:null,
            minTenancyLength:null,
            maxTenancyLength:null,
            rentalPrice:null,
            paymentPlan:null,
            minInvestment:'',
            investmentCategory:'',
            investmentType:'',
            expectedROI:'',
            roiPlan:"",
            historicPerformance:'',
            roiPlanInput:'',
            historicInput:'',
            investmentTime:'',
            timelineDate:'',
            investmentTimeType:'',
            natureInvestment:'',
            zoiningPermits:'',
            propertyValue:""
          }
      
          dispatch(listingActions.addListingDetail(listingData))
          const listingPremiums={
            premiumUpgrade:null,
            premiumRightMove:null,
            premiumZoopla:null,
            energyCertifcate:null,
            gasCertificate:null,
            premiumRent:null,
            premiumTenancyRef:null,
            premiumTenancyAgreem:null,
          }
          dispatch(listingActions.addListingPremiums(listingPremiums))
          dispatch(listingActions.addIsListing(false))

    }

    return (
        <>
            <Transition.Root show={success} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setSuccess}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 ">
                        <div className="">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="  ">
                                    <div className='success'>
                                        <div className="successBox">
                                            <img src={CancelIcon} onClick={() => setSuccess(false)} className=" ri-close-circle-line" />
                                            <h6>Congratulations!</h6>
                                            {isBoost &&   <p>Your property have been successfully boosted on Linked Bricks!
                                                </p> }
                                                {!isBoost &&   <p>Your property have been successfully listed on Linked Bricks!
                                                    </p> }
                                            <button onClick={() => {
                                                localStorage.setItem("rw", "rw");
                                                navigate('/dashboard/my-propertymain');
                                                handleDashboard()
                                            }} >Go to dashboard</button>
                                        </div>

                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>

    )
}
