import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import './PasswordSuccess.css'
import Signin from './Signin'


export default function PasswordSuccess({ changepass, setChangepass }) {

    const cancelButtonRef = useRef(null)

    const [open, setOpen] = useState(false)

    return (
        <>
            <Transition.Root show={changepass} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setChangepass}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 ">
                        <div className="">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="  ">

                                    <div className="PasswordChnage">
                                        <div className="passwordsuccess">
                                            <i style={{ cursor: "pointer", width: "100%", display: "flex", alignItems: "flex-end", justifyContent: "flex-end", fontSize: "1.5vmax", }} onClick={() => setChangepass(false)} className=" ri-close-circle-line"></i>
                                            <h5>Your password has been changed</h5>
                                            <p><span onClick={() => setOpen(true)} style={{ color: "blue", cursor: "pointer" }}>Sign In</span> with your new password</p>
                                        </div>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <Signin open={open} setOpen={setOpen} />
        </>

    )
}
