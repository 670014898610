import { Fragment, useRef, } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import './AccountDelete.css'
import { useNavigate } from 'react-router-dom'
import { delete_userMessages } from '../../apiServices'

export default function Conversation({ conversation, setConversation, messageId,getUserDataCounts }) {

    const navigate = useNavigate()
    const cancelButtonRef = useRef(null)

    const handleDeleteMessage = async () => {
        try {
            const res = await delete_userMessages(messageId)
            navigate('/dashboard/my-account/message')
            getUserDataCounts()
        } catch (err) {
            console.log(err)
            alert(err)
        }
    }

    return (
        <>
            <Transition.Root show={conversation} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setConversation}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 ">
                        <div className="">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="  ">
                                    <div className='DeleteProperty'>
                                        <div className="Deleted">
                                            {/* <i onClick={() => setOpencard(false)} className="signclose ri-close-circle-line"></i> */}
                                            <div className="Deletepop">
                                                <h5>Delete Conversation</h5>
                                                <p>Are you sure you want to delete this conversation? This will permanently delete all the files you’ve sent as well.</p>
                                                <div className="desidebtn">
                                                    <button onClick={() => setConversation(false)} style={{ border: "1.5px solid #E53935" }} className='bg-white'>Cancel</button>
                                                    <button onClick={handleDeleteMessage}>Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            {/* <Signup signUpOpen={signUpOpen} setSignUpOpen={setSignUpOpen} /> */}
        </>

    )
}
