import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import LOGO from '../../assets/Images/navimage/LOGO.png'
import './Signup.css';
import { register_user, signIn_provider } from '../../apiServices';
// import Signin from './Signin'
import { signInWithPopup } from 'firebase/auth'
import { auth, provider, facebook } from '../../firebase/firebase'
import { useDispatch } from 'react-redux';
import { authActions } from '../../store/slice/auth-slice';
import { useNavigate } from 'react-router-dom';

export default function Signup({ isSaved, signUpOpen, setSignUpOpen, setOpen, isListing, isAuth, isSchedule, isContact }) {
    const [show, setShow] = useState(false)
    const [confirmPass, setConfirmPass] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const cancelButtonRef = useRef(null)

    const handleSignup = async (e) => {
        e.preventDefault()
        const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
        if (!passwordRegex.test(password)) {
            alert(
                'Password must be at least 8 characters with 1 uppercase letter and 1 special character'
            );
            return;
        }
        if (password !== confirmPassword) {
            alert("Passwords don't match");
            return;
        }
        try {
            const user = { email, password };
            const response = await register_user(user)
            setEmail('')
            setPassword('')
            setConfirmPassword('')
            alert('Please check your email, we have sent a link to verify your account!')
            // alert(" You're succesfully signup with email " + response.email);

            // setOpen(true);
            setSignUpOpen(false);
        } catch (error) {
            alert(error);
        }
    }

    const handleSignIn = () => {
        setOpen(true);
        setSignUpOpen(false);
    }


    const handleGoogleLogin = async () => {
        await signInWithPopup(auth, provider)
            .then(async (result) => {
                const authenticatedUser = result.user;
                let userDetail = {
                    email: authenticatedUser.email,
                    photoUrl: authenticatedUser.photoURL,
                    fullName: authenticatedUser.displayName

                };
                const res = await signIn_provider(userDetail)
                setSignUpOpen(false);
                const userData = {
                    userId: res.userId,
                    email: res.email,
                    token: res.jwt,

                }
                dispatch(authActions.login(userData))
                localStorage.setItem("id", res.userId)
                localStorage.setItem("token", res.jwt);
                localStorage.setItem('userEmail',res.email)



                navigate('/dashboard/my-propertymain');

            })
    }
    const handleFacebookLogin = async () => {
        await signInWithPopup(auth, facebook)
            .then(async (result) => {
                const authenticatedUser = result.user;
                let userDetail = {
                    email: authenticatedUser.email,
                    photoUrl: authenticatedUser.photoURL,
                    fullName: authenticatedUser.displayName

                };
                const res = await signIn_provider(userDetail)
                setSignUpOpen(false);
                const userData = {
                    userId: res.userId,
                    email: res.email,
                    token: res.jwt,

                }
                dispatch(authActions.login(userData))
                localStorage.setItem("id", res.userId)
                localStorage.setItem("token", res.jwt);
                localStorage.setItem('userEmail',res.email)

                navigate('/dashboard/my-propertymain');

            })
    }
    return (
        <>
            <Transition.Root show={signUpOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setSignUpOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 ">
                        <div className="">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className=" ">
                                    <div className='sinmain2'>
                                        <i onClick={() => setSignUpOpen(false)} className="signclose ri-close-line rounded-circle px-2" style={{ backgroundColor: "#FFF9E6", marginTop: "-1vmax", marginRight: "-1vmax", cursor: "pointer" }}></i>
                                        <div className='signflex2'>
                                            <div className='signone2'>
                                                <img src={LOGO} alt="" />

                                            </div>
                                            <div className='signtwo2'>
                                                {
                                                    isListing && <h5 >Sign up to list your <br /> properties and much more</h5>

                                                }
                                                {
                                                    isSaved && <h5 > Sign up to save your <br /> property and much more</h5>

                                                }
                                                {
                                                    isContact && <h5 > Sign up to contact property <br /> owner and much more</h5>

                                                }
                                                {
                                                    isAuth && <h5 > Welcome to Linked Bricks. <br /> Sign up to enjoy much more</h5>

                                                }
                                                {
                                                    isSchedule && <h5 > Sign up to schedule a tour <br /> and much more</h5>

                                                }

                                                <h6>Already resistered?  <span
                                                    onClick={handleSignIn}
                                                > Sign in</span></h6>

                                                <form onSubmit={handleSignup}>
                                                    <input className='' type="email" placeholder='Email'
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)} required />
                                                    <input className='mt-2' type={show ? ("text") : ("password")} placeholder='Password'
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)} required />
                                                    {show ? (<i style={{color:'#A6A7A8'}} onClick={() => setShow(false)} class="eyes ri-eye-fill"></i>) : (<i style={{color:'#A6A7A8'}} onClick={() => setShow(true)} class="eyes ri-eye-off-fill"></i>)}
                                                    <input className=' mt-2' type={confirmPass ? ("text") : ("password")} placeholder='Confirm Password'
                                                        value={confirmPassword}
                                                        onChange={(e) => setConfirmPassword(e.target.value)} required />
                                                    {confirmPass ? (<i style={{color:'#A6A7A8'}} onClick={() => setConfirmPass(false)} class="eyes ri-eye-fill"></i>) : (<i style={{color:'#A6A7A8'}} onClick={() => setConfirmPass(true)} class="eyes ri-eye-off-fill"></i>)}

                                                    <p>Password must be at least 8 characters with 1 uppercase letter and 1 special charecter</p>
                                                    <button type='submit'>Sign Up</button>
                                                </form>

                                                <h3>By signing up you accept our <span style={{cursor:'pointer'}} onClick={()=>{setSignUpOpen(false);
                                                    navigate('/termsofuse')}}>Terms of Use</span> and <span style={{cursor:'pointer'}}  onClick={()=>{setSignUpOpen(false);
                                                        navigate('/privacypolicy')}}>Privacy Policy</span></h3>

                                                <h4>or continue with</h4>
                                                <div className='signicon2'>
                                                    <i style={{ cursor: 'pointer' }} onClick={handleGoogleLogin} class="ri-google-fill "></i>
                                                    <i style={{ cursor: 'pointer' }} onClick={handleFacebookLogin} class="ri-facebook-fill ms-5"></i>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            {/* <Signin open={open} setOpen={setOpen}/> */}
        </>
    )
}
