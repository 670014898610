import React, { useState, useEffect } from 'react'
import "./Inactive.css"
import InactiveImg from "../../../assets/Images/property/Inactivve.png"
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import DeleteProperty from '../../Popupfile/DeletePropert'
import DeactivateProperty from "../../Popupfile/DeactivateProperty";
import Insight from '../../Popupfile/Insight';
import { deactive_listing, delete_listing, get_listingByStatus } from '../../../apiServices';
import Delete from '../../../assets/Delete/Vector (2).png'
import bathub24 from "../../../assets/Images/property/bathub24.png";
import EditIcon from '../../../assets/Edit.png'
import PhotoImg from '../../../assets/image2.png'
import ActivateProperty from '../../Popupfile/ActivateProperty.js';
import MoreOption from '../../../assets/Group.png'
import More from "../../Popupfile/MoreOption";
import InvestIcon from '../../../assets/InvestIcon.png'
import Chairs24 from "../../../assets/Images/property/Chairs24.png";


const Inactive = ({ activeLink, userListings }) => {
  const [deleted, setDeleted] = useState(false)
  const [deactive, setDeactive] = useState(false)
  const [insight, setInsight] = useState(false)
  const [propertyId, setPropertyId] = useState('')
  const [isInActive, setIsInActive] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)
  const [isActivate, setIsActivate] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  const [listings, setListings] = useState([])
  const { link } = useParams()
  const [loading, setLoading] = useState(true);
  const [more, setMore] = useState(false)
  const [isInactivePop,setIsInactivePop]=useState(false);

  const filterListings = async () => {
    let listingType;
    if (link == 'for-sale') {
      listingType = 'FOR_SALE'
    } else if (link == 'for-rent') {
      listingType = 'FOR_RENT'

    } else {
      listingType = 'FOR_INVESTMENT'

    }

    try {
      setLoading(true)
      const res = await get_listingByStatus(listingType, 'INACTIVE')
      setListings(res.listings)
      setLoading(false)

    } catch (err) {
      setListings([])
      setLoading(false)

    }
  };

  const handleDeleteListing = async () => {
    try {
      const response = await delete_listing(propertyId)
      // Optionally update the state or fetch listings again
      setDeleted(false)
      setIsDeleted(true)
      filterListings()
    } catch (error) {
      console.error(error);
      setListings([])
      // Handle error
    }
  }

  const handleUpdateStatus = async () => {
    try {
      let status = 'ACTIVE'
      const response = await deactive_listing(propertyId, status)
      // Optionally update the state or fetch listings again
      setDeactive(false)

      setIsActivate(true)
      filterListings()

    } catch (error) {
      console.error(error);
      // Handle error
    }
  };

  useEffect(() => {
    filterListings()
  }, [location])

  return (
    <div className='Inactive'>
      {
        isDeleted && <div className="col-11 statusNotify ms-3 me-5 mb-4 p-3 d-flex justify-content-between  bg-[#EBFFFD] rounded-[12px]">
          <div className="text-[16px] font-medium  ms-2">
            Your listing has been successfully deleted.
          </div>
          <spna onClick={() => setIsDeleted(false)} className="">
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-8    me-1 "
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </spna>
        </div>
      }
      {
        isActivate && <div className="col-11 ms-3 me-5 mb-4 p-3 d-flex justify-content-between  bg-[#EBFFFD] rounded-[12px]">
          <div className="text-[16px] font-medium  ms-2">
            Your listing has been successfully activated.
          </div>
          <spna onClick={() => setIsActivate(false)} className="">
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-8    me-1 "
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </spna>
        </div>
      }
      <div class="d-flex flex-lg-row flex-column ">
        <div class="col">
          <div className="myPropertyTitle  text-[26px] font-semibold ">My properties</div>
        </div>
        <div class="col">
          <div className="myPropertyOptions w-[308px] mt-[6px] h-[24px] text-center ">

            <p style={{ cursor: "pointer" }} className={`myPropertyTitle float-left text-[18px] font-medium pr-[24px] ${location.pathname.includes('for-sale') ? 'active-link' : ' '}`}

              onClick={() => navigate('/dashboard/my-propertymain')}>
              For sale
            </p>
            <p style={{ cursor: "pointer", }} onClick={() => navigate('/dashboard/for-rent')}
              className={`myPropertyTitle float-left text-[18px] font-medium pr-[24px] ${location.pathname.includes('for-rent') ? 'active-link' : ' '}`}>
              For rent

            </p>
            <p style={{ cursor: "pointer", }} onClick={() => navigate('/dashboard/for-investment')}
              className={`myPropertyTitle text-[18px] font-medium ${location.pathname.includes('for-investment') ? 'active-link' : ' '}`}>
              For investment</p>
          </div>
        </div>
        <div class="col"></div>
      </div>
      <div className=' '>
        <div class="container">
          <div class="row">
            <div class="p-0 col">
              <p style={{ cursor: "pointer", }} onClick={() => navigate('/dashboard/my-propertymain')} className="myPropertyTitle text-start text-[18px] font-semibold">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="myPropertyBackIcon w-5 h-5 float-left mt-[3px]"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
                </svg>
                Inactive
              </p>
            </div>
            <div class="col myPropertyTotalValue
            mr-6">
              <p className="myPropertyTitle text-[18px] font-semibold text-[#098895] text-end ">
                {listings.length} Properties
              </p>
            </div>
          </div>
        </div>
      </div>
      {
        loading ? (
          <h6 className='notifyLoading'>Loading...</h6>

        ) : (
          <>
            {
              listings.length != 0 ? (
                listings?.map((listings, i) => {
                  return (
                    <>
                    <div className="mb-4 cursor-pointer Propertycrd">
                      <div className="CHouse">
                        <div onClick={() => navigate(`/property-detail/${listings?._id}`)} className="cLeft">
                          <div className="w-[100%] position-relative">
                            <img style={{
                              height: '316px', borderTopLeftRadius: '12px', // Border radius for the top-left corner
                              borderBottomLeftRadius: '12px'
                            }} src={listings?.listingMedia[0]} alt='/' className='myPropertyImg' />
                            {/* Overlay */}
                            <div
                              style={{
                                position: 'absolute',
                                bottom: '15px',
                                right: '15px',
                                backgroundColor: '#F9FAFB',
                                color: '#0D0E0F',
                                padding: '4px 8px',
                                borderRadius: '12px',
                                fontSize: '14px',
                                fontWeight: '400',
                                display: 'flex'
                              }}
                              className='myPropertyImg__total'

                            >
                              <img
                                style={{ width: '12px', height: '12px', marginTop: '5px', marginRight: '3px' }}
                                src={PhotoImg}
                                alt=''
                              />

                              {0 + 1}/{listings.listingMedia.length}
                            </div>
                          </div>

                        </div>
                        <div className="cRight">
                          <div  className="tp">
                            <div onClick={() => navigate(`/property-detail/${listings?._id}`)} className="price">
                              {link == 'for-rent' && <h5 className='myPropertyPrice'> £{listings?.rentalPrice}</h5>}
                              {link == 'for-sale' && <h5 className='myPropertyPrice'>£{listings?.price}</h5>}
                              {link == 'for-investment' && <h5 className="myPropertyPrice ">
                                £{listings?.minInvestment}</h5>}
                            </div>
                            <div  className='flex myPropertyIcon2 '>

                            <img style={{ height: '12px'}} onClick={() => {setMore(true)
                              setIsInactivePop(true)
                              setIsInActive(true)
                            }} src={MoreOption} />

                          </div>

                          </div>
                          <div onClick={() => navigate(`/property-detail/${listings?._id}`)} style={{ cursor: 'pointer', marginLeft: '5px' }} className="myPropertyContainer2 md">
                            {link == 'for-investment' ? <h2 style={{ textTransform: 'capitalize' }} className="myPropertyType myPropertyType2 text-[26px] font-semibold text-[#135966] ">
                              {listings?.investmentCategory}</h2> :
                              <h2 className="myPropertyType myPropertyType2 text-[26px] font-semibold text-[#135966]">

                                {listings?.propertyType}
                              </h2>

                            }                            {
                              listings?.addressLine1 ? (
                                <p className='myPropertyPlace myPropertyPlace2'>{listings?.addressLine1}, {listings?.town} </p>

                              ) : (
                                <p className='myPropertyPlace myPropertyPlace2'>{listings?.postalCode} </p>

                              )
                            }                         </div>
                          <div className="declinedButtons lt">
                            <button style={{ border: "1.5px solid #E8AE00", display: 'flex', paddingTop: '10px' }} onClick={() => {
                              setPropertyId(listings?._id)
                              setDeactive(true)
                            }} ><img style={{ width: '24px', height: '24px', fontSize: '17px' }} src={EditIcon} />
                              <span style={{ marginLeft: '6px', marginTop: '2px' }}>
                                Edit & Reactivate
                              </span></button>
                            <button style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: "2px solid #E53935" }} onClick={() => {
                              setPropertyId(listings?._id);

                              setDeleted(true)
                            }} >
                              <img style={{ width: '13px', height: '15px' }} src={Delete} />
                              <span style={{ marginLeft: '6px', marginTop: '5px' }}>
                                Delete
                              </span></button>
                          </div>
                          <div onClick={() => navigate(`/property-detail/${listings?._id}`)} className="myPropertyContainer3 bm">
                            <p><i class="ri-hotel-bed-fill"></i>{listings?.numberofBedrooms} <span className='myPropertyContainer3__iconsSpan'>beds</span></p>
                            <p><img
                              src={bathub24}
                              alt=""
                              className="w-[24px] h-[24px] float-left mx-[4px] "
                            />{listings?.numberofBathrooms}<span className='myPropertyContainer3__iconsSpan'>baths</span></p>

                            <p>

                            {
                              link=='for-investment' ?(
                                <img
                                src={InvestIcon}
                                alt=""
                                className="myPropertyContainer3__icons w-[24px] h-[24px] float-left ]"
                                style={{ filter: 'brightness(0)', color: 'black' }} 
                              />
                              ):(
                                <img
                                src={Chairs24}
                                alt=""
                                className="myPropertyContainer3__icons w-[24px] h-[24px] float-left ]"
                              />
                              )
                            } 
                            
                            {
                              link == 'for-investment' ? <span style={{ textTransform: 'capitalize' }} className="text-[14px] font-normal">
                                {listings?.investmentType}
                              </span> : <span className="text-[14px] font-normal ">
                                {listings?.furnishingOption}
                              </span>
                            }</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <More isInActive={isInActive} setDeactive={setDeactive} setPropertyId={setPropertyId} isDeclinePop={isInactivePop} more={more} setMore={setMore} listingId={listings?._id} setDeleted={setDeleted} />

                    </>
                  )
                })
              ) : (
                <h6 style={{ marginLeft: '15px' }}>You don't have any inactive property</h6>
              )
            }
          </>
        )
      }

      <DeleteProperty handleDeleteListing={handleDeleteListing} deleted={deleted} setDeleted={setDeleted} />
      <ActivateProperty handleUpdateStatus={handleUpdateStatus} deactive={deactive} setDeactive={setDeactive} />

    </div>
  )
}

export default Inactive