import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import LogoMain from "../../assets/Images/navimage/maillogo.png";
import "./PasswordResetEmail.css";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function PasswordResetEmail({ passwordMail, setPasswordMail }) {
  const navigate = useNavigate();
  const cancelButtonRef = useRef(null);
  const userEmail = useSelector(state => state.auth.userEmail)
  const closePopup = () => {
    setPasswordMail(false);
  };
  return (
    <>
      <Transition.Root show={passwordMail} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={closePopup}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10">
            <div className="">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="  ">
                  <div className='PasswordRestEmail'>
                    <div className="PasswordTemplate">
                      <div className="PasswordTemptop">
                        <img src={LogoMain} alt="" />
                      </div>
                      <div className="PasswordTempTwo">
                        <h4 style={{ fontWeight: '700px' }}>We received a request to reset your password </h4>
                        <h6>Hello there! 👋</h6>
                        <p>This is for your Linked Bricks account with email: <span style={{color:'#098895'}}>{userEmail}</span>. If you want to reset your password, please click on the button below:</p>
                        <button onClick={() => { closePopup(); navigate('/passwordchangemail'); }}>Reset your password</button>
                      </div>
                      <div className="PasswordTempThree">
                        <p>If you don’t want to change your password, you can safely ignore this email and continue using Linked Bricks with your existing password. We’re happy to help, so please feel free to <span style={{color:'#098895'}}>contact us</span> with any questions or feedback. </p>
                        <h6>Thanks</h6>
                        <p>The Linked Bricks Team</p>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
