import { createSlice } from '@reduxjs/toolkit'

export const initialListingState = {
    listingId: localStorage.getItem('listingId') || '',
    isAddListing: false,
    premiumTotal: 0,
    premiumUpgrade:0,
    premiumRightMove:0,
    premiumZoopla:0,
    energyCertifcate:0,
    gasCertificate:0,
    premiumRent:0,
    premiumTenancyRef:0,
    premiumTenancyAgreem:0,
    listingFormAddress:{
        searchAddress:'',
        address:'',
        town:'',
        country:'',
        postalCode:''
    },
    listingPremiums:{
        premiumUpgrade:null,
        premiumRightMove:null,
        premiumZoopla:null,
        energyCertifcate:null,
        gasCertificate:null,
        premiumRent:null,
        premiumTenancyRef:null,
        premiumTenancyAgreem:null,
    },
    listingFormDetails:{
        propertyType:'',
              furnishingOption:"",
              price:'',
              numberofBathrooms: '',
              numberofBedrooms: '',
              propertyAge:'',
              carpetArea:'',
              listingMedia: [],
              floorImage: [],
    
              propertyDesc:'',
              selectedDate:"",
              phoneNumber:'',
              showNumber:false,
              statusGroup:'Agent',
              parking:false,
              securityAlarm:false,
              balconies:false,
              garden:false,
              swimmingPool:false,
              kitchen:false,
              otherFeatures:'',
              depositAmount:null,
              occupancyLimit:null,
              minTenancyLength:null,
              maxTenancyLength:null,
              rentalPrice:null,
              paymentPlan:null,
              minInvestment:'',
              investmentCategory:'',
              investmentType:'',
              expectedROI:'',
              roiPlan:"",
              historicPerformance:'',
              roiPlanInput:'',
              historicInput:'',
              investmentTime:'',
              timelineDate:'',
              investmentTimeType:'',
              natureInvestment:'',
              zoiningPermits:'',
              propertyValue:""
    }
    // listingFormDetails:{

    // }

}

const ListingSlice = createSlice({
    name: 'listing',
    initialState: initialListingState,
    reducers: {
        addListingId(state, action) {
            state.listingId = action.payload
        },
        addIsListing(state, action) {
            state.isAddListing = action.payload
        },
        addPremiumTotal(state, action) {
            state.premiumTotal =action.payload
        },
        increaseUpgrade(state, action) {
            state.premiumUpgrade += 10
        },
        decreaseUpgrade(state, action) {
            if(state.premiumUpgrade>0){
                state.premiumUpgrade -= 10

            }
        },
        increaseRightMove(state, action) {
            state.premiumRightMove += 10
        },
        decreaseRightMove(state, action) {
            if(state.premiumRightMove>0){
                state.premiumRightMove -= 10

            }
        },
        increaseZoopla(state, action) {
            state.premiumZoopla += 10
        },
        decreaseZoopla(state, action) {
            if(state.premiumZoopla>0){
                state.premiumZoopla -= 10

            }
        },
        increaseGas(state, action) {
            state.gasCertificate += 10
        },
        decreaseGas(state, action) {
            if(state.gasCertificate>0){
                state.gasCertificate -= 10

            }
        },
        increaseEnergy(state, action) {
            state.energyCertifcate += 10
        },
        decreaseEnergy(state, action) {
            if(state.energyCertifcate>0){
                state.energyCertifcate-= 10

            }
        },
        increaseTenancyRef(state, action) {
            state.premiumTenancyRef += 10
        },
        decreaseTenancyRef(state, action) {
            if(state.premiumTenancyRef>0){
                state.premiumTenancyRef -= 10

            }
        },
        increaseRent(state, action) {
            state.premiumRent += 10
        },
        decreaseRent(state, action) {
            if(state.premiumRent>0){
                state.premiumRent -= 10

            }
        },
        increaseTenancyAgree(state, action) {
            state.premiumTenancyAgreem  += 10
        },
        decreaseTenancyAgree(state, action) {
            if(state.premiumTenancyAgreem >0){
                state.premiumTenancyAgreem -= 10

            }
        },
        addListingAddress(state,action){
            state.listingFormAddress=action.payload
        },
        addListingDetail(state,action){
            state.listingFormDetails=action.payload
        },
        addListingPremiums(state,action){
            state.listingPremiums=action.payload
        }
    }
})


export const listingActions = ListingSlice.actions
export default ListingSlice.reducer