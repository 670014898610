import { configureStore } from "@reduxjs/toolkit";
import authRducer from './slice/auth-slice'
import listingReducer from "./slice/listing-slice";
import homeReducer from './slice/home-slice'

const store = configureStore({
    reducer: {
        auth: authRducer,
        listing: listingReducer,
        home: homeReducer
    }
})

export default store