import React from 'react'
import "./MyPropertymain.css"
import { useNavigate, useLocation } from 'react-router-dom'
import Active from "../../../assets/Images/property/active.png"
import Reviewing from "../../../assets/Images/property/ReviewIcon.png"
import Declined from "../../../assets/Images/property/DeclinedIcon.png"
import Inactive from "../../../assets/Images/property/InactiveIcon.png"
import { useSelector } from 'react-redux'

const Forinvestment = ({ setActiveLink,setShowTabs }) => {

  // ---------------
  const navigate = useNavigate()
  const location = useLocation();
  let activeLink = 'for-investment'
  const isProfileUpdated = useSelector(state => state.auth.isProfileUpdated)

  // --------------
  return (
    <div className='MyPropertymain'>
      <div className="PropertyMain">
        {
          isProfileUpdated == false && <div className="TopOne mb-8">
            <div className="topOneLeft">
              <h5 className="topOneTitle">Welcome to your account</h5>
              <p className="topOnePara">Complete your profile to seamlessly connect with property owners and do so much more.</p>
              <button className="topOneButton" onClick={() => navigate('/dashboard/my-profile')}><span>Complete profile</span> <i style={{ fontSize: '24px' }} className="ri-arrow-right-s-line"></i></button>
            </div>
            <div className="topOneRight">
              <img className="topOneImg" src="https://myproperties.vercel.app/BuildingIllustration.png" alt="" />
            </div>
          </div>
        }

        <div className="TopLast">
          <div className="HeadingP">
            <h5 className='flex'><i onClick={() => setShowTabs(true)} className="showTabBack ri-arrow-left-s-line"></i>My Properties</h5>
            <div className="links">
              <span
                className={location.pathname.includes('my-propertymain') ? 'active-link' : ' '}
                onClick={() => {
                  setActiveLink("for-sale");
                  navigate('/dashboard/my-propertymain')
                }}
              >
                For sale
              </span>
              <span
                className={location.pathname.includes('for-rent') ? 'active-link' : ' '}
                onClick={() => {
                  setActiveLink("for-rent");
                  navigate('/dashboard/for-rent')
                }}
              >
                For rent
              </span>
              <span
                className={location.pathname.includes('for-investment') ? 'active-link' : ' '}
                onClick={() => {
                  setActiveLink('for-investment');
                  navigate('/dashboard/for-investment')
                }}
              >
                For investment
              </span>
            </div>
          </div>
          <div className="activemain">
            <div className="active" onClick={() => { navigate(`/dashboard/${activeLink}/active`) }}>
              <div className="one">
                <img src={Active} alt='' />
                <p>Active</p>
              </div>
              <i class="ri-arrow-right-s-line"></i>
            </div>
            <div className="active" onClick={() => { navigate(`/dashboard/${activeLink}/reviewing`) }}>
              <div className="one">
                <img src={Reviewing} alt='' />
                <p>Reviewing</p>
              </div>
              <i class="ri-arrow-right-s-line"></i>
            </div> <div className="active" onClick={() => { navigate(`/dashboard/${activeLink}/decline`) }}>
              <div className="one">
                <img src={Declined} alt='' />
                <p>Declined</p>
              </div>
              <i class="ri-arrow-right-s-line"></i>
            </div> <div className="active" onClick={() => { navigate(`/dashboard/${activeLink}/inactive`) }}>
              <div className="one">
                <img src={Inactive} alt='' />
                <p>Inactive</p>
              </div>
              <i class="ri-arrow-right-s-line"></i>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Forinvestment