

import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Rating from '@mui/material/Rating';
import axios from 'axios';
import './Rating.css'
import Thankyou from './Thankyou'
import CancelIcon from '../../assets/cancel-icon.png'

export default function AddRating({ openRating, setOpenRating }) {
    const [thankYouOpen, setThankYouOpen] = useState(false)
    const [rating, setRating] = useState(null)
    const [title, setTitle] = useState('')
    const [desc, setDesc] = useState('')

    const handleRating = (rate) => {
        setRating(rate)
        // other logic
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const userId = localStorage.getItem('id')
        const data = {
            rating,
            title,
            description: desc
        }
        try {
            const response = await axios.post(`https://linkedbricks-backend.onrender.com/reviews/submit-review/${userId}`, data, {
                headers: {
                    'x-api-key': 'application/javascript',
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")

                },
            });

            setDesc('')
            setTitle('')
            setRating(0)
            setThankYouOpen(true)
            // Optionally, you can handle success response here (e.g., show a success message)
        } catch (error) {
            console.error('Error submitting review:', error);
            // Optionally, you can handle error response here (e.g., show an error message)
        }
    };
    const cancelButtonRef = useRef(null)

    return (
        <>
            <Transition.Root show={openRating} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenRating}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-dark bg-opacity-75" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="w-[764px] h-[664px] mt-1 relative transform overflow-hidden rounded-lg  ">
                                    <div className='thankmain2 mt-5'>
                                        <div className='thankdisplay2 mt-5'>
                                            <div className='mt-sm-4  mt-lg-0 w-100 d-flex justify-content-lg-between justify-content-evenly  align-items-center'>
                                                <h5 className='w-100 text-center ms-5'>Ratings and Reviews</h5>
                                                
                                                <i onClick={() => setOpenRating(false)} className="ratingicon ri-close-line text-light fs-4  bg-dark px-2 mb-3 rounded-circle"></i>
                                            </div>
                                            <p>We'll like to know how Linked Bricks works for you</p>
                                            <div style={{ marginTop: "-2vmax", color: "rgb(206, 206, 206)", display: 'flex', flexDirection: 'column' }}>
                                                <Rating
                                                    name="simple-controlled"
                                                    value={rating !== null ? rating : 0} // Ensure no star is selected when rating is null
                                                    onChange={(event, newValue) => {
                                                        setRating(newValue);
                                                    }}

                                                    size="large"
                                                    sx={{ color: 'green' }}


                                                />
                                                {/* Add more Rating components here if needed */}
                                            </div>


                                            <div className='d-flex flex-column' style={{ width: "90%" }}>
                                                <label style={{display:'flex',alignItems:'start'}} className='text-[16px] lable fw-semibold' >Add a title or headline</label>
                                                <input style={{border:'1px solid #FFEBB0',color:'#A6A7A8'}} value={title} onChange={(e) => setTitle(e.target.value)} className='px-3' type="text" placeholder='Review title (e.g Great Service)' />
                                                <label style={{display:'flex',alignItems:'start'}} className='text-[16px] lable mt-3 fw-semibold'>Add a description</label>
                                                <textarea style={{border:'1px solid #FFEBB0', color: "#A6A7A8"}} value={desc} onChange={(e) => setDesc(e.target.value)} className='px-3 py-2'  name="" id="" cols="50" rows="7" placeholder='Please describe your experience'></textarea>
                                            </div>
                                            <button className='text-[16px]' onClick={handleSubmit} >Submit Review</button>
                                        </div>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <Thankyou thankYouOpen={thankYouOpen} setThankYouOpen={setThankYouOpen} />
        </>

    )
}
