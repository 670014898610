import React, { useState } from 'react'
import "./DeleteAccount.css"
import { useNavigate } from 'react-router-dom';
import AccountDelete from '../../Popupfile/AccountDelete';
import { useSelector } from 'react-redux';
const DeleteAcccount = ({setShowTabs}) => {

  const [accountDlt, setAccountDlt] = useState(false)
  const isProfileUpdated = useSelector(state => state.auth.isProfileUpdated)
  const [show, setShow] = useState(false)
  const [pass, setPass] = useState('')
  const [deleteData, setDeleteData] = useState('')
  const navigate = useNavigate()
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  let allFieldsCompleted = pass && isChecked;

  return (
    <div className='DeleteAccount'>
      <div className="DeleteAcc">
        {/* ------------------------------- */}
        {
          isProfileUpdated == false && <div className="deleteMain">
            <div className="dltone">
              <div className="Dlft">
                <h5>Welcome to your account</h5>
                <p>Complete your profile to seamlessly connect with property owners and do so much more.</p>
                <button onClick={() => navigate('/dashboard/my-profile')}>Complete Profile <i className="ri-arrow-right-s-line"></i></button>
              </div>
              <div className="Drgt">
                <img src="https://myproperties.vercel.app/BuildingIllustration.png" alt="" />
              </div>
            </div>
          </div>
        }

        {/* ------------------------------- */}

        <div className="DeleteBox col-12 mb-36 pr-8">
          <h3 className='flex passwordTitle ms-2'><i onClick={() => setShowTabs(true)} className="showTabBack ri-arrow-left-s-line"></i>Delete Account
          </h3>
          <div className=' changeContainer  ms-2 h-[575px]'>
            <p className='delete-subHeading'>You are about to delete your Linked Bricks account. All your data will be removed.</p>
            <textarea onChange={(e)=>setDeleteData(e.target.value)} className='changeTextArea col-12 p-3' name="" id="" placeholder='Your feedback is important to us. Tell us why you’re deleting your account (optional)' cols="30" rows="10"></textarea>
            <div style={{ position: 'relative' }}>
              <input onChange={(e)=>setPass(e.target.value)} type={show ? ("text") : ("password")} className='changeInput col-12 p-3 pl-4' placeholder='Enter your password' />
              {show ? (<i onClick={() => setShow(false)} class="passView ri-eye-fill"></i>) : (<i onClick={() => setShow(true)} class="passView ri-eye-off-fill"></i>)}

            </div>

            <div className='col-lg-8 col-11 d-flex'>
              <input        checked={isChecked}
              onChange={handleCheckboxChange}  className='' style={{ width: "16px", opacity: allFieldsCompleted ? '1' : '0.5', }} type="checkbox" />
              <p className='deleteCheckText mt-3 text-[18px] ms-3'>I understand that deleting my account will remove all my data.</p>
            </div>
            <div className='text-end deleteButtonContainer'>
              <button disabled={!allFieldsCompleted} style={{ borderRadius: '12px', opacity: allFieldsCompleted ? '1' : '0.5' }} onClick={() => setAccountDlt(true)} className='w-[188px] bg-[#E8AE00] text-[18px] font-medium  p-2 border h-[50px]'>Delete Account <i className="ri-arrow-right-s-line"></i></button>
            </div>

          </div>
        </div>
      </div>
      <AccountDelete deleteData={deleteData} userPass={pass} accountDlt={accountDlt} setAccountDlt={setAccountDlt} />
    </div >
  )
}

export default DeleteAcccount