import React, { useState, useEffect } from "react";
import imgMessage from "../../../assets/Images/messageImg/ImgMessage1.png";
import userImg from "../../../assets/Images/messageImg/userImagees32.png";
import directMessage from "../../../logo/logo/Direct-messages24.png";
import { Link, useParams, useNavigate } from "react-router-dom";
import Conversation from "../../Popupfile/Conversation";
import { get_userMessages, send_message } from "../../../apiServices";
import { socket } from "../../../App";
import { Cloudinary } from 'cloudinary-core';
import './Message.css'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useSwipeable } from 'react-swipeable';
import Download from '../../../assets/Download.png'
import ZoomIn from '../../../assets/Zoom in.png'
import ZoomOut from '../../../assets/Zoom out.png'
import CancelIcon from '../../../assets/cancel-icon.png'
import AngleLeft from '../../../assets/angle-left.png';
import AngleRight from '../../../assets/angle-right.png';
function ReadMessage2({getUserDataCounts}) {
  const cloud_name = process.env.REACT_APP_CLOUD_NAME

  const cloudinary = new Cloudinary({ cloud_name: { cloud_name } });
  const upload_preset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
  const [conversation, setConversation] = useState(false)
  const [message, setMessage] = useState({})
  const [newMessage, setNewMessage] = useState('')
  const { messageId, senderId, recipientId, propertyId } = useParams()
  const [name, setName] = useState('')
  const loggedInUserId = localStorage.getItem('id')
  const loggedInUserDbId = localStorage.getItem('userDbId')
  const [propImg, setPropImg] = useState('')
  const navigate = useNavigate()
  const [propPlace, setPropPlace] = useState('')
  let messagesReceived = false
  const [showImage, setShowImage] = useState(false)
  const handleDownload = (file) => {
    // Create a temporary anchor element
    const anchor = document.createElement('a');
    anchor.href = file; // Set the URL of the image
    anchor.download = 'image.jpg'; // Set the name of the downloaded file
    document.body.appendChild(anchor);
    anchor.click(); // Simulate a click on the anchor element to trigger the download
    document.body.removeChild(anchor); // Remove the anchor element from the document
  };

  const renderFilePreview = (fileUrl) => {

    const fileType = fileUrl?.split('.').pop()?.toLowerCase();
    if (fileType) {
      if (['png', 'jpg', 'jpeg', 'gif', 'avif', 'webp'].includes(fileType)) {
        return (
          <>
            <img onClick={() => setShowImage(true)} style={{ borderRadius: '5px', marginTop: '3px' }} src={fileUrl} alt="Uploaded file" width="200" height="140" />
            {showImage && (
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                  zIndex: 9999,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <div style={{ position: 'relative' }}>
                  <TransformWrapper

                  >
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (

                      <>
                        <TransformComponent>
                          <img
                            src={fileUrl}
                            alt=""
                            className='propertyDetail__overlayImg'

                          />
                        </TransformComponent>

                        <div style={{
                          position: 'absolute',
                          top: '-12px',
                          fontSize: '2vmax',
                          right: '-12px',
                          backgroundColor: 'white',
                          borderRadius: '50%',

                        }}>
                          <img style={{ width: '28px', height: '28px', cursor: 'pointer', }} onClick={() => setShowImage(false)}
                            src={CancelIcon} />
                        </div>



                        <div
                          style={{
                            display: 'flex',
                            position: 'absolute',
                            bottom: '20px',
                            left: '20px',
                            backgroundColor: 'white',
                            color: '#0D0E0F',
                            padding: '8px 16px',
                            borderRadius: '12px',
                            width: '144px',
                            height: '40px',
                            fontSize: '14px',
                            fontWeight: '400',
                            justifyContent: 'space-around'
                          }}
                        >

                          <img onClick={() => handleDownload(fileUrl)} style={{ width: '15px', height: '15px', marginTop: '3px', marginRight: '3px', cursor: 'pointer' }} src={Download} alt='' />
                          <img
                            onClick={() => zoomIn()}
                            style={{ width: '14x', height: '14px', marginTop: '5px', marginRight: '3px', cursor: 'pointer' }} src={ZoomIn} alt='' />
                          <img
                            onClick={() => zoomOut()}

                            style={{ width: '14px', height: '14px', marginTop: '5px', marginRight: '3px', cursor: 'pointer' }} src={ZoomOut} alt='' />
                        </div>
                      </>
                    )}

                  </TransformWrapper>
                </div>

              </div>
            )}

          </>

        )
      } else if (fileType === 'mp4') {
        return (
          <video style={{ borderRadius: '5px', marginTop: '3px' }} controls src={fileUrl} width="200" height="150" />
        )
      } else if (fileType === 'pdf') {
        return (
          <div  >
            <p>Pdf is unsupported</p>
          </div>
        );
      } else if (fileType === 'csv') {
        return (
          <a href={fileUrl} style={{ fontSize: '12px' }} target="_blank" rel="noopener noreferrer">Download File</a>

        );

      } else {
        return (
          <div >
            <p style={{ fontSize: 'smaller' }}> {fileUrl}</p>   </div>
        );
      }
    } else {

      return (<p className="messageText2 col-lg-10 col-12  text-[18px] text-[#0D0E0F] font-normal  ">
        {
          fileUrl
        }
      </p>)
    }
  };


  const handleImageChange = async (event) => {
    const files = event.target.files;

    for (const file of files) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', upload_preset); // Specify your Cloudinary upload preset

      try {
        const response = await fetch(`https://api.cloudinary.com/v1_1/${cloud_name}/auto/upload`, {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const res = await response.json();
          const data = {
            propertyId,
            recipientId,
            senderId: loggedInUserDbId,
            content: res?.secure_url,
            userId: localStorage.getItem('id'),
            messageId: messageId,

          }
          const room = messageId
          await socket.emit('send-message', data, room, async (message) => {
            let userMsg = {
              propertyId: message.propertyId,
              recipient: message.recipientId,
              senderId: message.senderId,
              content: message.content,
              messageId: message.messageId
            }
            const res = await send_message(data)
            setMessage(res)
          })
          getMessagesBySocket();
        } else {
          console.error('Failed to upload image:', response.statusText);
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }

  };
  const getMessages = async () => {
    try {
      const response = await get_userMessages(messageId)
      setMessage(response)
      setPropImg(response?.propertyId.listingMedia?.[0])
      let name = (loggedInUserId == response?.sender.userId) ? response.recipient.fullName : response.sender.fullName
      setName(name)
      if (response?.place == 'undefined, undefined') {
        setPropPlace(response?.propertyId?.postalCode)
      } else {
        setPropPlace(response?.place)
      }

    } catch (err) {
      console.log(err)
    }
  }

  const handleDate = (timestamp) => {
    const date = new Date(timestamp);

    // Define options for formatting the date and time
    const options = { month: 'short', day: '2-digit', year: 'numeric' };

    // Format the date and time using toLocaleString()
    const formattedDateTime = date.toLocaleString('en-US', options);

    return formattedDateTime
  }
  const handleTime = (timestamp) => {
    const date = new Date(timestamp);

    // Define options for formatting the date and time
    const options = { hour: '2-digit', minute: '2-digit' };

    // Format the date and time using toLocaleString()
    const formattedDateTime = date.toLocaleString('en-US', options);

    return formattedDateTime
  }
  const handleSendMessage = async () => {
    messagesReceived = false
    try {
      messagesReceived = false

      if (newMessage != '') {
        const data = {
          propertyId,
          recipientId,
          senderId: loggedInUserDbId,
          content: newMessage,
          userId: localStorage.getItem('id'),
          messageId: messageId

        }
        const room = messageId

        await socket.emit('send-message', data, room, async (message) => {
          let userMsg = {
            propertyId: message.propertyId,
            recipient: message.recipientId,
            senderId: message.senderId,
            content: message.content,
            messageId: message.messageId
          }

          const res = await send_message(data)
          setMessage(res)
          setNewMessage('')
        })
      }
      getMessagesBySocket();

    } catch (err) {
      console.log(err)
    }
  }

  const getMessagesBySocket = () => {
    // Listen for incoming messages
    socket.on('receive-message', (message) => {
      getMessages(); // Update UI with new message
    });
  };


  useEffect(() => {
    getMessages()
  }, [])
  const place = message.place === 'undefined, undefined' ? message.propertyId.postalCode : message.place;


  return (
    <div className="">
      <div className="col-12 mb-5  ">
        <div className="  text-[28px] ms-3 font-semibold mb-[20px]">
          Message
        </div>
        <div className=" col-12  d-flex justify-content-between align-items-center   text-[28px] font-semibold mb-[20px] ">
          <div className="d-flex flex-lg-row ">

            <div>
              <i style={{ cursor: 'pointer' }} onClick={() => navigate('/dashboard/my-account/message')} className='float-left ri-arrow-left-s-line fs-4 pt-2'></i>

              <img src={propImg} className="mx-[10px] w-[50px] rounded-[8px]   h-[50px] float-left" />
            </div>
            <div className=" float-left ">

              <p className="text-[#098895]  mt-lg-0   messageMainPlace  text-[18px] font-semibold  mb-[2px]">
                {propPlace}
              </p>

              <p className="text-[#E8AE00] mt-lg-0  messageMainName   text-[14px] font-semibold  ">
                {name}
              </p>
            </div>
          </div>
          <div onClick={() => setConversation(true)} className="cursor-pointer w-[40px] h-[40px]  ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 text-red-700"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
          </div>
        </div>
        <div className="messageMain__Container col-12 mt-3 ">
          <h2 className="  col-12      text-center  text-[14px] font-normal ">
            {handleDate(message?.timestamp)}
          </h2>
          <div style={{ display: 'flex', flexDirection: 'column' }}>

            {
              message?.content?.map((content) => {

                const isSentByLoggedInUser = (content?.sender?.userId && content?.sender?.userId == loggedInUserId) || (content?.sender == loggedInUserDbId)
                const messageContainerClass = isSentByLoggedInUser ? 'messageContainer1' : 'messageContainer';
                const fileType = content.message?.split('.').pop()?.toLowerCase();
                const messageContainerClass2 = isSentByLoggedInUser ? 'float-right ' + (['png', 'jpg', 'jpeg', 'gif', 'avif', 'webp'].includes(fileType) ? 'transparent' : 'bg-[#EBFFFD]') : (['png', 'jpg', 'jpeg', 'gif', 'avif', 'webp'].includes(fileType) ? 'transparent' : 'bg-[#EBFFFD]') ;
                const displayTime=(['png', 'jpg', 'jpeg', 'gif', 'avif', 'webp'].includes(fileType) ? 'd-none' : 'd-flex') 
                return (
                  <div className={`${messageContainerClass} mx-[24px] mb-[24px]`}>
                    {
                      !isSentByLoggedInUser && <div>
                        <img
                          src={userImg}
                          alt=""
                          className="w-[32px] h-[32px] clear-both"
                        />
                      </div>
                    }


                    <div className={`${messageContainerClass2}`} style={{ borderRadius: '12px', padding: '10px', display: 'flex', marginLeft: '10px', backgroundColor: content.message.startsWith('data:image') ? 'transparent' : '#yourBackgroundColor' }} >
                      <div className="mr-4">
                        <p className="messageText text-[18px] text-[#0D0E0F] font-normal  ">
                          {
                            renderFilePreview(content?.message)
                          }
                        </p>
                      </div>
                      <div className={`messageTime__container ${displayTime}`}>
                        <span className=" messageTime float-right">{handleTime(content?.timestamp)}</span>

                      </div>

                    </div>
                  </div>
                )
              })
            }
          </div>



          <div className="messageMain__Container2 mx-[24px]  mb-[24px]  ">
            <button type="button" className="float-left  w-[40px] ">
              <input onChange={handleImageChange}
                style={{ opacity: '0' }} type="file" className=" w-[30px] h-[30px] text-white " />

              <svg

                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-[24px] h-[24px] mx-[8px] mt-[-28px] "
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13"
                />
              </svg>
            </button>
            <textarea
              rows={8}
              placeholder="Write a message..."
              style={{ border: '1px solid #FFEBB0' }}
              className="messageInputArea p-2 ml-[5px] rounded  col-11  "
              value={
                newMessage
              }
              onChange={
                (e) => setNewMessage(e.target.value)
              }
            />
          </div>
          <div className="mx-[24px] mb-[24px] h-[50px]">
            <button onClick={handleSendMessage} className="float-right messageSendButton col-lg-2 col-10 text-[18px] rounded-[12px] px-[16px] py-[12px] font-medium bg-[#E8AE00]  ">
              Send
              <img
                src={directMessage}
                alt="fb"
                className="w-[24px] h-[24px] float-right"
              />
            </button>
          </div>
        </div>
      </div>
      <Conversation getUserDataCounts={getUserDataCounts} messageId={messageId} conversation={conversation} setConversation={setConversation} />
    </div>
  );
}

export default ReadMessage2;
