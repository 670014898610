import React, { useState } from 'react';
import "./ManagePreferance.css";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ManagePreferance = ({setShowTabs}) => {
  const navigate = useNavigate();
  const [linkedBricks, setLinkedBricks] = useState(false);
  const [newHomesOffers, setNewHomesOffers] = useState(false);
  const [partnerOffers, setPartnerOffers] = useState(false);
  const isProfileUpdated = useSelector(state => state.auth.isProfileUpdated)

  const handleToggleLinkedBricks = () => {
    setLinkedBricks(!linkedBricks);
  };

  const handleToggleNewHomesOffers = () => {
    setNewHomesOffers(!newHomesOffers);
  };

  const handleTogglePartnerOffers = () => {
    setPartnerOffers(!partnerOffers);
  };

  return (
    <div className='ManagePreferance'>
      <div className="Preferance">
        {/* ------------------------------- */}
        {
          isProfileUpdated == false && <div className="TopOne mb-8">
            <div className="topOneLeft">
              <h5 className="topOneTitle">Welcome to your account</h5>
              <p className="topOnePara">Complete your profile to seamlessly connect with property owners and do so much more.</p>
              <button className="topOneButton" onClick={() => navigate('/dashboard/my-profile')}><span>Complete profile</span> <i style={{ fontSize: '24px' }} className="ri-arrow-right-s-line"></i></button>
            </div>
            <div className="topOneRight">
              <img className="topOneImg" src="https://myproperties.vercel.app/BuildingIllustration.png" alt="" />
            </div>
          </div>
        }

        {/* ------------------------------- */}

        <div className="ManageBox">
          <h5 className='flex passwordTitle'><i onClick={() => setShowTabs(true)} className="showTabBack ri-arrow-left-s-line"></i>Manage preferences</h5>
          <div className="prefbox">
            <div className="prefLeft">
              <h6 > Linked Bricks News</h6>
              <p>Exciting property news, surveys and offers from Linked Bricks</p>
            </div>
            <div className="prefRight">
              <div className={`toggle-container ${linkedBricks ? 'on' : 'off'}`} onClick={handleToggleLinkedBricks}>
                <div className={`slider ${linkedBricks ? 'on' : 'off'}`} />
              </div>
            </div>
          </div>
          <div className="prefbox">
            <div className="prefLeft">
              <h6>New Homes Offers</h6>
              <p>Updates on new build properties in your search area from new build developers.</p>
            </div>
            <div className="prefRight">
              <div className={`toggle-container ${newHomesOffers ? 'on' : 'off'}`} onClick={handleToggleNewHomesOffers}>
                <div className={`slider ${newHomesOffers ? 'on' : 'off'}`} />
              </div>
            </div>
          </div>
          <div className="prefbox">
            <div className="prefLeft">
              <h6>Partner Offers</h6>
              <p>Latest money-saving offers from Zoopla's chosen property and financial partners.</p>
            </div>
            <div className="prefRight">
              <div className={`toggle-container ${partnerOffers ? 'on' : 'off'}`} onClick={handleTogglePartnerOffers}>
                <div className={`slider ${partnerOffers ? 'on' : 'off'}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagePreferance;


// ----------------------------------------------------------------------------------------------



// import React, { useState } from 'react'
// import "./ManagePreferance.css"
// import { useNavigate } from 'react-router-dom';


// const ManagePreferance = () => {

//   const navigate = useNavigate()
//   const [isOn, setIsOn] = useState(false)

//   const handleToggle = () => {
//     setIsOn(!isOn)
//   }

//   return (
//     <div className='ManagePreferance'>
//       <div className="Preferance">
//         {/* ------------------------------- */}
//         <div className="Managemain">
//           <div className="mainOne">
//             <div className="lft">
//               <h5>Welcome to your account</h5>
//               <p>Complete your profile to seamlessly connect with property owners and do so much more.</p>
//               <button onClick={() => navigate('/dashboard/my-profile')}>Complete Profile <i className="ri-arrow-right-s-line"></i></button>
//             </div>
//             <div className="rgt">
//               <img src="https://myproperties.vercel.app/BuildingIllustration.png" alt="" />
//             </div>
//           </div>
//         </div>
//         {/* ------------------------------- */}

//         <div className="ManageBox">
//           <h5>Manage preferences</h5>
//           <div className="prefbox">
//             <div className="prefLeft">
//               <h6>Linked Bricks News</h6>
//               <p>Exciting property news, surveys and offers from Linked Bricks</p>
//             </div>
//             <input type="checkbox" className='toggle' checked data-toggle="toggle" data-style="ios" />
//           </div>
//           <div className="prefbox">
//             <div className="prefLeft">
//               <h6>New Homes Offers</h6>
//               <p>Updates on new build properties in your search area from new build developers.</p>
//             </div>
//             <input type="checkbox" className='toggle' checked data-toggle="toggle" data-style="ios" />
//           </div>
//           <div className="prefbox">
//             <div className="prefLeft">
//               <h6>Partner Offers</h6>
//               <p>Latest money-saving offers from Zoopla's chosen property and financial partners.</p>
//             </div>
//             <div className="prefRight">
//               <div className={`toggle-container ${isOn ? 'on' : 'off'}`} onClick={handleToggle}>
//                 <div className={`slider ${isOn ? 'on' : 'off'}`} /></div>
//               <span className={`label ${isOn ? 'on' : 'off'}`}>{isOn ? 'On' : 'Off'}</span>
//             </div>
//           </div>
//         </div>

//       </div>



//     </div>
//      </div >
//   )
// }

// export default ManagePreferance